import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { RichText } from 'components/shared/JssOverrides';
import { withSitecoreContext, isExperienceEditorActive } from '@sitecore-jss/sitecore-jss-react';
import Button from '../shared/Button';
import { FindNearYouWrapper } from './FindNearYou.styles.js';
import {trademarkFilterHelper} from "../../helpers/trademarkFilterHelper";

const FindNearYou = ({ title = '', fields = {}, params = {}, sitecoreContext }) => {
    const {
        content = {},
        heading = {},
        item_one_image = {},
        item_one_link = {},
        item_one_title = {},
        item_two_image = {},
        item_two_link = {},
        item_two_title = {},
        item_three_image = {},
        item_three_link = {},
        item_three_title = {},
        item_four_image = {},
        item_four_link = {},
        item_four_title = {},
        button = {},
    } = fields;

    const {
        backgroundColor = 'tan',
        richTextClass = '',
        pimg1 = '',
        pimg2 = '',
        pimg3 = '',
        pimg4 = '',
        plink1 = '',
        plink2 = '',
        plink3 = '',
        plink4 = '',
        ptitle1 = '',
        ptitle2 = '',
        ptitle3 = '',
        ptitle4 = '',
    } = params;

    const {site, trademarkReplacements = []} = sitecoreContext;
    const richTextClassname = richTextClass
        ? `content-container ${richTextClass}`
        : 'content-container';

    const componentRef = useRef(null);
    const experienceEditor = isExperienceEditorActive();
    const handleItemClick = (event) => {
        const link = event.currentTarget.getAttribute('data-link');
        if (link) {
            window.location.href = link;
        }
    };
    //console.log('FindNearYou.jsx fields', fields);
    return (
        <FindNearYouWrapper
            ref={componentRef}
            backgroundColor={backgroundColor}
            className="fny-module"
        >
            {heading?.value && (
                <div className="content-container heading-container">
                    <h2 dangerouslySetInnerHTML={{ __html: heading.value }} />
                </div>
            )}

            <div className="fny-grid-wrapper">
                <div className="fny-grid">
                    {item_one_image?.value?.src && (
                        <div
                            className="fny-grid-item"
                            data-link={item_one_link?.value?.href}
                            onClick={handleItemClick}
                        >
                            <div className="img-wrapper">
                                {item_one_image?.value?.src && (
                                    <img
                                        src={item_one_image.value.src}
                                        alt={item_one_image.value.alt || 'Product Image'}
                                        width="200"
                                    />
                                )}
                            </div>
                            <div className="text-wrapper">
                                {item_one_link?.value?.href && (
                                    <a href={item_one_link.value.href}>
                                        {trademarkFilterHelper(
                                            item_one_link?.value?.text,
                                            trademarkReplacements
                                        )}
                                    </a>
                                )}
                                {item_one_title?.value && (
                                    <p
                                        dangerouslySetInnerHTML={{
                                            __html: trademarkFilterHelper(
                                                item_one_title.value,
                                                trademarkReplacements
                                            )
                                        }}
                                    />
                                )}
                            </div>
                        </div>
                    )}
                    {item_two_image?.value?.src && (
                        <div
                            className="fny-grid-item"
                            data-link={item_two_link?.value?.href}
                            onClick={handleItemClick}
                        >
                            <div className="img-wrapper">
                                {item_two_image?.value?.src && (
                                    <img
                                        src={item_two_image.value.src}
                                        alt={item_two_image.value.alt || 'Product Image'}
                                        width="200"
                                    />
                                )}
                            </div>
                            <div className="text-wrapper">
                                {item_two_link?.value?.href && (
                                    <a href={item_two_link.value.href}>
                                        {trademarkFilterHelper(
                                            item_two_link?.value?.text,
                                            trademarkReplacements
                                        )}
                                    </a>
                                )}
                                {item_two_title?.value && (
                                    <p
                                        dangerouslySetInnerHTML={{
                                            __html: trademarkFilterHelper(
                                                item_two_title.value,
                                                trademarkReplacements
                                            )
                                        }}
                                    />
                                )}
                            </div>
                        </div>
                    )}
                    {item_three_image?.value?.src && (
                        <div
                            className="fny-grid-item"
                            data-link={item_three_link?.value?.href}
                            onClick={handleItemClick}
                        >
                            <div className="img-wrapper">
                                {item_three_image?.value?.src && (
                                    <img
                                        src={item_three_image.value.src}
                                        alt={item_three_image.value.alt || 'Product Image'}
                                        width="200"
                                    />
                                )}
                            </div>
                            <div className="text-wrapper">
                                {item_three_link?.value?.href && (
                                    <a href={item_three_link.value.href}>
                                        {trademarkFilterHelper(
                                            item_three_title.value,
                                            trademarkReplacements
                                        )}
                                    </a>
                                )}
                                {item_three_title?.value && (
                                    <p
                                        dangerouslySetInnerHTML={{
                                            __html: trademarkFilterHelper(
                                                item_three_link?.value?.text,
                                                trademarkReplacements
                                            )
                                        }}
                                    />
                                )}
                            </div>
                        </div>
                    )}
                    {item_four_image?.value?.src && (
                        <div
                            className="fny-grid-item"
                            data-link={item_four_link?.value?.href}
                            onClick={handleItemClick}
                        >
                            <div className="img-wrapper">
                                {item_four_image?.value?.src && (
                                    <img
                                        src={item_four_image.value.src}
                                        alt={item_four_image.value.alt || 'Product Image'}
                                        width="200"
                                    />
                                )}
                            </div>
                            <div className="text-wrapper">
                                {item_four_link?.value?.href && (
                                    <a href={item_four_link.value.href}>
                                        {trademarkFilterHelper(
                                            item_four_link?.value?.text,
                                            trademarkReplacements
                                        )}
                                    </a>
                                )}
                                {item_four_title?.value && (
                                    <p
                                        dangerouslySetInnerHTML={{
                                            __html: trademarkFilterHelper(
                                                item_four_title.value,
                                                trademarkReplacements
                                            )
                                        }}
                                    />
                                )}
                            </div>
                        </div>
                    )}

                    {ptitle1 && pimg1 && (
                        <div className="fny-grid-item" data-link={plink1} onClick={handleItemClick}>
                            <div className="img-wrapper">
                                <img width="200" src={pimg1} alt="img" />
                            </div>
                            <div className="text-wrapper">
                                <a href={plink1}>{ptitle1}</a>
                            </div>
                        </div>
                    )}
                    {ptitle2 && pimg2 && (
                        <div className="fny-grid-item" data-link={plink2} onClick={handleItemClick}>
                            <div className="img-wrapper">
                                <img width="200" src={pimg2} alt="img" />
                            </div>
                            <div className="text-wrapper">
                                <a href={plink2}>{ptitle2}</a>
                            </div>
                        </div>
                    )}
                    {ptitle3 && pimg3 && (
                        <div className="fny-grid-item" data-link={plink3} onClick={handleItemClick}>
                            <div className="img-wrapper">
                                <img width="200" src={pimg3} alt="img" />
                            </div>
                            <div className="text-wrapper">
                                <a href={plink3}>{ptitle3}</a>
                            </div>
                        </div>
                    )}
                    {ptitle4 && pimg4 && (
                        <div className="fny-grid-item" data-link={plink4} onClick={handleItemClick}>
                            <div className="img-wrapper">
                                <img width="200" src={pimg4} alt="img" />
                            </div>
                            <div className="text-wrapper">
                                <a href={plink4}>{ptitle4}</a>
                            </div>
                        </div>
                    )}
                </div>
            </div>

            <RichText className={richTextClassname} field={content} tag="div" editable={true} />

            {button.text && button.url && !experienceEditor &&  (
                <div className="content-container">
                    <Button text={button.text} href={button.url} target={button.target} />
                </div>
            )}
        </FindNearYouWrapper>
    );
};

FindNearYou.propTypes = {
    title: PropTypes.string,
    fields: PropTypes.shape({
        content: PropTypes.object,
        heading: PropTypes.shape({
            value: PropTypes.string,
        }),
        item_one_link: PropTypes.shape({
            value: PropTypes.shape({
                href: PropTypes.string,
                text: PropTypes.string,
            }),
        }),
        item_two_link: PropTypes.shape({
            value: PropTypes.shape({
                href: PropTypes.string,
                text: PropTypes.string,
            }),
        }),
        item_three_link: PropTypes.shape({
            value: PropTypes.shape({
                href: PropTypes.string,
                text: PropTypes.string,
            }),
        }),
        item_four_link: PropTypes.shape({
            value: PropTypes.shape({
                href: PropTypes.string,
                text: PropTypes.string,
            }),
        }),
        item_one_image: PropTypes.shape({
            value: PropTypes.shape({
                src: PropTypes.string,
                alt: PropTypes.string,
            }),
        }),
        item_two_image: PropTypes.shape({
            value: PropTypes.shape({
                src: PropTypes.string,
                alt: PropTypes.string,
            }),
        }),
        item_three_image: PropTypes.shape({
            value: PropTypes.shape({
                src: PropTypes.string,
                alt: PropTypes.string,
            }),
        }),
        item_four_image: PropTypes.shape({
            value: PropTypes.shape({
                src: PropTypes.string,
                alt: PropTypes.string,
            }),
        }),

        item_one_title: PropTypes.shape({
            value: PropTypes.string,
        }),
        item_two_title: PropTypes.shape({
            value: PropTypes.string,
        }),
        item_three_title: PropTypes.shape({
            value: PropTypes.string,
        }),
        item_four_title: PropTypes.shape({
            value: PropTypes.string,
        }),

        // Button
        button: PropTypes.shape({
            text: PropTypes.string,
            url: PropTypes.string,
            target: PropTypes.string,
        }),
    }),

    params: PropTypes.shape({
        backgroundColor: PropTypes.oneOf(['tan', 'yellow','blue','transparent','red','brown']),
        richTextClass: PropTypes.string,
        pimg1: PropTypes.string,
        pimg2: PropTypes.string,
        pimg3: PropTypes.string,
        pimg4: PropTypes.string,
        plink1: PropTypes.string,
        plink2: PropTypes.string,
        plink3: PropTypes.string,
        plink4: PropTypes.string,
        ptitle1: PropTypes.string,
        ptitle2: PropTypes.string,
        ptitle3: PropTypes.string,
        ptitle4: PropTypes.string,
    }),
    sitecoreContext: PropTypes.shape({
        site: PropTypes.object,
    }),
};

export default withSitecoreContext()(FindNearYou);