import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {VideoRightOuterWrapper, VideoComponent, VideoRightOuterWrapperBluey} from './TextLeftVideoRight.styles.js';
import { isExperienceEditorActive, withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import Button from "../shared/Button";
import {RichText} from "../shared/JssOverrides";
import cartoonCans from '../../assets/images/animated-bean-cans.svg';

const TextLeftVideoRight = ({ fields = {}, params = {}, sitecoreContext }) => {
    const { content = {}, heading, button } = fields;
    const {
        backgroundColor = 'tan',
        alignment = 'left',
        poster = '',
        video = '',
        rtClass = '',
        buttonUrl = '',
        buttonText = '',
        headingColor = '',
        buttonColor = '',
        textColor = '',
        bluey = '',
        containerClass = '',
    } = params;
    const { site } = sitecoreContext;
    const experienceEditor = isExperienceEditorActive();
    const [isIframeVisible, setIsIframeVisible] = useState(false);
    const [iframeVideoUrl, setIframeVideoUrl] = useState('');

    const handleVideoClick = () => {
        setIsIframeVisible(true);
        setIframeVideoUrl(`${video}&autoplay=1`);
    };

    if (bluey === 'true') {
        return (
            <VideoRightOuterWrapperBluey backgroundColor={backgroundColor} alignment={alignment} site={site?.name} textColor={textColor} buttonColor={buttonColor} headingColor={headingColor}>
                <div className={`container ${containerClass || ''} ${bluey === 'yes' ? 'contained bluey' : ''}`}>
                    <div className="two-col-grid">
                        <div className="col text-column">
                            {heading?.value && (
                                <h2 className="module-heading" dangerouslySetInnerHTML={{__html: heading?.value}}></h2>
                            )}
                            {content?.value && (
                                <RichText className={rtClass} field={content} tag="div" editable={true} />
                            )}
                            {button?.value?.text && button?.value?.href && (
                                    <Button
                                        text={button.value.text}
                                        href={button.value.href}
                                        target={button.value.target}
                                        {...button.value}
                                        variant={buttonColor || "red"}
                                        className="fade-in-up"
                                    />
                            )}
                            {buttonUrl && buttonText && !experienceEditor && (
                                <div className="content-container">
                                    <Button text={buttonText} href={buttonUrl} variant={buttonColor} />
                                </div>
                            )}
                        </div>
                        <div className="col video-column">
                            <VideoComponent data-video={video} style={{ backgroundImage: `url(${poster})` }} onClick={handleVideoClick}>
                                {isIframeVisible && (
                                    <iframe
                                        className={`fade-in ${isIframeVisible ? 'visible' : ''}`}
                                        width="560"
                                        height="315"
                                        src={iframeVideoUrl}
                                        frameBorder="0"
                                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                        allowFullScreen
                                    ></iframe>
                                )}
                            </VideoComponent>
                        </div>
                    </div>
                    <img src={cartoonCans} className="animated-cans" alt="animated bean cans graphic" />
                </div>
            </VideoRightOuterWrapperBluey>
        );
    }
    return (
        <VideoRightOuterWrapper backgroundColor={backgroundColor} alignment={alignment} site={site?.name} textColor={textColor} buttonColor={buttonColor} headingColor={headingColor}>
            <div className={`container ${containerClass || ''} ${bluey === 'yes' ? 'contained bluey' : ''}`}>
                <div className="two-col-grid">
                    <div className="col text-column">
                        {heading?.value && (
                            <h2 className="module-heading" dangerouslySetInnerHTML={{__html: heading?.value}}></h2>
                        )}
                        {content?.value && (
                            <RichText className={rtClass} field={content} tag="div" editable={true} />
                        )}
                        {button?.value?.text && button?.value?.href && (
                            <div className="content-container">
                                <Button
                                    text={button.value.text}
                                    href={button.value.href}
                                    target={button.value.target}
                                    {...button.value}
                                    variant={buttonColor || "red"}
                                    className="fade-in-up"
                                />
                            </div>
                        )}
                        {buttonUrl && buttonText && !experienceEditor && (
                            <div className="content-container">
                                <Button text={buttonText} href={buttonUrl} variant={buttonColor} />
                            </div>
                        )}
                    </div>
                    <div className="col video-column">
                        <VideoComponent data-video={video} style={{ backgroundImage: `url(${poster})` }} onClick={handleVideoClick}>
                            {isIframeVisible && (
                                <iframe
                                    className={`fade-in ${isIframeVisible ? 'visible' : ''}`}
                                    width="560"
                                    height="315"
                                    src={iframeVideoUrl}
                                    frameBorder="0"
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                    allowFullScreen
                                ></iframe>
                            )}
                        </VideoComponent>
                    </div>
                </div>
            </div>
        </VideoRightOuterWrapper>
    );
};

TextLeftVideoRight.propTypes = {
    fields: PropTypes.shape({
        content: PropTypes.object,
    }),
    params: PropTypes.shape({
        alignment: PropTypes.oneOf(['left', 'center', 'right']),
        backgroundColor: PropTypes.oneOf(['tan', 'yellow']),
        poster: PropTypes.string,
        video: PropTypes.string,
        rtClass: PropTypes.string,
    }),
};

export default withSitecoreContext()(TextLeftVideoRight);