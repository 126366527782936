import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
    BlueyBeanChallengeWrapper
} from './BlueyBeanChallenge.styles.js';
import { Image, isExperienceEditorActive, withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import Button from "../shared/Button";
import { RichText } from "../shared/JssOverrides";
import Icon from "../shared/Icon";
import beanbowl from '../../assets/images/bean-bowl.png';

const challenges = [
    { heading: "Name That Bean!", text: "Set a timer for 30 seconds and name as many types of beans as you can!", img: "/-/jssmedia/BushsBeans/bluey/challenge-1.png" },
    { heading: "Granny Dance-Off", text: "Do your best wiggly, bouncy, bean-inspired dance moves for 20 seconds.", img: "/-/jssmedia/BushsBeans/bluey/challenge-2.png" },
    { heading: "Bluey’s Bean Tongue Twister", text: "Try saying 'Bluey’s big bean bowls bring big belly laughs!' five times fast!", img: "/-/jssmedia/BushsBeans/bluey/challenge-3.png" },
    { heading: "Last Granny Standing", text: "Stay in Granny character the longest—first to laugh or drop character loses!", img: "/-/jssmedia/BushsBeans/bluey/challenge-4.png" },
    { heading: "Bean Can Strength Challenge", text: "Hold a can of Bush’s Beans straight out in front of you with both hands. How long can you keep your arms up?", img: "/-/jssmedia/BushsBeans/bluey/challenge-5.png" },
    { heading: "Pass the Beans", text: "Sit in a circle and pass a can of beans using only your elbows! How long can you keep it going?", img: "/-/jssmedia/BushsBeans/bluey/challenge-6.png" },
    { heading: "The Granny Shuffle", text: "Wrap a blanket around you and see how fast you can shuffle to your pantry and back!", img: "/-/jssmedia/BushsBeans/bluey/challenge-7.png" },
    { heading: "Granny Says", text: "One person is the Granny and gives silly instructions, but you only do the action if they say 'Granny says' first!", img: "/-/jssmedia/BushsBeans/bluey/challenge-8.png" },
    { heading: "A-Bean-C", text: "Go around in a circle and take turns naming a food that could go with beans—but it has to go in alphabetical order!", img: "/-/jssmedia/BushsBeans/bluey/challenge-9.png" },
    { heading: "Act It Out!", text: "Pick a bean-related word (e.g., eating, cooking, spilling beans) and act it out—no talking! Others guess the word!", img: "/-/jssmedia/BushsBeans/bluey/challenge-10.png" }
];

const BlueyBeanChallenge = ({ fields = {}, params = {}, sitecoreContext }) => {
    const { content = {}, heading, button, graphic } = fields;
    const {
        backgroundColor = 'tan',
        alignment = 'left',
        rtClass = '',
        buttonUrl = '',
        buttonText = '',
        headingColor = '',
        buttonColor = '',
        textColor = '',
        bluey = '',
        containerClass = '',
        supportingImg = '',
    } = params;
    const { site } = sitecoreContext;
    const experienceEditor = isExperienceEditorActive();

    const [challengeIndex, setChallengeIndex] = useState(0);
    const [isFading, setIsFading] = useState(false);
    const [currentChallengeImage, setCurrentChallengeImage] = useState(challenges[0].img);

    const handleNewChallenge = () => {
        setIsFading(true); // Start fade-out
        setTimeout(() => {
            const newIndex = (challengeIndex + 1) % challenges.length;
            setChallengeIndex(newIndex);
            setIsFading(false); // Start fade-in
        }, 400); // Matches the CSS transition duration
    };

    return (
        <BlueyBeanChallengeWrapper className="bluey-bean-challenge" backgroundColor={backgroundColor} alignment={alignment} site={site?.name} textColor={textColor} buttonColor={buttonColor} headingColor={headingColor}>
            <div className="bean-bowl-container">
                <img src={beanbowl} width="300" className="bean-bowl" alt="animated bowl of beans" />
            </div>
            <div className={`container ${containerClass || ''} ${bluey === 'yes' ? 'contained bluey' : ''}`}>
                <div className="contents">
                    <div className="col text-column">
                        <Icon icon="shine" className="bean-burst"/>
                        {heading?.value && (
                            <h2 className="module-heading" dangerouslySetInnerHTML={{__html: heading?.value}}></h2>
                        )}
                        <div className={`inner ${isFading ? 'fade-out' : 'fade-in'}`}>
                            <h3>{challenges[challengeIndex].heading}</h3>
                            <p>{challenges[challengeIndex].text}</p>
                        </div>
                        {button?.value?.text && button?.value?.href && (
                            <div className="content-container">
                                <Button
                                    text={button.value.text}
                                    href={button.value.href}
                                    target={button.value.target}
                                    {...button.value}
                                    variant={buttonColor || "red"}
                                    className="fade-in-up"
                                />
                            </div>
                        )}
                        <button className="start-button" onClick={handleNewChallenge}>
                            New Challenge
                        </button>
                        {buttonUrl && buttonText && !experienceEditor && (
                            <div className="content-container">
                                <Button text={buttonText} href={buttonUrl} variant={buttonColor} />
                            </div>
                        )}
                    </div>
                    <div className="col image-column">
                        {/*{graphic && graphic.value && graphic.value.src && (*/}
                        {/*    <Image*/}
                        {/*        field={graphic}*/}
                        {/*        srcSet={[{ mw: 650 }, { mw: 800 }]}*/}
                        {/*        sizes="(min-width: 960px) 650px, 800px"*/}
                        {/*        className="bluey-dress-up-image"*/}
                        {/*    />*/}
                        {/*)}*/}
                        <img
                            className={`supporting-img ${isFading ? 'fading' : ''} ${isFading ? 'fade-out' : 'fade-in'}`}
                            src={challenges[challengeIndex].img}
                            alt="Challenge Image"
                        />
                    </div>
                </div>
            </div>
        </BlueyBeanChallengeWrapper>
    );
};

BlueyBeanChallenge.propTypes = {
    fields: PropTypes.shape({
        content: PropTypes.object,
    }),
    params: PropTypes.shape({
        alignment: PropTypes.oneOf(['left', 'center', 'right']),
        backgroundColor: PropTypes.oneOf(['tan', 'yellow']),
        poster: PropTypes.string,
        video: PropTypes.string,
        rtClass: PropTypes.string,
    }),
};

export default withSitecoreContext()(BlueyBeanChallenge);