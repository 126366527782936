import React from 'react';
import PropTypes from 'prop-types';
import { isServer } from '@sitecore-jss/sitecore-jss';
import { isExperienceEditorActive, withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import { ArticleGridComponent } from './ArticleGrid.styles.js';

import Listing from 'components/shared/Listing';

const ArticleGrid = ({ fields = {}, params = {}, sitecoreContext = {} }) => {
  const { showFilters = false, pageSize = {}, prefilterArticleTopic = [] } = fields;
  const { site, trademarkReplacements = [] } = sitecoreContext;
  const pageSizeValue =
    (pageSize?.value && !isNaN(parseInt(pageSize.value)) && parseInt(pageSize.value)) || 10;

  const prefilter = { prefilterArticleTopic };

  if (isExperienceEditorActive()) {
    return (
      <div>
        <h1>ARTICLE GRID PLACEHOLDER</h1>
      </div>
    );
  }

  return (
    <ArticleGridComponent>
      {!isServer() && (
        <Listing
          type="article"
          prefilter={prefilter}
          showFilters={!!showFilters?.value}
          pageSize={pageSizeValue}
          site={site.name}
        />
      )}
    </ArticleGridComponent>
  );
};

ArticleGrid.propTypes = {};

export default withSitecoreContext()(ArticleGrid);