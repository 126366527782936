import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { ListingComponent } from './Listing.styles.js';
import Results from './components/Results';
import Error from './components/Error';
import Filters from './components/Filters';
import { useFetch, useQueryParams } from 'hooks';
import { searchConfig, filterConfig, globalizationConfig } from 'helpers/searchHelpers';
import { queryStringToObj } from 'helpers/queryStringHelpers';
import {isExperienceEditorActive, withSitecoreContext} from '@sitecore-jss/sitecore-jss-react';

const Listing = ({ type = 'recipe', site = 'bushs-beans', showFilters = true, prefilter = [], pageSize = 10, sitecoreContext = {} }) => {
  const searchParams = useQueryParams();
  const { endpoint } = searchConfig({ type });
  const originalFilterSettings = filterConfig({ type, prefilter });
  const [initialRequestMade, setInitialRequestMade] = useState(false);

  const [state, setState] = useState({
    filters: filterConfig({ type, prefilter, query: queryStringToObj(searchParams) }),
    results: [],
    request: {},
    facets: {},
    totalResultsCount: 0,
    skip: 0,
    sortOrder: 'dateDesc',
    globalization: globalizationConfig(),
  });

  const { results, facets, totalResultsCount, filters, skip, sortOrder, globalization } = state;

  // TODO: generate globalization object

  const { data, loading, error } = useFetch(endpoint, {
    method: 'POST',
    body: {
      subject: {
        filters,
        take: pageSize,
        skip,
        sortOrder,
      },
      context: {
        globalization,
        request: {
          scheme: 'https',
          queryString: '',
          routeData: {
            site: site
          },
        },
        content: {},
      },
    },
  });

  useEffect(() => {
    if (data) {
      if (!initialRequestMade) setInitialRequestMade(true);
      // checks if load more or filter applied
      const skipValue = !!(data?.subject?.request?.skip || 0);
      setState((oldState) => {
        return {
          ...oldState,
          ...data?.subject,
          results: skipValue
              ? [...oldState?.results, ...data?.subject?.results]
              : [...data?.subject?.results],
        };
      });
    }
  }, [data, initialRequestMade]);

  const loadMore = () =>
      setState((state) => {
        return {
          ...state,
          skip: results.length,
        };
      });
  if (isExperienceEditorActive()) {
    return (
        <div style={{ minHeight: '150px' }} >
          <h1>LISTING PLACEHOLDER</h1>
        </div>
    );
  }
  return (
      <ListingComponent>
        {showFilters && (
            <Filters
                type={type}
                facets={facets}
                filters={filters}
                originalFilterSettings={originalFilterSettings}
                setState={setState}
                state={state}
            />
        )}
        {!error ? (
            <Results
                results={results}
                globalization={globalization}
                type={type}
                loadMoreOnClick={loadMore}
                currentResults={results?.length}
                totalResultsCount={totalResultsCount}
                dataLoading={loading}
                initialRequestMade={initialRequestMade}
            />
        ) : (
            <Error />
        )}
      </ListingComponent>
  );
};

Listing.propTypes = {};

export default withSitecoreContext()(Listing);