import styled from 'styled-components';


const SectionWrapper = styled.section`
	width: 100%;
	max-width: 100%;
	margin: auto;
	position: relative;
	background-color: ${({ theme }) => theme.colours.blue};
	padding-top: ${({ theme }) => theme.spacing(150)};
	padding-bottom: ${({ theme }) => theme.spacing(150)};
	h2 {
		color: ${({ theme }) => theme.colours.white};
		font-family: "apercu condensed pro", sans-serif;
	}
	p {
		color: ${({ theme }) => theme.colours.white};
	}
	.collection-showcase {
		text-align: center;
		padding: 40px;
		background-color: #F1E8D7;
	}
	.showcase-header {
		margin-bottom: 30px;
	}
	.showcase-header .collections-grid .og-button-lg{
		margin: 15px auto 0 auto;
	}
	.main-title {
		font-size: 2.5em;
		margin-bottom: 10px;
	}
	.shop-button {
		background-color: #f04e23;
		color: #fff;
		padding: 10px 20px;
		text-decoration: none;
		border-radius: 5px;
	}
	.collections-grid {
		display: grid;
		grid-template-columns: repeat(4, 1fr);
		gap: 10px;
	}
	.collection-item {
		position: relative;
		.poster{
			padding-top: 109.04%;
			position: relative;
			.collection-image {
				position: absolute;
				left: 0;
				right: 0;
				top: 0;
				bottom: 0;
				width: 100%;
				height: 100%;
				object-fit: cover;
			}
		}
		.collection-link{
			display: flex;
			justify-content: space-between;
			align-items: center;
			background-color: #d43631;
			padding: 10px;
			transition: all 0.4s ease-in-out;
			cursor: pointer;
			.collection-title {
				font-size: 24px;
				margin: 0;
				color: #ffca2e;
				font-family: 'apercu pro', sans-serif;
				font-weight: 100;
				cursor: pointer;
			}
			span{
				color: #ffca2e;
				font-size: 50px;
				transition: transform 0.4s ease-in-out;
				line-height: 1;
			}
			&:hover{
				opacity: 1;
				.collection-title{
					border-bottom: 1px solid #ffca2e;
				}
				span{
					transform: translateX(5px);
				}
			}
			&:after{
				position: absolute;
				left: 0;
				right: 0;
				top: 0;
				bottom: 0;
				width: 100%;
				height: 100%;
				content: "";
				cursor: pointer;
			}
		}
	}

	.collection-title-item{
		position: relative;
		display: flex;
		justify-content: center;
		.inner{
			align-self: center;
			.deco-img{
				width: 57px;
				height: 57px;
			}
			.main-title{
				font-size: 68px;
				margin-bottom: 10px;
				text-transform: uppercase;
				color: #1471D2;
				line-height: 1;
				max-width: 347px;
				span{
					font-size: 70%;
					border-bottom: 4px solid;
					position: relative;
					top: -13px;
					letter-spacing: -1px;
				}
			}
			.og-button{
				margin: 30px auto 0 auto;
				max-width: 240px;
			}
			.og-button-lg{
				margin: 30px auto 0 auto;
			}
		}
	}

	.showcase-header{
		display: none;
		position: relative;
		justify-content: center;
		.inner{
			align-self: center;
			.deco-img{
				width: 57px;
				height: 57px;
			}
			.main-title{
				font-size: 68px;
				margin-bottom: 10px;
				text-transform: uppercase;
				color: #1471D2;
				line-height: 1;
				max-width: 347px;
				span{
					font-size: 70%;
					border-bottom: 4px solid;
					position: relative;
					top: -13px;
					letter-spacing: -1px;
				}
			}
		}
	}
	.collections-slider{
		display: none;
		width: 80%;
		.slick-list{
			overflow: visible;
			.slick-slide{
				margin-left: 20px;
			}
		}
	}
	@media (max-width: 1120px){
		.collection-showcase{
			padding: 50px 0;
		}
		.showcase-header{
			display: flex;
		}
		.collection-text{
			display: none;
		}
		.collections-grid{
			display: none;
		}
		.collections-slider{
			display: block;
		}
	}
	@media (max-width: 1330px){
		.collection-item .collection-link .collection-title{
			font-size: 21px;
		}
	}
	@media (max-width: 1200px){
		.collection-item .collection-link .collection-title{
			font-size: 18px;
		}
	}
	@media (max-width: 1120px){
		.collection-item .collection-link .collection-title{
			font-size: 22px;
		}
	}
	@media (max-width: 800px){
		.collection-item .collection-link .collection-title{
			font-size: 19px;
		}
	}
`;

export default SectionWrapper;