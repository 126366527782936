import React from 'react';
import {
    RelatedPagesPromoComponent,
    RelatedPagesPromoComponentBluey,
    RelatedPagesPromoComponentFS
} from './RelatedPagesPromo.styles.js';

import {withSitecoreContext} from '@sitecore-jss/sitecore-jss-react';
import {Text} from 'components/shared/JssOverrides';
import beanTopShine from '../../assets/images/bean-top-shine.svg';

import Grid from 'components/shared/Grid';
import Icon from 'components/shared/Icon';
import Card from 'components/shared/Card';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Button from 'components/shared/Button';

const slugify = (text) => {
    return text
        ? text
            .toString()
            .toLowerCase()
            .trim()
            .replace(/[\s\W-]+/g, '-')
        : '';
};


const RelatedPagesPromo = ({fields = {}, params = {}, sitecoreContext}) => {
    const {title = {}, relatedPages = []} = fields;
    const {site} = sitecoreContext;
    const {backgroundColor = 'yellow',landingSurvey = '', showBeanBurst = '0', showAltBeanBurst = '0', startingCardColor = 'red', id= ''} = params;
    const generatedId = id || (title && slugify(title.value));
    const productItems = relatedPages.filter(item => item.type === 'product');
    const pageItems = relatedPages.filter(item => item.type === 'page');
    const recipeItems = relatedPages.filter(item => item.type === 'recipe');
    const articleItems = relatedPages.filter(item => item.type === 'article');

    const sliderSettings = {
        dots: false,
        infinite: relatedPages.length > 4,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        arrows: false,
        responsive: [
            {
                breakpoint: 1450,
                settings: {
                    slidesToShow: 3,
                    infinite: relatedPages.length > 3,
                },
            },
            {
                breakpoint: 800,
                settings: {
                    slidesToShow: 2,
                    infinite: relatedPages.length > 1,
                },
            },
            {
                breakpoint: 650,
                settings: {
                    centerMode: true,
                    centerPadding: '0px',
                    slidesToShow: 1,
                },
            },
        ],
    };

    const altSliderSettings = {
        dots: false,
        infinite: relatedPages.length > 4,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        arrows: false,
        responsive: [
            {
                breakpoint: 1450,
                settings: {
                    slidesToShow: 3,
                    infinite: relatedPages.length > 3,
                },
            },
            {
                breakpoint: 1000,
                settings: {
                    slidesToShow: 2,
                    infinite: relatedPages.length > 1,
                },
            },
            {
                breakpoint: 650,
                settings: {
                    centerMode: true,
                    centerPadding: '0px',
                    slidesToShow: 1,
                },
            },
        ],
    };

    if (relatedPages.length === 0) {
        return null;
    }
    if(landingSurvey === 'true') {
        return (
            <RelatedPagesPromoComponentFS
                id={generatedId}
                className={`landingsurvey related-pages-${site.name}`}>
                {title && <Text tag="h2" field={title} className="section-heading sv-color" />}
                <Grid type="related" className="related-grid" id="landingSurvey">
                    {recipeItems.map((relatedPage, index) => {
                        const shapeData = {
                            title: {
                                value: relatedPage?.title,
                            },
                            image: {
                                value: {
                                    src: relatedPage?.image?.url,
                                    alt: relatedPage?.image?.alt,
                                },
                            },
                            category: {
                                value: relatedPage?.category,
                            },
                            link: {
                                href: relatedPage?.url,
                            },
                            itemId: relatedPage?.id,
                        };

                        return (
                            <Card
                                classValue={`${site.name}-card ${relatedPage?.type}`}
                                type="related"
                                ctype={relatedPage?.type}
                                key={`RelatedPage-${relatedPage?.id}-${index}`}
                                backgroundColor="blue"
                                {...shapeData}
                            />
                        );
                    })}
                </Grid>
            </RelatedPagesPromoComponentFS>
        );
    }
    if (site.name === 'bushs-beans-foodservices') {
        return (

            <RelatedPagesPromoComponentFS background={backgroundColor} className={`related-pages-${site.name}`} id={id}>
                {showBeanBurst === '1' && <Icon icon="shine" className="bean-burst" />}
                {title && <Text tag="h2" field={title} className="brown section-heading" />}
                <div className="container">
                {productItems.length > 0 && (
                    <Slider {...sliderSettings}>
                        {productItems.map((relatedPage, index) => {
                            const shapeData = {
                                title: {
                                    value: relatedPage?.title,
                                },
                                image: {
                                    value: {
                                        src: relatedPage?.image?.url,
                                        alt: relatedPage?.image?.alt,
                                    },
                                },
                                category: {
                                    value: relatedPage?.category,
                                },
                                link: {
                                    href: relatedPage?.url,
                                },
                                itemId: relatedPage?.id,
                            };

                            return (
                                <Card
                                    classValue={`${site.name}-card ${relatedPage?.type}`}
                                    type="product"
                                    ctype={relatedPage?.type}
                                    key={`RelatedPage-${relatedPage?.id}-${index}`}
                                    backgroundColor={startingCardColor}
                                    {...shapeData}
                                />
                            );
                        })}
                    </Slider>
                )}

                {recipeItems.length > 0 && (
                    <Slider {...sliderSettings}>
                        {recipeItems.map((relatedPage, index) => {
                            const shapeData = {
                                title: {
                                    value: relatedPage?.title,
                                },
                                image: {
                                    value: {
                                        src: relatedPage?.image?.url,
                                        alt: relatedPage?.image?.alt,
                                    },
                                },
                                category: {
                                    value: relatedPage?.category,
                                },
                                link: {
                                    href: relatedPage?.url,
                                },
                                itemId: relatedPage?.id,
                            };

                            return (
                                <Card
                                    classValue={`${site.name}-card ${relatedPage?.type}`}
                                    type="related"
                                    ctype={relatedPage?.type}
                                    key={`RelatedPage-${relatedPage?.id}-${index}`}
                                    backgroundColor="blue"
                                    {...shapeData}
                                />
                            );
                        })}
                    </Slider>
                )}

                {pageItems.length > 0 && (
                    <Grid type="related" className="related-grid">
                        {pageItems.map((relatedPage, index) => {
                            const shapeData = {
                                title: {
                                    value: relatedPage?.title,
                                },
                                image: {
                                    value: {
                                        src: relatedPage?.image?.url,
                                        alt: relatedPage?.image?.alt,
                                    },
                                },
                                category: {
                                    value: relatedPage?.category,
                                },
                                link: {
                                    href: relatedPage?.url,
                                },
                                itemId: relatedPage?.id,
                            };

                            return (
                                <Card
                                    classValue={`${site.name}-card ${relatedPage?.type}`}
                                    type="page"
                                    ctype={relatedPage?.type}
                                    key={`RelatedPage-${relatedPage?.id}-${index}`}
                                    backgroundColor={startingCardColor}
                                    {...shapeData}
                                />
                            );
                        })}
                    </Grid>
                )}

                {articleItems.length > 0 && (
                    <Grid type="related" className="related-grid">
                        {articleItems.map((relatedPage, index) => {
                            const shapeData = {
                                title: {
                                    value: relatedPage?.title,
                                },
                                image: {
                                    value: {
                                        src: relatedPage?.image?.url,
                                        alt: relatedPage?.image?.alt,
                                    },
                                },
                                category: {
                                    value: relatedPage?.category,
                                },
                                link: {
                                    href: relatedPage?.url,
                                },
                                itemId: relatedPage?.id,
                            };

                            return (
                                <Card
                                    classValue={`${site.name}-card ${relatedPage?.type}`}
                                    type="related"
                                    ctype={relatedPage?.type}
                                    key={`RelatedPage-${relatedPage?.id}-${index}`}
                                    backgroundColor={startingCardColor}
                                    {...shapeData}
                                />
                            );
                        })}
                    </Grid>
                )}
                </div>
                {recipeItems.length > 0 && (
                    <div className="button-container">
                        <Button id="related-pages-btn" target="_blank" text="View All Recipes" href="/recipes"
                                variant="yellowBrown" />
                    </div>
                )}
                {productItems.length > 0 && (
                    <div className="button-container">
                        <Button id="related-pages-btn" target="_blank" text="View All Products" href="/bulk-products"
                                variant="yellowBrown" />
                    </div>
                )}
            </RelatedPagesPromoComponentFS>

        );
    }
    if(id === 'bluey-recipes') {
        return (
            <RelatedPagesPromoComponentBluey background={backgroundColor} className={`related-pages-${site.name}`} id={id}>
                {showAltBeanBurst === '1' && <img src={beanTopShine} className="bean-top-shine"  alt="decorative bean image" />}
                {showBeanBurst === '1' && <Icon icon="shine" className="bean-burst" />}
                {title && <Text tag="h2" field={title} className="section-heading" />}
                <div className="button-container">
                    <Button id="related-pages-btn" target="_blank" text="View All Recipes" href="/bean-recipes"
                            variant="blue" />
                </div>
                <div className="container">
                    {recipeItems.length > 0 && (
                        <Slider {...altSliderSettings}>
                            {recipeItems.map((relatedPage, index) => {
                                const shapeData = {
                                    title: {
                                        value: relatedPage?.title,
                                    },
                                    image: {
                                        value: {
                                            src: relatedPage?.image?.url,
                                            alt: relatedPage?.image?.alt,
                                        },
                                    },
                                    category: {
                                        value: relatedPage?.category,
                                    },
                                    link: {
                                        href: relatedPage?.url,
                                    },
                                    itemId: relatedPage?.id,
                                };

                                return (
                                    <Card
                                        classValue={`${site.name}-card ${relatedPage?.type}`}
                                        type="related"
                                        ctype={relatedPage?.type}
                                        key={`RelatedPage-${relatedPage?.id}-${index}`}
                                        backgroundColor="blue"
                                        {...shapeData}
                                    />
                                );
                            })}
                        </Slider>
                    )}
                </div>
            </RelatedPagesPromoComponentBluey>
        );
    }
    return (
        <RelatedPagesPromoComponent background={backgroundColor} className={`related-pages-${site.name}`} id={id}>
            {showAltBeanBurst === '1' && <img src={beanTopShine} className="bean-top-shine"  alt="decorative bean image" />}
            {showBeanBurst === '1' && <Icon icon="shine" className="bean-burst"/>}
            {title && <Text tag="h2" field={title} className="section-heading" />}
              <Grid type="related" className="related-grid">
                {relatedPages.map((relatedPage, index) => {
                  const shapeData = {
                    title: {
                      value: relatedPage?.title,
                    },
                    image: {
                      value: {
                        src: relatedPage?.image?.url,
                        alt: relatedPage?.image?.alt,
                      },
                    },
                    category: {
                      value: relatedPage?.category,
                    },
                    link: {
                      href: relatedPage?.url,
                    },
                    itemId: relatedPage?.id,
                  };

                  return (
                    <Card
                      className={`${site.name}-card`}
                      type="related"
                      key={`RelatedPage-${relatedPage?.id}-${index}`}
                      backgroundColor={startingCardColor}
                      {...shapeData}
                    />
                  );
                })}
              </Grid>
        </RelatedPagesPromoComponent>
    );
};

export default withSitecoreContext()(RelatedPagesPromo);