import styled from 'styled-components';

export const ProductGridComponent = styled.div`
    min-height: 150px;
	background-color: ${({ theme }) => theme.colours.tan};
    .grid-top-headline{
        display: flex;
        //justify-content: space-between;
        gap: 50px;
        align-items: center;
		width: 90%;
		margin: auto;
		top: 2rem;
		position: relative;
		z-index: 9;
		padding-top: 3rem;
		background-color: ${({ theme }) => theme.colours.tan};
        h2{
		    color: ${({ theme }) => theme.colours.red};
            margin-bottom: 0;
            font-size: 50px;
			font-family: 'apercu pro';
            font-weight: 700;
        }
    }
	@media (max-width: 1100px){
		.grid-top-headline{
			h2{
				font-size: 40px;
			}
		}
	}
    @media (max-width: 768px){
        .grid-top-headline{
			flex-direction: column;
			justify-content: center;
            padding-top: 0;
            top: 2rem;
            gap: 1rem;
            h2{
                font-size: 25px;
            }
        }
    }
`;