import styled from 'styled-components';

const animationTransition = '0.3s';

export const Container = styled.section`
	max-width: 100%;
	padding: ${({ theme }) => theme.spacing(50)} 0;
	overflow: hidden;
	background-color: ${({ theme }) => theme.colours.blue};
	h2 {
		margin: 0;
		color: ${({ theme }) => theme.colours.tan};
		text-transform: uppercase;
		line-height: 1;
		text-align: left;
	}
	h1 {
		margin: 0;
		color: ${({ theme }) => theme.colours.tan};
		text-transform: uppercase;
		line-height: 1;
		text-align: left;
	}
	.title-container {
		width: 800px;
		margin: auto;
		display: block;
		text-align: left;
		max-width: 90%;
		margin-bottom: ${({ theme }) => theme.spacing(20)};
	}
	& ul {
		& .accordion-section {
			position: relative;
			margin: ${({ theme }) =>
				theme.spacing({
					top: 0,
					left: 0,
					right: 0,
					bottom: 20,
				})};
			overflow: hidden;

			button {
				position: relative;
				display: flex;
				flex-direction: row;
				min-width: 100%;
				padding: ${({ theme }) => theme.spacing(20)} ${({ theme }) => theme.spacing(0)};
				cursor: pointer;
				&:focus {
					outline: none;
				}

				${({ theme }) => theme.breakPoints.minBp('desktop')} {
					padding: ${({ theme }) => `${theme.spacing(55)} ${theme.spacing(0)}`};
				}

				h3 {
					margin: 0;
					color: ${({ theme }) => theme.colours.red};
					text-align: left;
					text-transform: uppercase;
					pointer-events: none;
					max-width: 90%;
					font-size: 22px;
					line-height: 1.2;
					position: relative;
					top: 5px;
					${({ theme }) => theme.breakPoints.minBp('desktop')} {
						margin: 0;
						text-align: left;
						font-size: 31px;
						line-height: 1.2;
					}
				}

				svg {
					width: 36px;
					height: 36px;
					color: ${({ theme }) => theme.colours.yellow};
					fill: ${({ theme }) => theme.colours.blue} !important;
					transition: all ${animationTransition};
					position: absolute;
					right: 0;
					top: 0;
					bottom: 0;
					margin-bottom: auto;
					margin-top: auto;
					${({ theme }) => theme.breakPoints.minBp('desktop')} {
						width: 77px;
						height: 77px;
					}
				}
			}

			& .accordion-section-content {
				opacity: 0;
				padding: 0 32px 32px 32px;
				& ul {
					list-style: initial;
					& li {
						margin-bottom: ${({ theme }) => theme.spacing(24)};
					}
				}
				& img {
					max-width: 100%;
					height: auto !important;
				}
			}

			& .section-wrap {
				border: ${({ theme }) => `2px solid ${theme.colours.yellow}`};
				background-color: ${({ theme }) => theme.colours.yellow};
				border-radius: ${({ theme }) => theme.spacing(10)};
				padding: ${({ theme }) =>
					theme.spacing({
						top: 0,
						left: 0,
						right: 0,
						bottom: 0,
					})};
				width: 800px;
				max-width: 90%;
				margin: auto;
				& button {
					padding: ${({ theme }) => theme.spacing(35)};
				}
			}

			${({ theme }) => theme.breakPoints.minBp('desktop')} {
				& .section-wrap {
					padding: ${({ theme }) =>
						theme.spacing({
							top: 0,
							left: 0,
							right: 0,
							bottom: 0,
						})};
				}
			}
		}

		& .active {
			margin: ${({ theme }) =>
				theme.spacing({
					top: 0,
					left: 0,
					right: 0,
					bottom: 20,
				})};
			padding-bottom: 0;
			background-color: transparent;
			button {
				background: transparent;
				background-color: transparent;
				h3 {
					color: ${({ theme }) => theme.colours.yellow};
				}
			}
			& .section-wrap {
				background-color: transparent;
				padding: ${({ theme }) =>
					theme.spacing({
						top: 0,
						left: 0,
						right: 0,
						bottom: 0,
					})};
				button {
					background: transparent;
					color: ${({ theme }) => theme.colours.yellow};
					h3 {
						color: ${({ theme }) => theme.colours.yellow};
					}
					h2 {
						color: ${({ theme }) => theme.colours.yellow};
					}
					svg {
						transform: rotate(-180deg);
						fill: ${({ theme }) => theme.colours.yellow} !important;
						color: ${({ theme }) => theme.colours.blue};
					}
				}
			}
			button {
				background: transparent;
				color: ${({ theme }) => theme.colours.yellow};
				h3 {
					color: ${({ theme }) => theme.colours.yellow};
				}
			}
			& .accordion-section-content {
				opacity: 1;
				color: #fff;
				p {
					color: #fff;
					&:last-of-type {
						margin-bottom: 0;
					}
				}
				h2,
				h3,
				h4,
				h5,
				h6 {
					color: #fff;
				}
			}
		}
	}

	.active + li {
		border-top: none;
	}
`;


export const AccordionList = styled.ul`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const AccordionItem = styled.li`
	display: flex;
	flex-basis: 100%;
	flex-direction: column;
	align-self: flex-start;
	width: 100%;
	max-width: ${({ theme }) => `calc(100% - ${theme.spacing(40)})`};
	max-width: 90%;
	margin: 0 auto;
	overflow: hidden;
	border-top: ${({ theme }) => `4px solid ${theme.colours.red}`};
	border-bottom: ${({ theme }) => `4px solid ${theme.colours.red}`};

	&:not(:last-child) {
		border-bottom: none;
	}

	&.active {
		max-width: 100%;
		background-color: ${({ theme }) => theme.colours.white};
		border-bottom: ${({ theme }) => `4px solid ${theme.colours.red}`};

		& + li {
			border-top: 0;
		}

		svg {
			transform: rotate(-180deg);
		}

		p {
			opacity: 1;
		}
	}

	${({ theme }) => theme.breakPoints.minBp('desktop')} {
		max-width: 100%;

		&.active {
			> div {
				width: 100%;
				max-width: 800px;
				margin: 0 auto;
			}

			button {
				padding: ${({ theme }) => `${theme.spacing(55)} ${theme.spacing(0)}`};
			}

			svg {
				right: 110px;
			}

			p {
				max-width: 80%;
				padding-bottom: ${({ theme }) => theme.spacing(55)};
				padding-left: ${({ theme }) => theme.spacing(0)};
			}
		}
	}

	button {
		position: relative;
		display: flex;
		flex-direction: column;
		align-items: center;
		min-width: 100%;
		padding: ${({ theme }) => theme.spacing(20)} ${({ theme }) => theme.spacing(0)};
		cursor: pointer;

		&:focus {
			outline: none;
		}

		${({ theme }) => theme.breakPoints.minBp('desktop')} {
			flex-direction: unset;
			justify-content: space-between;
			padding: ${({ theme }) => `${theme.spacing(55)} ${theme.spacing(0)}`};
		}

		h2 {
			margin: 0 0 ${({ theme }) => theme.spacing(10)};
			color: ${({ theme }) => theme.colours.red};
			text-align: center;
			text-transform: uppercase;
			pointer-events: none;

			${({ theme }) => theme.breakPoints.minBp('desktop')} {
				margin: 0 ${({ theme }) => theme.spacing(35)} 0 0;
				text-align: left;
			}
		}

		svg {
			width: 36px;
			height: 36px;
			color: ${({ theme }) => theme.colours.blue};
			transition: all ${animationTransition};

			${({ theme }) => theme.breakPoints.minBp('desktop')} {
				width: 77px;
				height: 77px;
			}
		}
	}
`;