import React from 'react';
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import {
  FeaturedHeroComponent,
  PromoWrap,
  ImageWrap,
  FoodServicesImageWrap,
  FSHeroComponent, InsideTextWrap, LandingImageWrapSmall, LandingImageWrapLarge
} from './FeaturedDetailHero.styles.js';
import PromoBox from 'components/shared/PromoBox';
import RecipePromoBox from './PromoVariants/Recipe/RecipePromo.jsx';
import ArticlePromoBox from './PromoVariants/Article/ArticlePromo.jsx';
import GeneralPromoBox from './PromoVariants/General/GeneralPromo.jsx';
import { SvgHeroWrapper } from './FeaturedDetailHero.styles.js';
import SVG from "react-inlinesvg";
import {Text} from "../shared/JssOverrides";

const FeaturedDetailHero = props => {
  const { fields = {}, params = {}, sitecoreContext = {} } = props;
  const { viewBag = {}, route = {}, site } = sitecoreContext;
  const {
    heroType = '',
    title = {},
    longName = {},
    description = {},
    backgroundImage = {},
    heroImage = {},
    author = '',
    date = '',
    recipeHighlights = [],
  } = fields;
  const {
    styleOverride = '',
    style = '',
    contentBackground = '',
    textColor = '',
    backgroundColor = '',
    heroSvg = '',
    herotextposition = '',
    smallimagesrc = '',
  } = params;
  const promoProps = {
    heroType,
    title,
    copy: description,
    author,
    date,
    recipeHighlights,
    style,
    styleOverride,
    longName,
    viewBag,
    itemId: route?.itemId,
  };
  const imageToUse = heroImage?.value?.src || backgroundImage?.value?.src;
  const heroClass = imageToUse ? `fdh-${site.name}` : `fdh-${site.name} no-img`;
  // console.log('small image src', smallimagesrc);
  // console.log('background image src', backgroundImage?.value?.src);
  const effectiveStyleOverride = styleOverride || "blue";
  if (site.name === 'bushs-beans-foodservices') {
    return (
        <FSHeroComponent
            backgroundColor={backgroundColor}
            className={`fs-hero shrink-on-print ${heroClass}`}
            data-use={imageToUse}
        >
          {imageToUse && (
              <FoodServicesImageWrap
                  backgroundImage={heroImage?.value || backgroundImage?.value}
                  className="hide-on-print"
              />
          )}
          <PromoWrap
              styleOverride={effectiveStyleOverride}
              className="promobox"
          >
            {handlePromoType(promoProps)}
          </PromoWrap>
        </FSHeroComponent>
    );
  }

  if (heroSvg === 'beansongxxx') {
    return (
        <FeaturedHeroComponent
            backgroundColor={backgroundColor}
            className="shrink-on-print wsvg"
        >
          <SvgHeroWrapper className="svg-hero-wrapper">
            <SVG src={backgroundImage?.value?.src} />
          </SvgHeroWrapper>
          <PromoWrap className="promo-wsvg">
            {handlePromoType(promoProps)}
          </PromoWrap>
        </FeaturedHeroComponent>
    );
  } else if (herotextposition === 'inside') {
    return (
        <FeaturedHeroComponent
            backgroundColor={backgroundColor}
            className={`shrink-on-print ${heroClass}`}
            data-use={imageToUse}
        >
          {imageToUse && (
              <LandingImageWrapLarge
                  backgroundImage={heroImage?.value || backgroundImage?.value}
                  className="hide-on-print"
              />
          )}
          {smallimagesrc && (
              <LandingImageWrapSmall
                  backgroundImage={smallimagesrc}
                  className="hide-on-print"
              />
          )}
          <InsideTextWrap>
            {title?.value && (
                <Text
                    className="feature-title-headline"
                    field={title}
                    tag="h1"
                />
            )}
          </InsideTextWrap>
        </FeaturedHeroComponent>
    );
  } else {
    return (
        <FeaturedHeroComponent
            backgroundColor={backgroundColor}
            className={`shrink-on-print ${heroClass}`}
            data-use={imageToUse}
        >
          {imageToUse && (
              <ImageWrap
                  backgroundImage={heroImage?.value || backgroundImage?.value}
                  className="hide-on-print"
              />
          )}
          <PromoWrap
              styleOverride={styleOverride}
              className="promobox"
          >
            {handlePromoType(promoProps)}
          </PromoWrap>
        </FeaturedHeroComponent>
    );
  }

};

const handlePromoType = props => {
  const { heroType } = props;

  switch (heroType.toLowerCase()) {
    case 'recipe':
      return <RecipePromoBox {...props} />;
    case 'article':
      return <ArticlePromoBox {...props} />;
    case 'general':
      return <GeneralPromoBox {...props} />;
    default:
      return <PromoBox {...props} />;
  }
};
export default withSitecoreContext()(FeaturedDetailHero);