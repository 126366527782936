import styled from 'styled-components';

const getVariantColours = ({ variant = 'tan', theme } = {}) => {
  const variants = {
    tan: {
      background: theme.colours.tan,
      text: theme.colours.red,
    },
    yellow: {
      background: theme.colours.yellow,
      text: theme.colours.red,
    },
    brown: {
      background: theme.colours.brown,
      text: theme.colours.yellow,
    },
    blue: {
      background: theme.colours.blue,
      text: theme.colours.yellow,
    },
  };

  if (variant in variants) return variants[variant];

  return variants.tan;
};

export const ScrollingVanityTextWithVideoComponent = styled.section`
  position: relative;
  box-sizing: border-box;
  padding: ${({ theme, hasVideo }) =>
    hasVideo
      ? theme.spacing({ top: 180, bottom: 180, left: 0, right: 0 })
      : theme.spacing({ top: 80, bottom: 80, left: 0, right: 0 })};
  background-color: ${({ theme, backgroundColor }) =>
    getVariantColours({ theme, variant: backgroundColor }).background};

  ${({ theme }) => theme.breakPoints.minBp('tablet')} {
    padding: ${({ theme, hasVideo }) =>
      hasVideo
        ? theme.spacing({ top: 220, bottom: 220, left: 0, right: 0 })
        : theme.spacing({ top: 120, bottom: 120, left: 0, right: 0 })};
  }
  &.nopadding{
    padding: 0;
  }
  .vanity {
    @supports (-webkit-text-stroke: 1px) {
      -webkit-text-stroke: 2px ${({ theme, backgroundColor }) =>
          getVariantColours({ theme, variant: backgroundColor }).text};
      -webkit-text-fill-color: transparent;
    }
  }
`;

export const ScrollingVanityTextWithVideoFS = styled.section`
  position: relative;
  box-sizing: border-box;
  background-color: ${({ theme, backgroundColor }) =>
      getVariantColours({ theme, variant: backgroundColor }).background};
    .inner-wrapper{
        white-space: nowrap;
        padding: ${({ theme, hasVideo }) =>
        hasVideo
            ? theme.spacing({ top: 180, bottom: 180, left: 0, right: 0 })
            : theme.spacing({ top: 0, bottom: 0, left: 0, right: 0 })};
        margin: ${({ theme, hasVideo }) =>
        hasVideo
            ? theme.spacing({ top: 0, bottom: 0, left: 0, right: 0 })
            : theme.spacing({ top: 80, bottom: 80, left: 0, right: 0 })};
        ${({ theme }) => theme.breakPoints.minBp('tablet')} {
          padding: ${({ theme, hasVideo }) =>
          hasVideo
              ? theme.spacing({ top: 220, bottom: 220, left: 0, right: 0 })
              : theme.spacing({ top: 0, bottom: 0, left: 0, right: 0 })};
          margin: ${({ theme, hasVideo }) =>
          hasVideo
              ? theme.spacing({ top: 0, bottom: 0, left: 0, right: 0 })
              : theme.spacing({ top: 160, bottom: 160, left: 0, right: 0 })};
        }
      .inner{
        white-space: nowrap;
      }
    }
  &.nopadding{
    padding: 0;
  }
  .vanity {
    @supports (-webkit-text-stroke: 1px) {
      -webkit-text-stroke: 2px ${({ theme, backgroundColor }) =>
          getVariantColours({ theme, variant: backgroundColor }).text};
      -webkit-text-fill-color: transparent;
    }
  }
`;

export const VideoWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: ${({ theme }) => theme.zindex.scrollingText.content};
  width: 100%;
  max-width: 90%;
  margin: 0 auto;
  transform: translate(-50%, -50%);

  ${({ theme }) => theme.breakPoints.minBp('700px')} {
    width: 860px;
  }
`;