import React from 'react';
import SectionWrapper from './ThreeColShowcase.styles.js';
import { isExperienceEditorActive, withSitecoreContext,  Image, } from '@sitecore-jss/sitecore-jss-react';
import Button from "../shared/Button";
import {RichText} from "../shared/JssOverrides";

const ThreeColShowcase = ({ fields = {}, params = {}, sitecoreContext }) => {
    const {
        backgroundColor = 'tan',
        alignment = 'left',
        headingColor = '',
        buttonColor = '',
        textColor = '',
    } = params;
    const {
        title = {},
        copy = {},
        button = {},
        columnoneimage = {},
        columnonelink = {},
        columntwoimage = {},
        columntwolink = {},
        columnthreeimage = {},
        columnthreelink = {},
    } = fields;
    const { site } = sitecoreContext;
    const experienceEditor = isExperienceEditorActive();
    return (
        <SectionWrapper className="three-col-showcase" backgroundColor={backgroundColor} alignment={alignment} site={site?.name} textColor={textColor} headingColor={headingColor}>
            <div className="collection-showcase">
                <div className="showcase-header">
                    <div className="inner">
                        {title?.value &&
                        <h2 className="main-title">
                            {title?.value || 'Love That Bean Stuff'}
                        </h2>
                        }
                    </div>
                </div>

                <div className="collections-grid">
                    <div className="collection-title-item collection-text">
                        <div className="inner">
                            {copy?.value && <p>{copy.value}</p>}
                            {button?.value?.text && button?.value?.href && (
                                <div className={`content-container ${alignment === 'center' ? 'justify-center' : ''}`}>
                                    <Button
                                        text={button.value.text}
                                        href={button.value.href}
                                        target={button.value.target}
                                        {...button.value}
                                        variant={buttonColor || "red"}
                                        className="fade-in-up"
                                    />
                                </div>
                            )}
                        </div>
                    </div>

                    {[columnoneimage, columntwoimage, columnthreeimage].map((imageField, index) => {
                        const linkField = [columnonelink, columntwolink, columnthreelink][index];
                        return (
                            <div className="collection-item" key={index}>
                                <div className="poster">
                                    {imageField?.value && (
                                        <Image
                                            field={imageField}
                                            className="collection-image"
                                            imageParams={{ mw: 600 }}
                                            alt={imageField?.alt || 'Collection Image'}
                                        />
                                    )}
                                </div>
                                {linkField?.value?.href && (
                                    <a className="collection-link" href={linkField.value.href}>
                                        <h3>{linkField.value.text}</h3>
                                        <span>→</span>
                                    </a>
                                )}
                            </div>
                        );
                    })}
                </div>
            </div>
        </SectionWrapper>
    );
};

export default withSitecoreContext()(ThreeColShowcase);