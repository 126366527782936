import React, { useState } from 'react';
import { useTransition, animated, config } from 'react-spring';
import PropTypes from 'prop-types';
import {
  FeatureRecipePromoComponent,
  FeatureRecipePromoInner,
  NewRecipeButtonWrap,
} from './FeatureRecipePromo.styles.js';

import PromoBox from 'components/shared/PromoBox';
import InterruptorRibbon from 'components/shared/InterruptorRibbon';

import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import translate from 'helpers/translate';


const FeatureRecipePromo = ({ fields = {}, params = {}, sitecoreContext = {} }) => {
  const { viewBag = {} } = sitecoreContext;
  const { recipePages = [] } = fields;

  // Read your custom params
  const {
    backgroundColor = '',
    removeTopBackground = 'false',
    showRibbon = '',
    // These are your new custom rendering params
    customImage = '',
    customTitle = '',
    customDescription = '',
    customLink = '',
    customClass = '',
    customParentClass = '',
    customId = '',
  } = params;

  const [activeIndex, setActiveIndex] = useState(0);

  // If you have a Sitecore image field for tapButtonImage you can read it as:
  const tapButtonImage = fields?.tapButtonImage?.value;

  // Convert recipePages to an array of slides
  const slides =
      Array.isArray(recipePages) && recipePages?.length > 0
          ? recipePages.map((item, index) => ({ ...item, slideIndex: index }))
          : [];

  // // Optionally add the custom slide if your custom params exist
  // // You can decide your logic — for example, only if all four are present:
  // if (customImage || customTitle || customDescription || customLink) {
  //   slides.push({
  //     title: customTitle,
  //     description: customDescription,
  //     url: customLink,
  //     image: {
  //       url: customImage,
  //       alt: 'Custom Alt Text' // change or parameterize as needed
  //     },
  //     slideIndex: slides.length,
  //   });
  // }

  // React Spring transitions for fade in/out
  const transitions = useTransition([activeIndex], (item) => item, {
    from: { opacity: 0, transform: 'translateY(0px)' },
    enter: { opacity: 1, transform: 'translateY(0px)' },
    leave: { opacity: 0, transform: 'translateY(-40px)' },
    config: config.gentle,
  });

  const handleRecipeChange = () => {
    if (activeIndex === slides.length - 1) {
      setActiveIndex(0);
    } else {
      setActiveIndex(activeIndex + 1);
    }
  };

  const title = slides[activeIndex]?.title;
  const description = slides[activeIndex]?.description;
  const url = slides[activeIndex]?.url;

  // Example logic for variant colors
  const getVariantColours = () => {
    // Your own logic here
    return 'red blue';
  };

  const featureRecipePromoClassName = [
    'feature-recipe-promo',
    getVariantColours(),
    customParentClass
  ].filter(Boolean).join(' ');

  return (
      <FeatureRecipePromoComponent className={featureRecipePromoClassName} id={customId}>
        {removeTopBackground !== 'true' && <span className="top-background" />}
        <FeatureRecipePromoInner className={customClass}>
          <div>
            {slides.length > 1 && (
                <NewRecipeButtonWrap>
                  <button onClick={handleRecipeChange}>
                    {tapButtonImage && (
                        <img
                            src={tapButtonImage.src}
                            alt="circle graphic button"
                        />
                    )}
                    {tapButtonImage?.alt && <span>{tapButtonImage?.alt}</span>}
                  </button>
                </NewRecipeButtonWrap>
            )}
            {transitions.map(({ item, key, props }, index) => {
              const image = slides[item]?.image;
              const imageUrl = image?.url ? `${image.url}?mw=800` : '';
              return (
                  <div key={`${key}-${index}`} className="recipe-image">
                    <animated.div key={key} style={{ ...props }}>
                      <img src={imageUrl} alt={image?.alt || 'Recipe Image'} />
                    </animated.div>
                  </div>
              );
            })}
          </div>
          <PromoBox
              className="promo-box"
              title={{ value: title }}
              text={{ value: description }}
              cta={{
                value: {
                  text: translate(viewBag, 'BushsBeans.Recipes.FeaturedRecipePromo.GetRecipeLinkText'),
                  href: url,
                },
              }}
          >
            {showRibbon === '1' && (
                <>
                  <InterruptorRibbon className="ribbon-left" variant="red" />
                  <InterruptorRibbon className="ribbon-right" variant="red" />
                </>
            )}
          </PromoBox>
        </FeatureRecipePromoInner>
      </FeatureRecipePromoComponent>
  );
};

FeatureRecipePromo.propTypes = {
  fields: PropTypes.object,
  params: PropTypes.shape({
    removeTopBackground: PropTypes.string,
    backgroundColor: PropTypes.string,
    showRibbon: PropTypes.string,
    customImage: PropTypes.string,
    customTitle: PropTypes.string,
    customDescription: PropTypes.string,
    customLink: PropTypes.string,
  }),
  sitecoreContext: PropTypes.object,
};

export default withSitecoreContext()(FeatureRecipePromo);