import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Image } from '@sitecore-jss/sitecore-jss-react';
import { isExperienceEditorActive, withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import { SideBySideComponent, Block } from './SideBySide.styles.js';
import PromoBox from 'components/shared/PromoBox';
import InlineSVG from "react-inlinesvg";
import baloonImg from '../../assets/images/baloon.svg';


const SideBySide = ({sitecoreContext, ...props}) => {
  const { fields = {}, params = {} } = props;
  const { title = '', copy = '', foregroundMedia = null, backgroundMedia = null, cta = {}  } = fields;
  const {
    alignment = 'left',
    contentPanelBackgroundColor = 'red',
    backgroundColor = null,
    contentPanelTitleColor = null,
    contentPanelCopyColor = null,
    contentPanelCtaColor = null,
    contentPanelTextOrentation = null,
    headingClass = null,
    textClass = null,
    backgroundOverride = null,
    componentclass = null,
    id = null,
  } = params;
  const backgroundImageTitle = backgroundMedia?.value?.title || '';
  const { site } = sitecoreContext;
  // console.log('side by side params', params);
  const contentBlock = {
    title,
    copy,
    cta,
    backgroundColor: contentPanelBackgroundColor,
    titleColor: contentPanelTitleColor,
    copyColor: contentPanelCopyColor,
    ctaColor: contentPanelCtaColor,
    cssClass: backgroundImageTitle === 'hero' ? 'hero-bg-lg' : '',
  };
  return (
      <SideBySideComponent
          alignment={alignment}
          variant={contentPanelBackgroundColor}
          backgroundColor={backgroundColor}
          backgroundImage={backgroundMedia?.value}
          className={`${backgroundImageTitle === 'hero' ? 'hero-bg-lg' : ''} bg-${backgroundOverride} ${componentclass}`}
          id={id}
      >
        {site && site.name === 'bushs-beans-foodservices' ? (
            <div className={`container ${backgroundMedia?.value?.src ? '' : 'no-bg'}`}
              alignment={alignment}
              variant={contentPanelBackgroundColor}
              >
              <Block className="promoboxWrap">
                <PromoBox
                    {...contentBlock}
                    variant={contentPanelBackgroundColor}
                    className={`promo-box text-align-${contentPanelTextOrentation} bg-${backgroundOverride}`}
                    titleColor={contentPanelTitleColor}
                    copyColor={contentPanelCopyColor}
                    headingClass={headingClass}
                    textClass={textClass}
                />
              </Block>
              {alignment !== 'center' && (
                  <Block className="cont-img-block">
                    {foregroundMedia && <Image field={foregroundMedia}/>}
                  </Block>
              )}
            </div>
        ) : (
            <div className="inner"
                 alignment={alignment}
                 variant={contentPanelBackgroundColor}
            >
              {id === 'bluey-sbs' && (
                <img className="baloon-graphic" src={baloonImg} alt="baloon graphic"/>
              )}
              <Block className="promoboxWrap">
                <PromoBox
                    {...contentBlock}
                    variant={contentPanelBackgroundColor}
                    className={`promo-box text-align-${contentPanelTextOrentation}`}
                    titleColor = {contentPanelTitleColor}
                    copyColor = {contentPanelCopyColor}
                    textClass={textClass}
                />
              </Block>
              {alignment !== 'center' && (
                  <Block className="cont-img-block">
                    {foregroundMedia && <Image field={foregroundMedia} />}
                  </Block>
              )}
            </div>
        )}
      </SideBySideComponent>
  );
};

SideBySide.propTypes = {
  fields: PropTypes.shape({
    title: PropTypes.string,
    copy: PropTypes.string,
    foregroundMedia: PropTypes.object,
    backgroundMedia: PropTypes.object,
    cta: PropTypes.shape({
      href: PropTypes.string,
      text: PropTypes.string,
    }),
  }),
  params: PropTypes.shape({
    alignment: PropTypes.oneOf(['left', 'center', 'right']),
    contentPanelBackgroundColor: PropTypes.oneOf(['red', 'yellow', 'blue', 'transparent']),
    backgroundColor: PropTypes.string,
    contentPanelTitleColor: PropTypes.string,
    contentPanelCopyColor: PropTypes.string,
    contentPanelCtaColor: PropTypes.string,
    contentPanelTextOrentation: PropTypes.string,
  }),
  sitecoreContext: PropTypes.object.isRequired,
};

export default withSitecoreContext()(SideBySide);