import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import {isExperienceEditorActive, withSitecoreContext, Image} from '@sitecore-jss/sitecore-jss-react';
import {
    HeroWithTextLeftComponent,
    MediaDiv,
    CopyDiv,
    FadeInUpElement,
    FadeInUpElementQuart,
    CloudsWrapper, HeroWithTextLeftBluey, CopyDivBluey, MediaDivBluey
} from './HeroWithTextLeft.styles.js';
import {isServer} from "@sitecore-jss/sitecore-jss";
import {RichText} from "../shared/JssOverrides";
import Button from "../shared/Button";
import skyclouds from '../../assets/images/skyclouds.png';
import beanCanGif from '../../assets/images/can-peek-big-opt.gif';



const HeroWithTextLeft = props => {
    const {fields = {}, params = {}, sitecoreContext = {}} = props;
    const {viewBag = {}, route = {}} = sitecoreContext;

    const {
        heroType = '',
        title = {},
        description = {},
        backgroundImage = {},
        heroImage = {},
        foregroundImage = {},
        topheading = '',
        largeheading = '',
        heading = '',
        button = {},
        copyImage = {},
        onClick = () => {
        },
        children,
    } = fields;
    const {
        backgroundColor = null,
        contentPanelTitleColor = null,
        contentPanelCopyColor = null,
        contentPanelCtaColor = null,
        heroSvg = '',
        style = '',
        noContainer = false,
        buttonText = '',
        buttonLink = '',
        buttonTarget = '',
        buttonColor = '',
        headingColor = '',
        ctaColor = '',
        copyImg = '',
        copyImgAlt = '',
        textImg = '',
        textImgAlt = '',
        id = '',
        clouds = 'false',
    } = params;
    const promoProps = {
        heroType,
        title,
        copy: description,
        itemId: route?.itemId,
    };
    const experienceEditor = isExperienceEditorActive();

    const [inView, setInView] = useState(false);
    const heroRef = useRef(null);

    const containerClass = noContainer ? 'grid-div c-wrap' : 'container c-wrap';

    const imageToUse = backgroundImage?.value?.src || heroImage?.value?.src;
    const loaded = inView;


    const [isPageLoaded, setIsPageLoaded] = useState(false);
    useEffect(() => {
        const handleWindowLoad = () => {
            setIsPageLoaded(true);
        };
        if (document.readyState === 'complete') {
            setIsPageLoaded(true);
        } else {
            window.addEventListener('load', handleWindowLoad);
        }
        return () => {
            window.removeEventListener('load', handleWindowLoad);
        };
    }, []);


    useEffect(() => {
        const observer = new IntersectionObserver(
            (entries) => {
                if (entries[0].isIntersecting) {
                    setInView(true);
                    observer.disconnect();
                }
            },
            { threshold: 0.1 }
        );
        if (heroRef.current) {
            observer.observe(heroRef.current);
        }
        return () => {
            if (heroRef.current) {
                observer.unobserve(heroRef.current);
            }
        };
    }, []);

    if (experienceEditor || sitecoreContext?.pageEditing) {
        return (
            <HeroWithTextLeftComponent
                backgroundColor={backgroundColor}
                headingColor={headingColor}
                buttonColor={buttonColor}
                className="shrink-on-print"
                data-use={imageToUse}
                aria-label="Hero section with image and text"
                data-loaded={loaded}
                ref={heroRef}
                id={id}
            >
                {clouds === 'true' && (
                    <CloudsWrapper>
                        <img src={skyclouds} alt="Sky clouds" />
                    </CloudsWrapper>
                )}
                <div className={containerClass}>
                    <CopyDiv className="copyDiv" backgroundColor={backgroundColor}>
                        <div className="inner">
                            {copyImg && (
                                <img className="copy-img" src={copyImg} alt="{copyImgAlt}" />
                            )}
                            {textImg && (
                                <img className="copy-img" src={textImg} alt="{textImgAlt}" />
                            )}
                            {copyImage?.value?.src && (
                                <Image
                                    field={copyImage}
                                    imageParams={{ mw: 600, mh: 600 }}
                                    className="copy-img"
                                />
                            )}
                            {topheading?.value && (
                                <p dangerouslySetInnerHTML={{__html: topheading?.value}}></p>
                            )}
                            {largeheading?.value && (
                                <p dangerouslySetInnerHTML={{__html: largeheading?.value}}></p>
                            )}
                            {heading?.value && (
                                <p dangerouslySetInnerHTML={{__html: heading?.value}}></p>
                            )}
                            {description?.value && (
                                <p dangerouslySetInnerHTML={{__html: description?.value}}></p>
                            )}
                            {children}
                            {button?.value.text && button?.value.href && (
                                <div className="buttonWrapper">
                                    <Button
                                        text={button?.value.text}
                                        href={button?.value.href}
                                        target={button?.value.target}
                                        onClick={onClick}
                                        {...button?.value}
                                        variant={buttonColor || "solidred"}
                                        className="fade-in-up"
                                    />
                                </div>
                            )}
                            {button.text && button.href && (
                                <Button
                                    text={button?.text}
                                    href={button?.href}
                                    target={button?.target}
                                    onClick={onClick}
                                    {...button?.value}
                                    size="large"
                                    variant={buttonColor || "solidred"}
                                />
                            )}
                            {buttonText && buttonLink && (
                                <div className="buttonWrapper">
                                    <Button
                                        text={buttonText}
                                        href={buttonLink}
                                        target={buttonTarget}
                                        variant={buttonColor || "solidred"}
                                        className="fade-in-up"
                                    />
                                </div>
                            )}
                        </div>
                    </CopyDiv>
                    <MediaDiv>
                        {foregroundImage?.value?.src && (
                            <img src={foregroundImage.value.src} alt={foregroundImage.value.alt}/>
                        )}
                    </MediaDiv>
                </div>
            </HeroWithTextLeftComponent>
        );
    }
    if (id === 'bluey-bg') {
        const blueyForegroundSrc = isPageLoaded
            ? beanCanGif
            : foregroundImage?.value?.src;
        return(
            <HeroWithTextLeftBluey
                backgroundColor={backgroundColor}
                headingColor={headingColor}
                buttonColor={buttonColor}
                data-use={imageToUse}
                aria-label="Hero section with image and text"
                data-loaded={loaded}
                ref={heroRef}
                id={id}
            >
                {clouds === 'true' && (
                    <CloudsWrapper>
                        <img src={skyclouds} alt="Sky clouds" />
                    </CloudsWrapper>
                )}
                <div className={containerClass}>
                    <CopyDivBluey className="copyDiv" backgroundColor={backgroundColor}>
                        <div className="inner">

                            {topheading?.value && (
                                <FadeInUpElement style={{ animationDelay: '1s' }}>
                                    <h1 className="top-heading fade-in-up" dangerouslySetInnerHTML={{__html: topheading?.value}}></h1>
                                </FadeInUpElement>
                            )}
                            <FadeInUpElementQuart style={{ animationDelay: '0.5s' }}>
                                {heading?.value && (
                                    <span className="small-heading fade-in-up" dangerouslySetInnerHTML={{__html: heading?.value}}></span>
                                )}
                                {description?.value && (
                                    <p className="description fade-in-up"
                                       dangerouslySetInnerHTML={{
                                           __html: description?.value.replace(/(®|&reg;|&#174;|&trade;|™|&#8482;)/g, '<sup>$1</sup>')
                                       }}
                                    />
                                )}
                                {children}
                                {button?.value.text && button?.value.href && (
                                    <div className="buttonWrapper">
                                        <Button
                                            text={button?.value.text}
                                            href={button?.value.href}
                                            target={button?.value.target}
                                            onClick={onClick}
                                            {...button?.value}
                                            variant={buttonColor || "solidred"}
                                            className="fade-in-up"
                                        />
                                    </div>
                                )}
                            </FadeInUpElementQuart>
                        </div>
                    </CopyDivBluey>
                    <MediaDivBluey>
                        {foregroundImage?.value && (
                            <img
                                style={{ animationDelay: '0.5s' }}
                                src={blueyForegroundSrc}
                                alt={foregroundImage.value.alt}
                            />
                        )}
                    </MediaDivBluey>
                </div>
            </HeroWithTextLeftBluey>
        );
    }
    return (
        <HeroWithTextLeftComponent
            backgroundColor={backgroundColor}
            headingColor={headingColor}
            buttonColor={buttonColor}
            data-use={imageToUse}
            aria-label="Hero section with image and text"
            data-loaded={loaded}
            ref={heroRef}
            id={id}
        >
            {clouds === 'true' && (
                <CloudsWrapper>
                    <img src={skyclouds} alt="Sky clouds" />
                </CloudsWrapper>
            )}
            <div className={containerClass}>
                <CopyDiv className="copyDiv" backgroundColor={backgroundColor}>
                    <div className="inner">
                        {copyImg && (
                            <FadeInUpElement style={{ animationDelay: '1s' }}>
                            <img className="copy-img" src={copyImg} alt="{copyImgAlt}" />
                            </FadeInUpElement>
                        )}
                        {copyImage?.value?.src && (
                            <FadeInUpElement style={{ animationDelay: '1s' }}>
                            <Image
                                field={copyImage}
                                imageParams={{ mw: 600, mh: 600 }}
                                className="copy-img"
                            />
                            </FadeInUpElement>
                        )}
                        {topheading?.value && (
                            <FadeInUpElement style={{ animationDelay: '1s' }}>
                                <h1 className="top-heading fade-in-up" dangerouslySetInnerHTML={{__html: topheading?.value}}></h1>
                            </FadeInUpElement>
                        )}
                        {largeheading?.value && (
                            <FadeInUpElement style={{ animationDelay: '1.4s' }}>
                                <p className="large-heading fade-in-up" dangerouslySetInnerHTML={{__html: largeheading?.value}}></p>
                            </FadeInUpElement>
                        )}
                        <FadeInUpElementQuart style={{ animationDelay: '0.5s' }}>
                            {heading?.value && (
                                <span className="small-heading fade-in-up" dangerouslySetInnerHTML={{__html: heading?.value}}></span>
                            )}
                            {description?.value && (
                                <p className="description fade-in-up"
                                    dangerouslySetInnerHTML={{
                                        __html: description?.value.replace(/(®|&reg;|&#174;|&trade;|™|&#8482;)/g, '<sup>$1</sup>')
                                    }}
                                />
                            )}
                            {children}
                            {button?.value.text && button?.value.href && (
                                <div className="buttonWrapper">
                                    <Button
                                        text={button?.value.text}
                                        href={button?.value.href}
                                        target={button?.value.target}
                                        onClick={onClick}
                                        {...button?.value}
                                        variant={buttonColor || "solidred"}
                                        className="fade-in-up"
                                    />
                                </div>
                            )}
                            {button.text && button.href && (
                                <div className="buttonWrapper">
                                    <Button
                                        text={button?.text}
                                        href={button?.href}
                                        target={button?.target}
                                        onClick={onClick}
                                        {...button?.value}
                                        variant={buttonColor || "solidred"}
                                        className="fade-in-up"
                                    />
                                </div>
                            )}
                            {buttonText && buttonLink && (
                                <div className="buttonWrapper">
                                    <Button
                                        text={buttonText}
                                        href={buttonLink}
                                        target={buttonTarget}
                                        variant={buttonColor || "solidred"}
                                        className="fade-in-up"
                                    />
                                </div>
                            )}
                        </FadeInUpElementQuart>
                    </div>
                </CopyDiv>
                <MediaDiv>
                    {foregroundImage?.value?.src && (
                        <img style={{ animationDelay: '0.5s' }} src={foregroundImage.value.src} alt={foregroundImage.value.alt}/>
                    )}
                </MediaDiv>
            </div>
        </HeroWithTextLeftComponent>
    );
};

export default withSitecoreContext()(HeroWithTextLeft);