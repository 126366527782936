import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import Joi from 'joi';
import { ContactRepFormComponent, FormContent, ContactUsCardsContainer } from './ContactRepForm.styles.js';

import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import { Text, RichText } from 'components/shared/JssOverrides';

import ContactRepHero from './components/ContactRepHero.jsx';
import Select from 'components/shared/Select';
import Input from 'components/shared/Input';
import Textarea from 'components/shared/Textarea';
import Button from 'components/shared/Button';
import Spinner from 'components/shared/LoadingSpinner';

import translate from 'helpers/translate';
import { useForm } from 'react-hook-form';

import { useFetch } from 'hooks';
import { searchConfig } from 'helpers/searchHelpers';
import { zipCodeRegex } from 'helpers/regexHelpers';

// TODO: form endpoint needs hooking up with success/error state
const ContactRepForm = ({ fields, sitecoreContext: { viewBag, ...context }, rendering, ...props }) => {
  const { handleSubmit, register, errors, setValue, getValues, reset } = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onSubmit',
  });
    const [formSubject, setFormSubject] = useState(null);
    const [formSegment, setFormSegment] = useState('');
    const [formDistributor, setFormDistributor] = useState('');
    const [formSample, setFormSample] = useState('');
    const formRef = useRef(null);
    const [requestBody, setRequestBody] = useState(null);
    const [formData, setFormData] = useState({
        units: '1',
        sample: '',
        otherSample: '',
        distributor: '',
        otherDistributor: '',
        segment: '',
        otherSegment: '',
    });
    const segmentOptionsMap = {
        1: 'K12',
        2: 'College & University',
        3: 'Restaurant',
        4: 'Other'
    }
    const distributorOptionsMap = {
        1:'Sysco',
        2:'US Foods',
        3:'Performance Food Group',
        4:'Gordon Foodservice',
        5:'Restaurant Depot',
        6:'Ben E Keith',
        7:'Cheney Brothers',
        8:'Shamrock',
        9:'Goldstar',
        10:'Nicholas',
        11:'Labatt ',
        12:'Dont know',
        13:'Not Applicable',
        14:'Other',
    }
    const sampleOptionsMap = {
        1:'Easy Entrees™ Texas RanchEasy Entrees™ Texas Ranchero Pinto Beans',
        2:'Easy Entrees™ Taco FieEasy Entrees™ Taco Fiesta Black Beans',
        3:'Chili BeanChili Beans in Mild Sauce',
        4:'OrigiOriginal Baked Beans',
        5:'Bean Pot® Vegetarian Baked Beans',
        6:'Bean Pot® Vegetarian Baked Beans Reduced Sodium',
        7:'Other',
    }
    const subjectOptionsMap = {
        1: 'General Inquiry',
        2: 'Praise',
        3: 'Product Feedback',
        4: 'Suggestion',
        5: 'Sample Request',
        6: 'Privacy Request',
        7: 'Other'
    };
    const countryOptionsMap = {
        1: 'United States of America',
        2: 'Canada'
    };
    const stateOrProvinceOptionsMap = {
        1: 'Alabama',
        2: 'Alaska',
        3: 'Arizona',
        4: 'Arkansas',
        5: 'California',
        6: 'Colorado',
        7: 'Connecticut',
        8: 'Delaware',
        9: 'Florida',
        10: 'Georgia',
        11: 'Hawaii',
        12: 'Idaho',
        13: 'Illinois',
        14: 'Indiana',
        15: 'Iowa',
        16: 'Kansas',
        17: 'Kentucky',
        18: 'Louisiana',
        19: 'Maine',
        20: 'Maryland',
        21: 'Massachusetts',
        22: 'Michigan',
        23: 'Minnesota',
        24: 'Mississippi',
        25: 'Missouri',
        26: 'Montana',
        27: 'Nebraska',
        28: 'Nevada',
        29: 'New Hampshire',
        30: 'New Jersey',
        31: 'New Mexico',
        32: 'New York',
        33: 'North Carolina',
        34: 'North Dakota',
        35: 'Ohio',
        36: 'Oklahoma',
        37: 'Oregon',
        38: 'Pennsylvania',
        39: 'Rhode Island',
        40: 'South Carolina',
        41: 'South Dakota',
        42: 'Tennessee',
        43: 'Texas',
        44: 'Utah',
        45: 'Vermont',
        46: 'Virginia',
        47: 'Washington',
        48: 'Washington, D.C.',
        49: 'West Virginia',
        50: 'Wisconsin',
        51: 'Wyoming',
        52: 'Alberta',
        53: 'British Columbia',
        54: 'Manitoba',
        55: 'New Brunswick',
        56: 'Newfoundland & Labrador',
        57: 'Nova Scotia',
        58: 'Northwest Territories',
        59: 'Nunavut',
        60: 'Ontario',
        61: 'Prince Edward Island',
        62: 'Quebec',
        63: 'Saskatchewan',
        64: 'Yukon'
    };
    function getSubjectText(subjectOptionNumber) {
        return subjectOptionsMap[subjectOptionNumber] || 'General Inquiry';
    }
    function getCountryText(countryOptionNumber) {
        return countryOptionsMap[countryOptionNumber] || 'USA';
    }
    function getStateOrProvinceText(stateOrProvinceOptionNumber) {
        return stateOrProvinceOptionsMap[stateOrProvinceOptionNumber] || '';
    }
    function getSegmentText(segmentOptionNumber) {
        return segmentOptionsMap[segmentOptionNumber] || '';
    }
    function getDistributorText(distributorOptionNumber) {
        return distributorOptionsMap[distributorOptionNumber] || '';
    }
    function getSampleText(sampleOptionNumber) {
        return sampleOptionsMap[sampleOptionNumber] || '';
    }


    const handleReset = () => {
        reset();
        setFormData({
            units: 1,
            sample: '',
            otherSample: '',
            distributor: '',
            otherDistributor: '',
            segment: '',
            otherSegment: '',
        });
        if (formRef?.current) {
            const selects = formRef.current.querySelectorAll('select');
            selects.forEach(select => (select.selectedIndex = 0));
        }
    };

  const { data, loading, error, fetchLazy } = useFetch(
      undefined,
      {
        method: 'POST',
        ...(requestBody ? { body: { ...requestBody } } : {}),
      },
      'text'
  );

  useEffect(() => {
    if (typeof window !== 'undefined') {
      if (new URL(window.location.href).searchParams.get('ref') === 'privacy') {
        const subject = fields?.subjectOptions.find(({ value }) => value === 'Privacy Request');
        setFormSubject(subject);
      }
    }
  }, []);

  if (error) {
    return (
        <ContactRepFormComponent>
          {fields?.title?.value && (
              <ContactRepHero>
                <Text tag="h1" className="main-title" field={fields?.title} />
              </ContactRepHero>
          )}
          <div className="failure-message">
            {fields?.failureTitle && <Text tag="h2" field={fields?.failureTitle} />}
            {fields?.failureMessage && <RichText tag="p" field={fields?.failureMessage} />}
          </div>
        </ContactRepFormComponent>
    );
  }

    if (data === 'Submission successful.') {
        return (
            <ContactRepFormComponent>
                {fields?.title?.value && (
                    <ContactRepHero>
                        <Text tag="h1" className="main-title" field={fields?.title} />
                    </ContactRepHero>
                )}
                <div className="success-message">
                    <Text tag="h2" field={fields?.successTitle || { value: "Thanks for contacting us" }} />
                    <RichText tag="p" field={fields?.successMessage || { value: "Your message has been sent. Someone will review your message as soon as possible and get back to you." }} />
                    <div className="contact-form-divider" />
                    <RichText tag="p" field={fields?.privacyPolicyText || { value: "Important note: You are releasing the rights to the use of anything you submit to Bush Brothers & Company. This information is for Bush Brothers & Company internal use only. For details, see our Privacy Policy." }} />
                </div>
            </ContactRepFormComponent>
        );
    }

  return (
      <ContactRepFormComponent id="contact-rep-form">
        {fields?.title?.value && (
            <ContactRepHero>
              <Text tag="h1" className="main-title" field={fields?.title} />
            </ContactRepHero>
        )}
        {loading ? (
            <Spinner />
        ) : (
            <form
                ref={formRef}
                onSubmit={handleSubmit(async (data) => {
                    const subjectText = getSubjectText(Number(data.subjectOptions));
                    const requestBody = {
                        subject: subjectText,
                        firstName: data.firstName,
                        lastName: data.lastName,
                        emailAddress: data.email,
                        telephoneNo: data?.telephone || '',
                        addressLine1: data?.mailingAddress || '',
                        addressLine2: data?.mailingAddressTwo || '',
                        city: data?.city || '',
                        country: getCountryText(Number(data.countryOptions)) || '',
                        stateProvince: getStateOrProvinceText(Number(data.stateOrProvinceOptions)) || '',
                        zipCode: data?.zip || '',
                        units: formData.units.toString(),
                        sample: formData?.sample || '',
                        otherSample: formData?.otherSample || '',
                        distributor: formData?.distributor || '',
                        otherDistributor: formData?.otherDistributor || '',
                        segment: formData?.segment || '',
                        otherSegment: formData?.otherSegment || '',
                        message: data?.yourMessage || '',
                    };
                    setRequestBody(requestBody);
                    const selectedEndpoint = (subjectText === 'Privacy Request')
                        ? searchConfig({type: 'foodserviceSample'}).endpoint
                        : searchConfig({type: 'contactHq'}).endpoint;

                    fetchLazy(selectedEndpoint);
                })}
            >
                <FormContent>
                    {fields?.introCopy && (
                        <div className="intro-copy">
                            <Text tag="p" field={fields?.introCopy}/>
                        </div>
                    )}
                    <div className="subject-section">
                        {fields?.subjectSectionTitle && (
                            <Text tag="h2" className="require-title" field={fields?.subjectSectionTitle}/>
                        )}
                        {translate(viewBag, 'BushsBeans.Forms.ContactUs.Other.RequiredHelperText') && (
                            <p className="require-title">
                                {translate(viewBag, 'BushsBeans.Forms.ContactUs.Other.RequiredHelperText')}
                            </p>
                        )}
                        <Select
                            required
                            label={translate(viewBag, 'BushsBeans.Forms.ContactUs.Fields.SubjectLabel')}
                            placeholder={translate(viewBag, 'BushsBeans.Forms.ContactUs.Fields.SubjectPlaceholder')}
                            options={[
                                {value: 'General Inquiry', label: 'General Inquiry'},
                                {value: 'Praise', label: 'Praise'},
                                {value: 'Product Feedback', label: 'Product Feedback'},
                                {value: 'Suggestion', label: 'Suggestion'},
                                {value: 'Sample Request', label: 'Sample Request'},
                                {value: 'Privacy Request', label: 'Privacy Request'},
                                {value: 'Other', label: 'Other'},
                            ]}
                            error={errors?.subjectLabel?.message}
                            name="subjectOptions"
                            defaultValue={formSubject && fields?.subjectOptions.map(({value}) => value).indexOf(formSubject.value) + 1}
                            inputRef={register({required: true})}
                            onChange={event => {
                                const subject = getSubjectText(Number(event.target.value));
                                setFormSubject({value: subject});
                            }}
                        />
                    </div>
                    <div className="about-section">
                        {fields?.contactInfoSectionTitle && <Text tag="h2" field={fields?.contactInfoSectionTitle}/>}
                        <div className="field-row">
                            <div>
                                <Input
                                    type="text"
                                    required
                                    label={translate(viewBag, 'BushsBeans.Forms.ContactUs.Fields.FirstNameLabel')}
                                    placeholder={translate(viewBag, 'BushsBeans.Forms.ContactUs.Fields.FirstNamePlaceholder')}
                                    name="firstName"
                                    error={errors?.firstName?.message}
                                    inputRef={register({
                                        required: translate(viewBag, 'BushsBeans.Forms.ContactUs.Errors.FirstNameValidation'),
                                        validate: value => {
                                            const validation = Joi.string().pattern(
                                                /^[a-zA-Z]+(([',. -][a-zA-Z ])?[a-zA-Z]*)*$/
                                            );
                                            return !validation.validate(value).error || translate(viewBag, 'BushsBeans.Forms.ContactUs.Errors.FirstNameValidation');
                                        },
                                    })}
                                />
                            </div>
                            <div>
                                <Input
                                    type="text"
                                    required
                                    label={translate(viewBag, 'BushsBeans.Forms.ContactUs.Fields.LastNameLabel')}
                                    placeholder={translate(viewBag, 'BushsBeans.Forms.ContactUs.Fields.LastNamePlaceholder')}
                                    name="lastName"
                                    error={errors?.lastName?.message}
                                    inputRef={register({
                                        required: translate(viewBag, 'BushsBeans.Forms.ContactUs.Errors.LastNameValidation'),
                                        validate: value => {
                                            const validation = Joi.string().pattern(
                                                /^[a-zA-Z]+(([',. -][a-zA-Z ])?[a-zA-Z]*)*$/
                                            );
                                            return !validation.validate(value).error || translate(viewBag, 'BushsBeans.Forms.ContactUs.Errors.LastNameValidation');
                                        },
                                    })}
                                />
                            </div>
                        </div>
                        <div className="field-row">
                            <div>
                                <Input
                                    type="text"
                                    required
                                    label={translate(viewBag, 'BushsBeans.Forms.ContactUs.Fields.EmailAddressLabel')}
                                    placeholder={translate(viewBag, 'BushsBeans.Forms.ContactUs.Fields.EmailAddressPlaceholder')}
                                    name="email"
                                    error={errors?.email?.message}
                                    inputRef={register({
                                        required: translate(viewBag, 'BushsBeans.Forms.ContactUs.Errors.EmailAddressValidation'),
                                        validate: value => {
                                            const validation = Joi.string().email({tlds: {allow: false}});
                                            return !validation.validate(value).error || translate(viewBag, 'BushsBeans.Forms.ContactUs.Errors.EmailAddressValidation');
                                        },
                                    })}
                                />
                            </div>
                            <div>
                                <Input
                                    type="text"
                                    required={formSubject?.value === 'Product Feedback'}
                                    name="telephone"
                                    label={translate(viewBag, 'BushsBeans.Forms.ContactUs.Fields.TelephoneLabel')}
                                    placeholder={translate(viewBag, 'BushsBeans.Forms.ContactUs.Fields.TelephonePlaceholder')}
                                    error={errors?.telephone?.message}
                                    inputRef={register({
                                        required:
                                            formSubject?.value === 'Product Feedback' &&
                                            translate(viewBag, 'BushsBeans.Forms.ContactUs.Errors.TelephoneValidation'),
                                        validate: value => {
                                            const validation = Joi.string().pattern(
                                                /^\W?\d*?\W*?(?<area>\d{3})\W*?(?<group1>\d{3})\W*?(?<group2>\d{4})\W*?$/
                                            );
                                            if (formSubject?.value !== 'Product Feedback' && value.length === 0) {
                                                return true;
                                            }
                                            return !validation.validate(value).error || translate(viewBag, 'BushsBeans.Forms.ContactUs.Errors.TelephoneValidation');
                                        },
                                    })}
                                />
                            </div>
                        </div>
                        {formSubject?.value !== 'Privacy Request' && (
                            <div className="field-row">
                                <div>
                                    <Input
                                        type="text"
                                        required={formSubject?.value === 'Product Feedback'}
                                        label={translate(viewBag, 'BushsBeans.Forms.ContactUs.Fields.MailingAddressLabel')}
                                        placeholder={translate(viewBag, 'BushsBeans.Forms.ContactUs.Fields.MailingAddressPlaceholder')}
                                        name="mailingAddress"
                                        error={errors?.mailingAddress?.message}
                                        inputRef={register({
                                            required:
                                                formSubject?.value === 'Product Feedback' &&
                                                translate(viewBag, 'BushsBeans.Forms.ContactUs.Errors.MailingAddressValidation'),
                                            validate: value => {
                                                const validation = Joi.string().pattern(
                                                    /^[a-zA-Z0-9]+[\s]*[a-zA-Z0-9.\-,#]+[\s]*[a-zA-Z0-9.\-,#]+[a-zA-Z0-9\s.\-,#]*$/
                                                );
                                                if (formSubject?.value !== 'Product Feedback' && value.length === 0) {
                                                    return true;
                                                }
                                                return !validation.validate(value).error || translate(viewBag, 'BushsBeans.Forms.ContactUs.Errors.MailingAddressValidation');
                                            },
                                        })}
                                    />
                                </div>
                                <div>
                                    <Input
                                        type="text"
                                        label={translate(viewBag, 'BushsBeans.Forms.ContactUs.Fields.MailingAddress2Label')}
                                        placeholder={translate(viewBag, 'BushsBeans.Forms.ContactUs.Fields.MailingAddress2Placeholder')}
                                        name="mailingAddressTwo"
                                        error={errors?.mailingAddressTwo?.message}
                                        inputRef={register({
                                            required: false,
                                            validate: value => {
                                                const validation = Joi.string().pattern(
                                                    /^[a-zA-Z0-9]+[\s]*[a-zA-Z0-9.\-,#]+[\s]*[a-zA-Z0-9.\-,#]+[a-zA-Z0-9\s.\-,#]*$/
                                                );
                                                if (value.length === 0) {
                                                    return true;
                                                }
                                                return !validation.validate(value).error || translate(viewBag, 'BushsBeans.Forms.ContactUs.Errors.MailingAddressValidation');
                                            },
                                        })}
                                    />
                                </div>
                            </div>
                        )}
                        {formSubject?.value !== 'Privacy Request' && (
                            <div className="field-row">
                                <div>
                                    <Input
                                        type="text"
                                        required
                                        label={translate(viewBag, 'BushsBeans.Forms.ContactUs.Fields.CityLabel')}
                                        placeholder={translate(viewBag, 'BushsBeans.Forms.ContactUs.Fields.CityPlaceholder')}
                                        name="city"
                                        error={errors?.city?.message}
                                        inputRef={register({
                                            required:
                                                (formSubject?.value === 'Product Feedback' || formSubject?.value === 'Sample Request') &&
                                                translate(viewBag, 'BushsBeans.Forms.ContactUs.Errors.CityValidation'),
                                            validate: value => {
                                                const validation = Joi.string().pattern(
                                                    /^[a-zA-Z]+(([',. -][a-zA-Z ])?[a-zA-Z]*)*$/
                                                );
                                                if (formSubject?.value !== 'Product Feedback' && value.length === 0) {
                                                    return true;
                                                }
                                                return !validation.validate(value).error || translate(viewBag, 'BushsBeans.Forms.ContactUs.Errors.CityValidation');
                                            },
                                        })}
                                    />
                                </div>
                                <div>
                                    <Select
                                        label={translate(viewBag, 'BushsBeans.Forms.ContactUs.Fields.StateProvinceLabel')}
                                        placeholder={translate(viewBag, 'BushsBeans.Forms.ContactUs.Fields.StateProvincePlaceholder')}
                                        options={[
                                            {value: 'Alabama', label: 'Alabama'},
                                            {value: 'Alaska', label: 'Alaska'},
                                            {value: 'Arizona', label: 'Arizona'},
                                            {value: 'Arkansas', label: 'Arkansas'},
                                            {value: 'California', label: 'California'},
                                            {value: 'Colorado', label: 'Colorado'},
                                            {value: 'Connecticut', label: 'Connecticut'},
                                            {value: 'Delaware', label: 'Delaware'},
                                            {value: 'Florida', label: 'Florida'},
                                            {value: 'Georgia', label: 'Georgia'},
                                            {value: 'Hawaii', label: 'Hawaii'},
                                            {value: 'Idaho', label: 'Idaho'},
                                            {value: 'Illinois', label: 'Illinois'},
                                            {value: 'Indiana', label: 'Indiana'},
                                            {value: 'Iowa', label: 'Iowa'},
                                            {value: 'Kansas', label: 'Kansas'},
                                            {value: 'Kentucky', label: 'Kentucky'},
                                            {value: 'Louisiana', label: 'Louisiana'},
                                            {value: 'Maine', label: 'Maine'},
                                            {value: 'Maryland', label: 'Maryland'},
                                            {value: 'Massachusetts', label: 'Massachusetts'},
                                            {value: 'Michigan', label: 'Michigan'},
                                            {value: 'Minnesota', label: 'Minnesota'},
                                            {value: 'Mississippi', label: 'Mississippi'},
                                            {value: 'Missouri', label: 'Missouri'},
                                            {value: 'Montana', label: 'Montana'},
                                            {value: 'Nebraska', label: 'Nebraska'},
                                            {value: 'Nevada', label: 'Nevada'},
                                            {value: 'New Hampshire', label: 'New Hampshire'},
                                            {value: 'New Jersey', label: 'New Jersey'},
                                            {value: 'New Mexico', label: 'New Mexico'},
                                            {value: 'New York', label: 'New York'},
                                            {value: 'North Carolina', label: 'North Carolina'},
                                            {value: 'North Dakota', label: 'North Dakota'},
                                            {value: 'Ohio', label: 'Ohio'},
                                            {value: 'Oklahoma', label: 'Oklahoma'},
                                            {value: 'Oregon', label: 'Oregon'},
                                            {value: 'Pennsylvania', label: 'Pennsylvania'},
                                            {value: 'Rhode Island', label: 'Rhode Island'},
                                            {value: 'South Carolina', label: 'South Carolina'},
                                            {value: 'South Dakota', label: 'South Dakota'},
                                            {value: 'Tennessee', label: 'Tennessee'},
                                            {value: 'Texas', label: 'Texas'},
                                            {value: 'Utah', label: 'Utah'},
                                            {value: 'Vermont', label: 'Vermont'},
                                            {value: 'Virginia', label: 'Virginia'},
                                            {value: 'Washington', label: 'Washington'},
                                            {value: 'Washington, D.C.', label: 'Washington, D.C.'},
                                            {value: 'West Virginia', label: 'West Virginia'},
                                            {value: 'Wisconsin', label: 'Wisconsin'},
                                            {value: 'Wyoming', label: 'Wyoming'},
                                            {value: 'Alberta', label: 'Alberta'},
                                            {value: 'British Columbia', label: 'British Columbia'},
                                            {value: 'Manitoba', label: 'Manitoba'},
                                            {value: 'New Brunswick', label: 'New Brunswick'},
                                            {value: 'Newfoundland & Labrador', label: 'Newfoundland & Labrador'},
                                            {value: 'Nova Scotia', label: 'Nova Scotia'},
                                            {value: 'Northwest Territories', label: 'Northwest Territories'},
                                            {value: 'Nunavut', label: 'Nunavut'},
                                            {value: 'Ontario', label: 'Ontario'},
                                            {value: 'Prince Edward Island', label: 'Prince Edward Island'},
                                            {value: 'Quebec', label: 'Quebec'},
                                            {value: 'Saskatchewan', label: 'Saskatchewan'},
                                            {value: 'Yukon', label: 'Yukon'},
                                        ]}
                                        required
                                        error={errors?.stateOrProvinceOptions?.message}
                                        name="stateOrProvinceOptions"
                                        inputRef={register({
                                            required:
                                                (formSubject?.value === 'Product Feedback' || formSubject?.value === 'Sample Request') &&
                                                translate(viewBag, 'BushsBeans.Forms.ContactUs.Errors.RequiredSelectorFieldEmpty'),
                                            validate: {
                                                selectOne: value =>
                                                    formSubject?.value === 'Product Feedback' && value === '0'
                                                        ? translate(viewBag, 'BushsBeans.Forms.ContactUs.Errors.RequiredSelectorFieldEmpty')
                                                        : true,
                                            },
                                        })}
                                    />
                                </div>
                            </div>
                        )}
                        {formSubject?.value !== 'Privacy Request' && (
                            <div className="field-row">
                                <div>
                                    <Select
                                        label={translate(viewBag, 'BushsBeans.Forms.ContactUs.Fields.CountryLabel')}
                                        required={formSubject?.value === 'Product Feedback' || formSubject?.value === 'Sample Request'}
                                        placeholder={translate(viewBag, 'BushsBeans.Forms.ContactUs.Fields.CountryPlaceholder')}
                                        options={[
                                            {value: 'United States of America', label: 'United States of America'},
                                            {value: 'Canada', label: 'Canada'}
                                        ]}
                                        name="countryOptions"
                                        inputRef={register({
                                            required: false,
                                        })}
                                    />
                                </div>
                                <div id="usps-note">
                                    <Input
                                        type="text"
                                        required={formSubject?.value !== 'Privacy Request'}
                                        label={translate(viewBag, 'BushsBeans.Forms.ContactUs.Fields.ZipCodeLabel')}
                                        placeholder={translate(viewBag, 'BushsBeans.Forms.ContactUs.Fields.ZipCodePlaceholder')}
                                        name="zip"
                                        error={errors?.zip?.message}
                                        inputRef={register({
                                            required:
                                                formSubject?.value !== 'Privacy Request' &&
                                                translate(viewBag, 'BushsBeans.Forms.ContactUs.Errors.ZipCodeValidation'),
                                            validate: value => {
                                                const validation = Joi.string().pattern(zipCodeRegex);

                                                if (formSubject?.value === 'Privacy Request') {
                                                    return true;
                                                }

                                                return !validation.validate(value).error || translate(viewBag, 'BushsBeans.Forms.ContactUs.Errors.ZipCodeValidation');
                                            },
                                        })}
                                    />
                                </div>
                            </div>
                        )}
                        {formSubject?.value === 'Sample Request' && (
                            <div className="field-row">
                                <div>
                                    <Input
                                        type="text"
                                        required={formSubject?.value === 'Sample Request'}
                                        label="Units"
                                        placeholder="Units"
                                        name="units"
                                        value={formData?.units}
                                        defaultValue={1}
                                        error="Please enter the number of units"
                                        onChange={(event) => setFormData({ ...formData, units: event.target.value })}
                                        inputRef={register({
                                            required: "Please Enter a number",
                                            validate: (value) =>
                                                !isNaN(value) && Number(value) > 0 || "Please Enter a positive number",
                                        })}
                                    />
                                </div>
                                <div>
                                    <Select
                                        label="Select Sample"
                                        placeholder="Select Sample"
                                        options={[
                                            { value: 'Easy Entrees™ Texas Ranchero Pinto Beans', label: 'Easy Entrees™ Texas Ranchero Pinto Beans' },
                                            { value: 'Easy Entrees™ Taco Fiesta Black Beans', label: 'Easy Entrees™ Taco Fiesta Black Beans' },
                                            { value: 'Chili Beans in Mild Sauce', label: 'Chili Beans in Mild Sauce' },
                                            { value: 'Original Baked Beans', label: 'Original Baked Beans' },
                                            { value: 'Bean Pot® Vegetarian Baked Beans', label: 'Bean Pot® Vegetarian Baked Beans' },
                                            { value: 'Bean Pot® Vegetarian Baked Beans Reduced Sodium', label: 'Bean Pot® Vegetarian Baked Beans Reduced Sodium' },
                                            { value: 'Other', label: 'Other' },
                                        ]}
                                        required={formSubject?.value === 'Sample Request'}
                                        error="Select Sample"
                                        name="sample"
                                        onChange={event => {
                                            const sampleValue = getSampleText(Number(event.target.value));
                                            setFormSample({ value: sampleValue });
                                            setFormData({ ...formData, sample: sampleValue });
                                        }}
                                        inputRef={register({
                                            validate: (value) => {
                                                if (formSubject?.value !== 'Sample Request') {
                                                    return true; // Skip validation for other subjects
                                                }
                                                if (!value || value === '') {
                                                    return 'Sample is required';
                                                }
                                                return true; // Valid selection
                                            },
                                        })}
                                    />
                                </div>
                            </div>
                        )}
                        {formSample?.value === 'Other' && formSubject?.value === 'Sample Request' && (
                            <div className="field-row">
                                <div>
                                    <Input
                                        type="text"
                                        required={formData.sample === 'Other'}
                                        label="Other Sample"
                                        placeholder="Desired Product Name"
                                        name="otherSample"
                                        value={formData.otherSample || ''}
                                        onChange={(event) => setFormData({ ...formData, otherSample: event.target.value })}
                                        error="Please enter the desired product name"
                                    />
                                </div>
                            </div>
                        )}
                        {formSubject?.value === 'Sample Request' && (
                            <div className="field-row">
                                <div>
                                    <Select
                                        label="Select Distributor"
                                        placeholder="Select Distributor"
                                        options={[
                                            { value: 'Sysco', label: 'Sysco' },
                                            { value: 'US Foods', label: 'US Foods' },
                                            { value: 'Performance Food Group', label: 'Performance Food Group' },
                                            { value: 'Gordon Foodservice', label: 'Gordon Foodservice' },
                                            { value: 'Restaurant Depot', label: 'Restaurant Depot' },
                                            { value: 'Ben E Keith', label: 'Ben E Keith' },
                                            { value: 'Cheney Brothers', label: 'Cheney Brothers' },
                                            { value: 'Shamrock', label: 'Shamrock' },
                                            { value: 'Goldstar', label: 'Goldstar' },
                                            { value: 'Nicholas', label: 'Nicholas' },
                                            { value: 'Labatt', label: 'Labatt' },
                                            { value: "Don't know", label: "Don't know" },
                                            { value: 'Not Applicable', label: 'Not Applicable' },
                                            { value: 'Other', label: 'Other' },
                                        ]}
                                        required={formSubject?.value === 'Sample Request'}
                                        error="Select Distributor"
                                        onChange={event => {
                                            const distributorValue = getDistributorText(Number(event.target.value));
                                            setFormDistributor({ value: distributorValue });
                                            setFormData({ ...formData, distributor: distributorValue });
                                        }}
                                        inputRef={register({
                                            validate: (value) => {
                                                if (formSubject?.value !== 'Sample Request') {
                                                    return true; // Skip validation for other subjects
                                                }
                                                if (!value || value === '') {
                                                    return 'Distributor is required';
                                                }
                                                return true; // Valid selection
                                            },
                                        })}
                                    />
                                </div>
                                {formDistributor?.value === 'Other' && (
                                    <div className="field">
                                        <Input
                                            type="text"
                                            required={formData.distributor === 'Other'}
                                            label="Other Distributor"
                                            placeholder="Distributor Name"
                                            name="otherDistributor"
                                            value={formData.otherDistributor || ''}
                                            onChange={(event) => setFormData({ ...formData, otherDistributor: event.target.value })}
                                            error="Please provide the distributor name"
                                        />
                                    </div>
                                )}
                            </div>
                        )}
                        {formSubject?.value === 'Sample Request' && (
                            <div className="field-row">
                                <div>
                                    <Select
                                        label="Segment"
                                        placeholder="Select Segment"
                                        options={[
                                            { value: 'K12', label: 'K12' },
                                            { value: 'College & University', label: 'College & University' },
                                            { value: 'Restaurant', label: 'Restaurant' },
                                            { value: 'Other', label: 'Other' },
                                        ]}
                                        required={formSubject?.value === 'Sample Request'}
                                        error="Select Segment"
                                        name="segment"
                                        onChange={event => {
                                            const segmentValue = getSegmentText(Number(event.target.value));
                                            setFormSegment({ value: segmentValue });
                                            setFormData({ ...formData, segment: segmentValue });
                                        }}
                                        inputRef={register({
                                            required: formSubject?.value === 'Sample Request' && 'Segment is required',
                                        })}
                                    />
                                </div>
                                {formSegment?.value === 'Other' && (
                                    <div className="field">
                                        <Input
                                            type="text"
                                            required={formData.segment === 'Other'}
                                            label="Other Segment"
                                            placeholder="Segment Name"
                                            name="otherSegment"
                                            value={formData.otherSegment || ''}
                                            onChange={(event) => setFormData({ ...formData, otherSegment: event.target.value })}
                                            error="Please enter the segment name"
                                        />
                                    </div>
                                )}
                            </div>
                        )}
                        <div className="contact-form-divider"/>
                        <div className="field-row-textarea">
                            <div>
                                <Textarea
                                    rows="10"
                                    className="textarea"
                                    label={translate(viewBag, 'BushsBeans.Forms.ContactUs.Fields.YourMessageLabel')}
                                    maxLength={1500}
                                    required
                                    name="yourMessage"
                                    helpText="(be as descriptive as possible in your request)"
                                    error={
                                        errors?.yourMessage?.type === 'maxLength'
                                            ? translate(viewBag, 'BushsBeans.Forms.ContactUs.Errors.YourMessageCharacterLimitValidation')
                                            : errors?.yourMessage?.type === 'minLength'
                                                ? 'Message is too short'
                                                : errors?.yourMessage?.message
                                    }
                                    inputRef={register({
                                        minLength: 1,
                                        maxLength: 1500,
                                        required: translate(viewBag, 'BushsBeans.Forms.ContactUs.Errors.YourMessageEmptyValidation'),
                                    })}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="form-footer">
                        <div className="submit-button-container">
                            <Button
                                text={translate(viewBag, 'BushsBeans.Forms.ContactUs.Buttons.ClearAll', 'Clear All')}
                                className="clear-button blue-text"
                                noIcon
                                onClick={handleReset}
                            />
                            <Button
                                text={translate(viewBag, 'BushsBeans.Forms.ContactUs.Buttons.Submit', 'Submit')}
                                type="submit"
                                variant="blue"
                                noIcon
                            />
                        </div>
                        <RichText tag="div" field={fields?.privacyPolicyText}/>
                    </div>
                </FormContent>
            </form>
        )}
      </ContactRepFormComponent>
  );
};

ContactRepForm.propTypes = {
    fields: PropTypes.object,
    sitecoreContext: PropTypes.object,
    rendering: PropTypes.object,
};

export default withSitecoreContext()(ContactRepForm);