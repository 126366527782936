import styled from "styled-components";
const getVariantColours = ({ variant, theme } = {}) => {
    const variants = {
        red: {
            background: theme.colours.red,
            text: '#fff',
        },
        yellow: {
            background: theme.colours.yellow,
            text: theme.colours.brown,
        },
        blue: {
            background: theme.colours.blue,
            text: theme.colours.yellow,
        },
        tan: {
            background: theme.colours.tan,
            text: theme.colours.brown,
        },
    };

    if (variant in variants) return variants[variant];

    return variants.tan;
};

export const VideoTimelineWrapper = styled.section`
	position: relative;
	width: calc(100% - 30px);
	background-color: ${({ theme, backgroundColor }) =>
		getVariantColours({ variant: backgroundColor, theme }).background};
	padding: ${({ theme }) => theme.spacing({ top: 120, bottom: 100, left: 15, right: 15 })};
	@media (max-width: 900px) {
		padding: ${({ theme }) => theme.spacing({ top: 60, bottom: 50, left: 15, right: 15 })};
	}
	.so-darn-good-circle-plate{
		position: absolute;
		right: 2%;
		top: -100px;
		width: 200px;
		height: auto;
		z-index: 2;
        @media (max-width: 1200px){
            display: none;
        }
	}
	.container{
		width: 1550px;
		max-width: 90%;
		display: block;
		margin: auto;
	}
	h2{
		color:  ${({ theme }) => theme.colours.red};
		text-align: center;
		font-size: 69px;
		line-height: 1;
		@media (max-width: 1400px){
			font-size: 55px;
		}
		@media (max-width: 1140px){
			font-size: 45px;
		}
		@media (max-width: 960px){
			font-size: 38px;
		}
		@media (max-width: 900px){
			font-size: 32px;
			text-align: center;
		}
	}
	p{
		text-align: center;
		margin-bottom: 0;
		color:  ${({ theme }) => theme.colours.brown};
		@media (max-width: 900px){
			text-align: center;
		}
	}
`;

export const VideoHolder = styled.div`
	display: block;
	max-width: 100%;
	width: 958px;
	margin: 40px auto;
	.video-div{
		background-color: rgb(0, 0, 0);
		position: relative;
		width: 100%;
		padding-top: 56.2%;
		border-radius: 56px;
		background-size: cover;
		background-position: center center;
		overflow: hidden;
		cursor: pointer;
		.play-video-button {
			width: 100px;
			height: 100px;
			min-width: 100px;
			min-height: 100px;
			margin: auto;
			position: absolute;
			border: 0;
			background: none;
			left: 0;
			right: 0;
			top: 0;
			bottom: 0;
            cursor: pointer;
			.shadow {
				width: 100px;
				height: 100px;
				min-width: 100px;
				min-height: 100px;
				border-radius: 50%;
				content: "";
				background-color: #000;
				position: absolute;
				left: -10px;
				top: 0;
				bottom: 0;
				z-index: 0;
			}
			.self {
				background-color:  ${({theme}) => theme.colours.red};
				text-align: center;
				border-radius: 50%;
				display: flex;
				justify-content: center;
				align-items: center;
				content: "";
				width: 100px;
				height: 100px;
				min-width: 100px;
				min-height: 100px;
				align-self: center;
				color: #fff;
				position: absolute;
				top: 0;
				bottom: 0;
				left: 0;
				right: 0;
				margin: auto;
				animation-timing-function: cubic-bezier(.3, .7, .4, 1.5);
				transition: transform 250ms cubic-bezier(.3, .7, .4, 1.5);
                border: 1px solid #000;
				svg {
					width: 31px;
					height: 38px;
					fill: #fff;
				}
				&:hover {
					animation: bounce .4s ease-in-out;
					transform: translate(-10px, 0);
					background-color: #0c54a2;
				}
			}
			@media (max-width: 650px){
				width: 50px;
				height: 50px;
				min-width: 50px;
				min-height: 50px;
				.shadow{
					width: 50px;
					height: 50px;
					min-width: 50px;
					min-height: 50px;
				}
				.self{
					width: 50px;
					height: 50px;
					min-width: 50px;
					min-height: 50px;
					svg{
						width: 20px;
						height: 20px;
					}
				}
			}
		}
		iframe {
			position: absolute;
			left: 0;
			right: 0;
			bottom: 0;
			top: 0;
			width: 100%;
			height: 100%;
		}
		@media (max-width: 900px) {
			border-radius: 25px;
		}
	}
`;

export const VideoButtonWrapper = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
    margin: auto;
    width: 950px;
    max-width: 100%;
    border-radius: 30px;
    padding: 40px;
    gap: 40px;
    justify-content: center;
	background: #FFF8EB;
    flex-wrap: wrap;
    button{
		background-color: transparent;
        border: 0;
        outline: none;
		padding: 0;
		text-align: center;
		text-decoration: none;
		display: inline-block;
		font-size: 32px;
        color: #fff;
		margin: 4px 2px;
		cursor: pointer;;
		height: 100px;
		width: 100px;
        position: relative;
        transition: all 0.3s ease-in-out;
		.shadow {
			width: 100px;
			height: 100px;
			min-width: 100px;
			min-height: 100px;
			border-radius: 50%;
			content: "";
			background-color: #000;
			position: absolute;
			left: -10px;
			top: 0;
			bottom: 0;
			z-index: 0;
		}
		.self {
			background-color:  ${({theme}) => theme.colours.red};
			text-align: center;
			border-radius: 50%;
			display: flex;
			justify-content: center;
			align-items: center;
			content: "";
			width: 100px;
			height: 100px;
			min-width: 100px;
			min-height: 100px;
			align-self: center;
			color: #fff;
			position: absolute;
			top: 0;
			bottom: 0;
			left: 0;
			right: 0;
			margin: auto;
			animation-timing-function: cubic-bezier(.3, .7, .4, 1.5);
			transition: transform 250ms cubic-bezier(.3, .7, .4, 1.5), background-color 250ms ease-in-out;
            overflow: hidden;
            border: 1px solid #000;
            span{
                z-index: 5;
                align-self: center;
                justify-self: center;
            }
            &:before{
                content: "";
                position: absolute;
                left: 0;
                right: 0;
                top: 0;
                bottom: 0;
				background: linear-gradient(94deg, rgba(255,255,255,0.1) 55%, rgba(190,99,54,0) 100%);
            }
			&:hover {
				animation: bounce .4s ease-in-out;
				transform: translate(-10px, 0);
			}
		}
		@media (max-width: 650px){
			width: 75px;
			height: 75px;
			min-width: 75px;
			min-height: 75px;
            font-size: 25px;
			.shadow{
				width: 75px;
				height: 75px;
				min-width: 75px;
				min-height: 75px;
			}
			.self{
				width: 75px;
				height: 75px;
				min-width: 75px;
				min-height: 75px;
			}
		}
    }
	button:nth-child(1),
	button:nth-child(5) {
        .self{
			background-color: ${({ theme }) => theme.colours.red};
			&:before{
				content: "";
				position: absolute;
				left: 0;
				right: 0;
				top: 0;
				bottom: 0;
				background: linear-gradient(94deg, rgba(239,44,38,0.1) 55%, rgba(212, 54, 49,0) 100%);
			}
            &:hover{
                background-color: #b4201c;
            }
        }
	}

	button:nth-child(2),
	button:nth-child(6) {
        .self{
			background-color: ${({ theme }) => theme.colours.blue};
			&:before{
				content: "";
				position: absolute;
				left: 0;
				right: 0;
				top: 0;
				bottom: 0;
				background: linear-gradient(94deg, rgba(17,127,242,0.1) 55%, rgba(20, 113, 210,0) 100%);
			}
            &:hover{
                background-color: #0b539e;
            }
        }
	}

	button:nth-child(3) {
        .self{
			background-color: ${({ theme }) => theme.colours.yellow};
			color: ${({ theme }) => theme.colours.brown};
			&:before{
				content: "";
				position: absolute;
				left: 0;
				right: 0;
				top: 0;
				bottom: 0;
				background: linear-gradient(94deg, rgba(255,201,19,0.1) 55%, rgba(255, 202, 46,0) 100%);
			}
            &:hover{
                background-color: #e7b420;
            }
        }
	}

	button:nth-child(4) {
        .self{
			background-color: ${({ theme }) => theme.colours.brown};
			&:before{
				content: "";
				position: absolute;
				left: 0;
				right: 0;
				top: 0;
				bottom: 0;
				background: linear-gradient(94deg, rgba(189, 84, 4,0.1) 55%, rgba(148, 64, 0,0) 100%);
			}
            &:hover{
                background-color: #783603;
            }
        }
	}
	@media (max-width: 980px) {
		width: 80%;
	}
`;