import React from 'react';
import PropTypes from 'prop-types';
import { isServer } from '@sitecore-jss/sitecore-jss';
import {isExperienceEditorActive, withSitecoreContext} from '@sitecore-jss/sitecore-jss-react';
import { ProductGridComponent } from './ProductGrid.styles.js';
import Listing from 'components/shared/Listing';
import ListingFS from 'components/shared/ListingFs';
import Button from "../shared/Button";
import { trademarkFilterHelper } from 'helpers/trademarkFilterHelper';

const ProductGrid = ({ fields = {}, params = {}, sitecoreContext = {}  }) => {
    const { showFilters = false, pageSize = {} } = fields;
    const experienceEditor = isExperienceEditorActive();
    const { site, trademarkReplacements = [] } = sitecoreContext;
    const isBushsBeansFoodservices = site?.name === 'bushs-beans-foodservices';
    const pageSizeValue =
        (pageSize?.value && !isNaN(parseInt(pageSize.value)) && parseInt(pageSize.value)) || 10;

    const prefilterProductType =
        (fields?.prefilterProductType?.fields?.key?.value && [
            fields?.prefilterProductType?.fields?.key?.value,
        ]) ||
        [];

    const prefilter = { prefilterProductType };
    const {
        gridHeadline = '',
        gridButtonUrl = '',
        gridButtonText = ''
    } = params;
    if (isExperienceEditorActive()) {
        return (
            <div style={{ minHeight: '150px' }} >
                <h1>PRODUCT GRID PLACEHOLDER</h1>
            </div>
        );
    }
    if (isBushsBeansFoodservices){
        return (
            <ProductGridComponent>
                {gridHeadline && (
                    <div className="grid-top-headline">
                        <h2 dangerouslySetInnerHTML={{ __html: trademarkFilterHelper(gridHeadline, trademarkReplacements) }}></h2>
                        {gridButtonUrl && gridButtonText && (
                            <div>
                                <Button text={gridButtonText} href={gridButtonUrl} variant="red" />
                            </div>
                        )}
                    </div>
                )}
                {!isServer() && (
                    <ListingFS
                        type="product"
                        site={site.name}
                        prefilter={prefilter}
                        showFilters="true"
                        pageSize={pageSizeValue}
                    />
                )}
            </ProductGridComponent>
        );
    } else {
        return (
            <ProductGridComponent>
                {gridHeadline && (
                <div className="grid-top-headline">
                    <h2 dangerouslySetInnerHTML={{ __html: trademarkFilterHelper(gridHeadline, trademarkReplacements) }}></h2>
                    {gridButtonUrl && gridButtonText && (
                        <div>
                            <Button text={gridButtonText} href={gridButtonUrl} variant="red" />
                        </div>
                    )}
                </div>
                )}
                {!isServer() && (
                    <Listing
                        type="product"
                        site={site.name}
                        prefilter={prefilter}
                        showFilters={!!showFilters?.value}
                        pageSize={pageSizeValue}
                    />
                )}
            </ProductGridComponent>
        );
    }
};

ProductGrid.propTypes = {
    fields: PropTypes.object,
    params: PropTypes.object,
};

export default withSitecoreContext()(ProductGrid);