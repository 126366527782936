import styled from 'styled-components';

const getVariantColours = ({variant, theme} = {}) => {
    const variants = {
        red: {
            background: theme.colours.red,
        },
        yellow: {
            background: theme.colours.yellow,
        },
        blue: {
            background: theme.colours.blue,
        },
        brown: {
            background: theme.colours.brown,
        },
        tan: {
            background: theme.colours.tan,
        },
    };

    if (variant in variants) return variants[variant];

    return variants.red;
};

export const SideBySideRevealImageComponent = styled.section`
    position: relative;
	background-color: ${({ theme, variant, backgroundColor }) =>
		theme.colours.checkColourExists(backgroundColor) || getVariantColours({ theme, variant }).background};
	${({ theme }) => theme.breakPoints.minBp('desktop')} {
		padding: ${({ theme }) => `clamp(${theme.spacing(50)}, 5vw, ${theme.spacing(100)});`};
	}

	${({ theme }) => theme.breakPoints.minBp('1920px')} {
		padding: ${({ theme }) => `clamp(${theme.spacing(50)}, 6vw, ${theme.spacing(100)});`};
	}
	.so-darn-good-bb-logo-svg{
		width: 200px;
		position: absolute;
		right: -100px;
		top: -90px;
		transform: rotate(15deg);
        @media (max-width: 1550px){
			transform: rotate(15deg) scale(0.8);
            right: -60px
        }
		@media (max-width: 1200px){
			display: none;
		}
	}
	.fontApercu {
		.promoboxWrap {
			.promo-box {
				.h1 {
					font-family: "apercu condensed pro", serif !important;
				}
				h2 {
					font-family: "apercu condensed pro", serif !important;
				}
			}
		}
	}
	.container{
         display: flex;
        width: 1450px;
        max-width: 100%;
        margin: auto;
  flex-direction: column-reverse;
  ${({ backgroundImage }) =>
    backgroundImage?.src
        ? `background-image: url(${backgroundImage.src}); 
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    `
        : ''}
  .promoboxWrap {
	  overflow: visible;
	  .promo-box {
		  padding: 80px 40px;
		  .h1 {
			  font-family: ${({ headingFont }) => (headingFont === 'chunk' ? 'chunk' : 'inherit')};
		  }
		  h2 {
			  font-family: ${({ headingFont }) => (headingFont === 'chunk' ? 'chunk' : 'inherit')};
		  }
		  p {
			  font-size: 18px;
			  color: #fff;
		  }
	  }
  }
        
  ${({ theme }) => theme.breakPoints.minBp('tablet')} {
    flex-direction: row;

    ${({ alignment }) => `
      ${alignment === 'right' ? 'flex-direction: row-reverse;' : ''}
    `}
  }

  > div {
    ${({ theme }) => theme.breakPoints.minBp('tablet')} {
      flex: 1;

      ${({ alignment }) => `
      ${
    alignment === 'center'
        ? `width: 50%;
      display: block;
      flex: none;
      margin: 0 auto;`
        : ''
}
    `}
    }
  }
  &.hero-bg-lg{
    margin-bottom: 0;
    @media (max-width: 1100px){
      flex-direction: column;
    }
    .cont-img-block{
      display: none;
    }
    @media (max-width: 1100px){
      .cont-img-block{
        display: flex;
        order: 0;
      }
    }
    .promoboxWrap{
      order: 1;
    }
    .promo-box{
      background: ${({ theme }) => theme.colours.red};
      height: fit-content;
      width: 100%;
      margin: 0;
      text-align: center;
      padding: 35px;
      h1{
        margin-bottom: 20px;
        display: block;
        font-size: 35px;
        line-height: 1.2;
      }
      .h1{
        margin-bottom: 20px;
        text-align: left;
        display: block;
        font-size: 35px;
        line-height: 1.2;
      }
      p{
        margin-bottom: 18px;
        display: block;
        width: 100%;
        text-align: center;
        display: block;
      } text-align: center;
        display: block;
      }
      a{
        margin: 0 auto;
      }
      @media (max-width: 1100px){
        .h1{
            text-align: center;
            font-size: 26px;
        }
        h1{
          text-align: center;
          font-size: 26px;
        }
      }
      @media (min-width: 1100px){
          width: 500px;
          margin: 100px 15px;
          display: block;
          flex-direction: column;
          justify-content: center;
          box-sizing: border-box;
          height: auto;
        text-align: left;
        h1{
          text-align: left;
        }
        p{
          text-align: left;
        }
        .p{
          text-align: left;
        }
        a{
          margin: 0 ;
        }
      }
    }
  }
    }
`;

export const ImgReveal = styled.div`
	position: relative;
	overflow: hidden;
	display: flex;
	justify-content: flex-end;
	aspect-ratio: 1 / 1;
	img {
		position: relative;
		display: block;
		width: 100%;
		height: 100%;
		object-fit: cover;
	}
	.slider-container {
		position: relative;
		width: 100%;
		height: 100%;
		overflow: hidden;
	}

	.before-image, .after-image {
		position: absolute;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
	}
    
	.slider {
		position: absolute;
		top: 0;
        left: 0;
		right: 0;
		width: 50%; 
		height: 100%;
		background-color: ${({ theme }) => theme.colours.yellow};
		background-size: 50%;
		cursor: ew-resize;
		border: 10px solid rgb(255, 255, 255);
        height: 97.5%;
        justify-self: end;
		background-attachment: fixed;
		.handle {
			position: absolute;
			left: 0;
			top: 50%;
			width: 40px;
			height: 40px;
			border-radius: 100%;
			background: ${({ theme }) => theme.colours.red};
			text-align: center;
			display: flex;
			justify-content: center;
			align-content: center;
			color: ${({ theme }) => theme.colours.yellow};
			font-size: 29px;
			transform: translate(-26px, 0px);
            span{
				align-self: center;
				justify-self: center;
			}
		}
	}
	.image-comparison {
		max-width: 100%;
		margin-right: auto;
		margin-left: auto;
        height: 100%;
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
	}

	.image-comparison__slider-wrapper {
		position: relative;
        height: 100%;
	}

	.image-comparison__label {
		font-size: 0;
		line-height: 0;
	}

	.image-comparison__label,
	.image-comparison__range {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		margin: 0;
		padding: 0;
		background-color: transparent;
		border: none;
		-webkit-appearance: none;
		-moz-appearance: none;
		appearance: none;
		outline: none;
		cursor: ew-resize;
		z-index: 20;
	}

	@media (hover) {
		.image-comparison__range:hover ~ .image-comparison__slider .image-comparison__thumb {
			transform: scale(1.2);
		}
	}
	.image-comparison .image-comparison__slider-wrapper .image-comparison__range:active ~ .image-comparison__slider .image-comparison__thumb,
	.image-comparison .image-comparison__slider-wrapper .image-comparison__range:focus ~ .image-comparison__slider .image-comparison__thumb,
	.image-comparison .image-comparison__slider-wrapper .image-comparison__range--active ~ .image-comparison__slider .image-comparison__thumb {
		transform: scale(0.8);
		background-color: rgba(0, 97, 127, 0.5);
	}
    .image-comparison__image-wrapper{
        height: 100%;
    }
	.image-comparison__image-wrapper--overlay {
		position: absolute;
		top: 0;
		left: 0;
		width: calc(50% + 1px);
		height: 100%;
		overflow: hidden;
	}

	.image-comparison__figure {
		margin: 0;
	}
	.image-comparison__figure::before {
		content: "";
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-color: #f2f2f2;
	}
	.image-comparison__figure:not(.image-comparison__figure--overlay) {
		height: 98.8%;
		border: 5px solid #fff;
		top: 0;
		bottom: 0;
		position: absolute;
		left: 0;
		right: 0;
	}

	.image-comparison__image {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		-o-object-fit: cover;
		object-fit: cover;
		-o-object-position: 0 50%;
		object-position: 0 50%;
		overflow: hidden;
        background-color: ${({ theme }) => theme.colours.yellow};
	}
	.image-comparison__figure--overlay .image-comparison__image {
		z-index: 1;
	}


	.image-comparison__slider {
		position: absolute;
		top: 0;
		left: 50%;
		width: 5px;
		height: 100%;
		background-color: #fff;
		transition: background-color 0.3s ease-in-out;
		z-index: 10;
	}
	.image-comparison__range--active ~ .image-comparison__slider {
		background-color: rgba(255, 255, 255, 0);
	}
	.image-comparison__thumb {
		position: absolute;
		top: calc(50% - 20px);
		left: calc(50% - 20px);
		width: 40px;
		height: 40px;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		background-color: ${({ theme }) => theme.colours.red};
		color: #fff;
		border-radius: 50%;
		box-shadow: 0 0 22px 0 rgba(0, 0, 0, 0.5);
		transform-origin: center;
		transition: transform 0.3s ease-in-out, background-color 0.3s ease-in-out;
	}

	.image-comparison__range::-webkit-slider-runnable-track {
		width: 40px;
		height: 40px;
		opacity: 0;
	}

	.image-comparison__range::-moz-range-thumb {
		width: 40px;
		height: 40px;
		opacity: 0;
	}

	.image-comparison__range::-webkit-slider-thumb {
		width: 40px;
		height: 40px;
		opacity: 0;
	}

	.image-comparison__range::-ms-fill-lower {
		background-color: transparent;
	}

	.image-comparison__range::-ms-track {
		position: relative;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		border: none;
		margin: 0;
		padding: 0;
		background-color: transparent;
		color: transparent;
		outline: none;
		cursor: col-resize;
	}

	.image-comparison__range::-ms-thumb {
		width: 0.5%;
		height: 100%;
		opacity: 0;
	}

	.image-comparison__range::-ms-tooltip {
		display: none;
	}
`;

export const Block = styled.div`
	overflow: hidden;
	> img {
		position: relative;
		display: block;
		width: 100%;
		height: 100%;
		object-fit: cover;
	}
	.promo-box {
		${({ theme }) => theme.breakPoints.minBp('tablet')} {
			display: flex;
			flex-direction: column;
			justify-content: center;
			box-sizing: border-box;
			height: 100%;
		}
		p{
			width: 100%;
			margin-bottom: 30px;
		}
	}
`;