import styled from 'styled-components';

export const FindNearYouWrapper = styled.section`
    display: block;
    position: relative;
    width: 100%;
    background: ${({ theme }) => theme.colours.tan};
    .heading-container{
        max-width: 90%;
        margin: auto;
    }
    .fny-grid-wrapper{
        width: 1000px;
        max-width: 90%;
        margin: auto;
        .fny-grid{
			display: grid;
			grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
			gap: 45px;
			justify-content: center;
			max-width: 100%;
			margin: 0 auto;
			padding: 20px 0;
			width: 100%;
            .fny-grid-item{
				display: flex;
				flex-flow: column;
                cursor: pointer;
                gap: 30px;
                .img-wrapper{
					background-color: ${({ theme }) => theme.colours.yellow};
					padding: 20px;
					text-align: center;
					font-size: 1.2rem;
					box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.1);
					img{
						width: 200px;
                        max-width: 100%;
					}
                }
                .text-wrapper{
                    font-size: 1.2rem;
                    text-transform: uppercase;
                    display: block;
                    margin: 0 auto;
                    text-align: center;
                    p{
                        color: ${({ theme }) => theme.colours.brown};
                        line-height: 1.3;
                        margin-bottom: 0;
						font-size: 20px;
						font-weight: 700;
                    }
                    a{
						color: ${({ theme }) => theme.colours.brown};
                        font-weight: 700;
                        transition: all 0.4s ease-in-out;
                        text-decoration: none;
						line-height: 1.3;
						margin-bottom: 0;
						font-size: 20px;
                        &:hover{
                            color: ${({ theme }) => theme.colours.blue};
                        }
                    }
                }
            }
        }
    }
	@media (max-width: 800px) {
		.fny-grid-wrapper{
			.fny-grid{
				grid-template-columns: repeat(2, 1fr);
			}
		}
	}

	@media (max-width: 400px) {
		.fny-grid-wrapper{
			.fny-grid{
				grid-template-columns: repeat(1, 1fr);
			}
		}
	}
`;