import styled from 'styled-components';

export const NotFoundWrapper = styled.div`
      .hidden{
          display: none;
      }
      .search-on-p{
          max-width: 1200px;
          margin: auto;
		  .input-container input{
              font-size: 1.3rem;
          }
      }
      .inner{
		  padding-top: 50px;
		  h2{
			  color: ${({ theme }) => theme.colours.red};
			  margin: 30px auto 10px auto;
			  line-height: 1.2;
			  font-size: 55px;
			  font-family: apercu pro, 'apercu pro', impact, sans-serif;
			  @media (max-width: 1250px){
				  font-size: 38px;
			  }
		  }
		  h3{
			  color: ${({ theme }) => theme.colours.red};
			  font-size: 27px;
			  font-family: apercu pro, 'apercu pro', impact, sans-serif;
			  @media (max-width: 1250px){
				  font-size: 20px;
			  }
		  }
		  h2, h3, p{
			  &.blue{
				  color: ${({ theme }) => theme.colours.blue};
			  }
			  &.red{
				  color: ${({ theme }) => theme.colours.red};
			  }
			  &.yellow{
				  color: ${({ theme }) => theme.colours.yellow};
			  }
			  &.brown{
				  color: ${({ theme }) => theme.colours.brown};
			  }
		  }   
      }
	  .buttonUl {
		  width: 1400px;
		  max-width: 98%;
		  display: flex;
		  justify-content: center;
		  margin: 30px auto;
		  flex-wrap: wrap;

		  li {
			  flex-basis: 0;
			  flex-grow: 1;
			  width: 50%;
              min-width: 280px;
			  a {
				  background-color: ${({ theme }) => theme.colours.yellow};
				  color: ${({ theme }) => theme.colours.brown};
				  padding: 17px 0 15px 0;
				  margin: 20px;
				  font-size: 20px;
				  line-height: 1;
				  border-radius: 25px;
				  font-weight: 700;
				  font-family: apercu condensed pro, 'apercu pro', impact, sans-serif;
				  transition: all 0.4s ease-in-out;
				  display: block;
				  text-align: center;
                  text-transform: uppercase;
				  &:hover {
					  background-color: ${({ theme }) => theme.colours.brown};
					  color: ${({ theme }) => theme.colours.yellow};
				  }
			  }
		  }
	  }
`;

export const NotFoundWrapperFs = styled.div`
      .hidden{
          display: none;
      }
      .search-on-p{
          max-width: 1200px;
          margin: auto;
		  .input-container input{
              font-size: 1.3rem;
          }
      }
      .inner{
		  padding-top: 50px;
		  padding-bottom: 100px;
		  h2{
			  color: ${({ theme }) => theme.colours.red};
			  margin: 30px auto 10px auto;
			  line-height: 1.2;
			  font-size: 72px;
			  font-style: normal;
			  font-weight: 700;
			  font-family: 'apercu condensed pro', 'impact', sans-serif;
			  @media (max-width: 1250px){
				  font-size: 38px;
			  }
		  }
		  h3{
			  color: ${({ theme }) => theme.colours.red};
			  font-size: 27px;
			  font-family: apercu pro, 'apercu pro', impact, sans-serif;
			  @media (max-width: 1250px){
				  font-size: 20px;
			  }
		  }
		  h2, h3, p{
			  &.blue{
				  color: ${({ theme }) => theme.colours.blue};
			  }
			  &.red{
				  color: ${({ theme }) => theme.colours.red};
			  }
			  &.yellow{
				  color: ${({ theme }) => theme.colours.yellow};
			  }
			  &.brown{
				  color: ${({ theme }) => theme.colours.brown};
			  }
		  }   
      }
	  .buttonUl {
		  width: 1400px;
		  max-width: 98%;
		  display: flex;
		  justify-content: center;
		  margin: 30px auto;
		  flex-wrap: wrap;

		  li {
			  flex-basis: 0;
			  flex-grow: 0;
			  width: 50%;
              min-width: 280px;
			  a {
				  color: ${({ theme }) => theme.colours.brown};
				  padding: 17px 0 15px 0;
				  margin: 0;
				  font-size: 20px;
				  line-height: 1;
				  border-radius: 25px;
				  font-weight: 700;
				  font-family: apercu condensed pro, 'apercu pro', impact, sans-serif;
				  transition: all 0.4s ease-in-out;
                  text-transform: uppercase;
				  text-align: center;
				  align-content: center;
				  justify-content: center;
				  display: flex;
				  background: transparent;
				  button{
					  white-space: nowrap;
				  }
			  }
		  }
	  }
`;

export const Container = styled.div`
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-color: ${({ theme }) => theme.colours.tan};

  > div {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: ${({ theme }) => `${theme.spacing(70)} ${theme.spacing(30)} 0 `};
  }

  h1 {
    margin: 0;
    color: ${({ theme }) => theme.colours.red};
    font-size: ${({ theme }) => theme.spacing(90)};
    font-family: ${({ theme }) => theme.typography.fontFamily.heading};
    font-family: "apercu pro", apercu pro, sans-serif;
    font-weight: 900;
    line-height: 1;
  }
  h3{
    line-height: 2;
  }
  p {
    margin-bottom: ${({ theme }) => theme.spacing(30)};
    color: ${({ theme }) => theme.colours.adaBrown};
    font-family: ${({ theme }) => theme.typography.fontFamily.main};
  }

  a {
    text-decoration: none;
  }

  img {
    max-width: 100%;
    margin-top: ${({ theme }) => theme.spacing(20)};

    ${({ theme }) => theme.breakPoints.minBp('desktop')} {
      margin-top: ${({ theme }) => theme.spacing(65)};
    }
  }
`;