import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {Image} from '@sitecore-jss/sitecore-jss-react';

import {SideBySideRevealImageComponent, ImgReveal} from './SideBySideRevealImage.styles.js';
import {RichText} from "../shared/JssOverrides/index.js";
import {isServer} from "@sitecore-jss/sitecore-jss";
import Button from "../shared/Button/index.js";
import {underlineText} from "../../helpers/stringHelpers.js";
import {Block} from "../SideBySide/SideBySide.styles.js";
import PromoBox from "../shared/PromoBox";
import HandleSvg from "./InlineSvg/HandleSvg.jsx";
import SoDarnGoodBBLogoSvg from "../Text/SubTemplates/InlineSvg/SoDarnGoodBBLogoSvg.jsx";

const SideBySideRevealImage = (props) => {
    const {fields = {}, params = {}} = props;
    const {
        title = '',
        copy = '',
        foregroundMedia = null,
        revealMedia = null,
        backgroundMedia = null,
        cta = {}
    } = fields;
    const {
        alignment = 'left',
        contentPanelBackgroundColor = 'red',
        backgroundColor = null,
        contentPanelTitleColor = null,
        contentPanelCopyColor = null,
        contentPanelCtaColor = null,
        headingFont = 'chunk',
        id = '',
        contentClass = '',
    } = params;
    const backgroundImageTitle = backgroundMedia?.value?.title || '';

    const ctaVisible = !!(cta?.hasAction || cta?.value?.href?.length);
    const headingTag = backgroundImageTitle === 'hero' ? 'h1' : 'h2';
    const contentBlock = {
        title,
        copy,
        cta,
        backgroundColor: contentPanelBackgroundColor,
        titleColor: contentPanelTitleColor,
        copyColor: contentPanelCopyColor,
        ctaColor: contentPanelCtaColor,
        cssClass: backgroundImageTitle === 'hero' ? 'hero-bg-lg' : '',
    };

    useEffect(() => {
        if (!isServer()) {
            const imageComparisonSlider = document.querySelector('[data-component="image-comparison-slider"]');
            if (imageComparisonSlider) {
                init(imageComparisonSlider);
            }
        }
    }, []);

    function setSliderState(e, element) {
        const sliderRange = element.querySelector('[data-image-comparison-range]');

        if (e.type === 'input') {
            sliderRange.classList.add('image-comparison__range--active');
            return;
        }

        sliderRange.classList.remove('image-comparison__range--active');
        element.removeEventListener('mousemove', moveSliderThumb);
    }

    function moveSliderThumb(e) {
        const sliderRange = document.querySelector('[data-image-comparison-range]');
        const thumb = document.querySelector('[data-image-comparison-thumb]');
        let position = e.layerY - 20;

        if (e.layerY <= sliderRange.offsetTop) {
            position = -20;
        }

        if (e.layerY >= sliderRange.offsetHeight) {
            position = sliderRange.offsetHeight - 20;
        }

        thumb.style.top = `${position}px`;
    }

    function moveSliderRange(e, element) {
        const value = e.target.value;
        const slider = element.querySelector('[data-image-comparison-slider]');
        const imageWrapperOverlay = element.querySelector('[data-image-comparison-overlay]');

        slider.style.left = `${value}%`;
        imageWrapperOverlay.style.width = `${value}%`;

        element.addEventListener('mousemove', moveSliderThumb);
        setSliderState(e, element);
    }

    function init(element) {
        const sliderRange = element.querySelector('[data-image-comparison-range]');

        if ('ontouchstart' in window === false) {
            sliderRange.addEventListener('mouseup', e => setSliderState(e, element));
            sliderRange.addEventListener('mousedown', moveSliderThumb);
        }

        sliderRange.addEventListener('input', e => moveSliderRange(e, element));
        sliderRange.addEventListener('change', e => moveSliderRange(e, element));
    }
    const containerClassName = `container${contentClass ? ` ${contentClass}` : ''}`;
    return (
        <SideBySideRevealImageComponent
            backgroundColor={backgroundColor}
            className="side-by-side-reveal-image"
            alignment={alignment}
            variant={contentPanelBackgroundColor}
            headingFont={headingFont}

        >
            <div className={containerClassName} id={id}>
                <Block className="promoboxWrap">
                    {id === 'reveal-sweep-end' && <SoDarnGoodBBLogoSvg  />}
                    <PromoBox {...contentBlock} variant={contentPanelBackgroundColor} className="promo-box"/>
                </Block>
                <ImgReveal className="cont-img-block">
                    <div className="image-comparison" data-component="image-comparison-slider">
                        <div className="image-comparison__slider-wrapper">
                            <label htmlFor="image-compare-range" className="image-comparison__label">
                                Move image comparison slider
                            </label>
                            <input
                                type="range"
                                min="0"
                                max="100"
                                defaultValue="50"
                                className="image-comparison__range"
                                id="image-compare-range"
                                data-image-comparison-range=""
                            />
                            <div className="image-comparison__image-wrapper image-comparison__image-wrapper--overlay" data-image-comparison-overlay="">
                                <figure className="image-comparison__figure image-comparison__figure--overlay">
                                    <picture className="image-comparison__picture">
                                        <img src={backgroundMedia?.value?.src} className="image-comparison__image" alt=""/>
                                    </picture>
                                </figure>
                            </div>
                            <div className="image-comparison__slider" data-image-comparison-slider="">
                            <span className="image-comparison__thumb" data-image-comparison-thumb="">
                                <HandleSvg/>
                            </span>
                            </div>
                            <div className="image-comparison__image-wrapper">
                                <figure className="image-comparison__figure">
                                    <picture className="image-comparison__picture">
                                        <img src={revealMedia?.value?.src} className="image-comparison__image" alt=""/>
                                    </picture>
                                </figure>
                            </div>
                        </div>
                    </div>
                </ImgReveal>
            </div>
        </SideBySideRevealImageComponent>
    );
};

SideBySideRevealImage.propTypes = {
    fields: PropTypes.shape({
        title: PropTypes.string,
        copy: PropTypes.string,
        backgroundMedia: PropTypes.shape({
            value: PropTypes.shape({
                src: PropTypes.string,
                alt: PropTypes.string,
            }),
        }),
        revealMedia: PropTypes.shape({
            value: PropTypes.shape({
                src: PropTypes.string,
                alt: PropTypes.string,
            }),
        }),
        cta: PropTypes.shape({
            hasAction: PropTypes.bool,
            value: PropTypes.shape({
                href: PropTypes.string,
                text: PropTypes.string,
            }),
        }),
    }),
    params: PropTypes.shape({
        alignment: PropTypes.oneOf(['left', 'center', 'right']),
        contentPanelBackgroundColor: PropTypes.oneOf(['red', 'yellow', 'blue', 'transparent']),
        backgroundColor: PropTypes.string,
        contentPanelTitleColor: PropTypes.string,
        contentPanelCopyColor: PropTypes.string,
        contentPanelCtaColor: PropTypes.string,
    }),
};

export default SideBySideRevealImage;