import styled from 'styled-components';

export const RecommendRecipeWrapper = styled.section`
	width: 100%;
	max-width: 100%;
	margin: auto;
	position: relative;
	background-color: ${({ theme }) => theme.colours.blue};
	padding-top: ${({ theme }) => theme.spacing(150)};
	padding-bottom: ${({ theme }) => theme.spacing(150)};

	h2 {
		color: ${({ theme }) => theme.colours.white};
	}

	p {
		color: ${({ theme }) => theme.colours.white};
	}

	.inner {
		width: 1000px;
		margin: auto;
		text-align: center;
        max-width: 90%;
        .resetQuiz{
            margin: 0 10px;
        }
	}
	.bg-graphics {
		position: absolute;
		display: block;
		top: 0;
		right: 0;
		left: 0;
		bottom: 0;
		height: 100%;
		width: 100%;
		z-index: 0;
		.duke-img {
			width: 450px;
			position: absolute;
			left: 0;
			bottom: 0;
			z-index: 0;
		}

		.ingredient-img {
			width: 460px;
			position: absolute;
			right: 0;
			top: 0;
			z-index: 0;
		}
		.mobile-ingredient-img {
			width: 380px;
			position: absolute;
			right: 0;
			top: 0;
			z-index: 0;
            display: none;
		}
	}
	/* Intro text */
	.intro-text {
		position: relative;
		z-index: 1;

		.text-content {
			z-index: 1;
			position: relative;
		}

		h2 {
			font-family: "apercu condensed pro", serif;
			font-style: normal;
			font-weight: 700;
			font-size: 83px;
			line-height: 1;
			display: block;
			text-align: center;
			color: #ffffff;
		}

		p {
			font-family: "apercu condensed pro", "apercu pro", serif;
			font-style: normal;
			font-weight: 400;
			font-size: 32px;
			line-height: 1.3;
			display: block;
			align-items: center;
			text-align: center;
			color: #ffffff;
		}
	}

	.imgloadtext {
		font-family: "apercu condensed pro", serif;
		font-style: normal;
		font-weight: 700;
		font-size: 33px;
		line-height: 1;
		display: block;
		text-align: center;
		color: #ffffff;
	}

	/* Quiz question styles */
	.quiz-question {
        position: relative;
        z-index: 5;
		.subheading {
			font-family: "apercu condensed pro", serif;
			font-style: normal;
			font-weight: 700;
			font-size: 32px;
			line-height: 1.2;
			text-align: center;
			color: ${({ theme }) => theme.colours.yellow};
			margin-bottom: 20px;
		}

		h3 {
			font-family: "apercu condensed pro", serif;
			font-style: normal;
			font-weight: 700;
			font-size: 56px;
			line-height: 1.2;
			text-align: center;
			color: #ffffff;
            max-width: 80%;
            margin: auto auto 30px auto;
		}
	}

	/* Quiz results styles */
	.quiz-results {
		h2 {
			font-family: "apercu condensed pro", serif;
			font-style: normal;
			font-weight: 700;
			font-size: 83px;
			line-height: 1;
			text-align: center;
			color: #ffffff;
			margin: auto auto 30px auto;
		}

		p {
			font-family: "apercu condensed pro", serif;
			font-style: normal;
			font-weight: 400;
			font-size: 28px;
			line-height: 1.5;
			text-align: center;
			color: #ffffff;
			a {
				color: ${({ theme }) => theme.colours.yellow};
				text-decoration: underline;
                transition: all 0.4s ease-in-out;
				&:hover {
					color: ${({ theme }) => theme.colours.tan};
				}
			}
		}
	}

	.start-button {
		font-family: "apercu pro", "apercu condensed pro", serif;
		font-weight: 700;
		text-transform: capitalize;
		color: ${({ theme }) => theme.colours.red};
		background-color: ${({ theme }) => theme.colours.yellow};
		width: 240px;
		margin: auto;
		padding: 13px 0 9px;
		display: block;
		text-align: center;
		font-size: 26px;
		border-radius: 25px;
		line-height: 1;
		border: 2px solid ${({ theme }) => theme.colours.red};
		transition: all 0.4s ease-in-out;
		cursor: pointer;
		position: relative;
		z-index: 5;
		&:hover {
			background-color: ${({ theme }) => theme.colours.red};
			color: ${({ theme }) => theme.colours.yellow};
			border: 2px solid ${({ theme }) => theme.colours.yellow};
		}
	}

	/* Quiz options */
	.quiz-options {
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
		gap: 20px;
        position: relative;
        z-index: 5;
	}

	.quiz-option {
		display: flex;
		flex-direction: column;
		align-items: center;
		cursor: pointer;
		transition: all 0.2s ease-in-out;
		width: 380px;
		&:hover {
			box-shadow: 0 5px 10px rgb(4 75 150);
			transform: translate(0px, -5px);
			.quiz-option-button{
                background-color: ${({ theme }) => theme.colours.yellow};
            }
		}

		.quiz-option-image {
			width: 100%;
			height: 250px;
			min-height: 250px;
			border-radius: 0;
			object-fit: cover;
		}

		.quiz-option-button {
			margin-top: 0;
			background-color: #ffffff;
			border: none;
			border-radius: 0;
			cursor: pointer;
			width: 100%;
			padding: 20px;
			color: ${({ theme }) => theme.colours.blue};
			font-family: "apercu pro", "apercu condensed pro", serif;
			font-style: normal;
			font-weight: 700;
			font-size: 32px;
			line-height: 1.5;
			display: flex;
			align-items: center;
			text-align: center;
			height: 100%;
			position: relative;
			z-index: 5;
            transition: all 0.4s ease-in-out;
			&:hover {
				background-color: ${({ theme }) => theme.colours.yellow};
			}
		}
	}
	
	/* Quiz results container */
	.results-container {
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
		gap: 20px;
		position: relative;
		z-index: 5;
		.result-item {
			width: 380px;
			background-color: ${({ theme }) => theme.colours.yellow};
			padding: 0;
			border-radius: 0;
			text-align: center;
			text-decoration: none;
            transition: all 0.4s ease-in-out;
            &:hover{
                background-color: #fff;
                box-shadow: 0 5px 10px rgb(4 75 150);
                transform: translate(0px, -5px);
            }
			.result-image {
				width: 100%;
				height: 250px;
				min-height: 250px;
				border-radius: 0;
				object-fit: cover;
			}

			h3 {
				font-family: "apercu condensed pro", serif;
				font-style: normal;
				font-weight: 700;
				font-size: 28px;
				line-height: 1.2;
				text-transform: capitalize;
				color: ${({ theme }) => theme.colours.blue};
				display: block;
				padding: 32px;
				text-align: left;
				margin-bottom: 0;
			}
		}
	}

	/* Keyframes */
	@keyframes fadeIn {
		0% {
			opacity: 0;
			transform: translateY(10px);
		}
		100% {
			opacity: 1;
			transform: translateY(0);
		}
	}
	.option-fade-in {
		opacity: 0;
		animation: optionFadeIn 0.5s forwards ease;
	}

	@keyframes optionFadeIn {
		from {
			opacity: 0;
			transform: translateY(10px);
		}
		to {
			opacity: 1;
			transform: translateY(0);
		}
	}

	@keyframes fadeOut {
		0% {
			opacity: 1;
			transform: translateY(0);
		}
		100% {
			opacity: 0;
			transform: translateY(-10px);
		}
	}

	.fade-in {
		animation: fadeIn 0.5s forwards;
	}

	.fade-out {
		animation: fadeOut 0.5s forwards;
	}

	/* Loading spinner */
	.loading-spinner {
		position: absolute;
		left: 0;
		right: 0;
		width: 40px;
		height: 40px;
		margin: 20px auto;
		border: 6px solid ${({ theme }) => theme.colours.yellow};
		border-top-color: ${({ theme }) => theme.colours.blue};
		border-radius: 50%;
		animation: spin 1s linear infinite;
	}

	@keyframes spin {
		to {
			transform: rotate(360deg);
		}
	}

	/* Loading screen */
	.loading-screen {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		height: auto;
		text-align: center;
        padding-bottom: 250px;
	}

	.duke-loading-img {
		display: block;
		width: 383px;
		max-width: 80%;
		margin: auto;
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        @media (max-width: 1000px){
            width: 280px
        }
	}

	/* Additional text styling */
	.qsubheading {
		display: block;
        position: relative;
		font-family: "apercu condensed pro", serif;
		font-style: normal;
		color: ${({ theme }) => theme.colours.yellow};
		text-align: center;
	}
    @media (max-width: 1600px){
		.results-container .result-item{
            width: 330px;
        }
		.quiz-option{
			width: 330px;
        }
        .bg-graphics .duke-img{
            width: 320px;
        }
		.bg-graphics .ingredient-img {
            display: none;
		}
		.bg-graphics .mobile-ingredient-img{
            display: block;
        }
    }
	@media (max-width: 1420px){
		.dJJeAy .results-container {
			width: 900px;
			margin: auto;
			max-width: 100%;
		}
        .quiz-options{
			width: 900px;
			margin: auto;
			max-width: 100%;
        }
		.bg-graphics .duke-img{
			width: 260px;
		}
		.bg-graphics .ingredient-img {
			display: none;
		}
		.bg-graphics .mobile-ingredient-img{
			display: block;
		}
	}
	@media (max-width: 1000px){
		padding-top: ${({ theme }) => theme.spacing(80)};
		padding-bottom: ${({ theme }) => theme.spacing(80)};
		.start-button{
			margin-bottom: 200px;
		}
		.bg-graphics .duke-img{
			width: 260px;
			left: 0;
			right: 0;
			margin: auto;
            bottom: 0;
		}
        .quiz-option .quiz-option-button{
            font-size: 22px;
        }
		.quiz-results p{
            font-size: 22px;
        }
        .quiz-results h2{
            font-size: 35px;
			max-width: 80%;
			width: 80%;
            margin: auto auto 30px auto;
        }
        .quiz-question h3{
            font-size: 35px;
            max-width: 80%;
            width: 80%;
            margin: auto auto 30px auto;
        }
		.intro-text h2{
            font-size: 45px;
			line-height: 1.2;
            margin: auto auto 30px auto;
            max-width: 80%;
        }
		.intro-text p{
            font-size: 22px;
        }
	}
	@media (max-width: 830px){
		.results-container .result-item{
            width: 180px;
        }
		.results-container .result-item h3{
            padding: 12px;
            font-size: 18px;
        }
		.results-container .result-item .result-image{
			height: 150px;
			min-height: 150px;
        }
		.quiz-option{
            width: 180px;
        }
		.quiz-option .quiz-option-button{
			padding: 12px;
			font-size: 18px;
        }
		.quiz-option .quiz-option-image{
            height: 150px;
            min-height: 150px;
        }
    }
	@media (max-width: 650px) {
		.bg-graphics .mobile-ingredient-img{
            width: 200px;
        }
		.intro-text h2{
			max-width: 95%;
			margin: 0 auto 40px auto;
        }
	}
`;