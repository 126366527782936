import styled, { keyframes } from 'styled-components';
const fadeInAnimation = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

const fadeOutAnimation = keyframes`
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
`;
export const BlueyBeanChallengeWrapper = styled.section`
	position: relative;
	width: 100%;
    background-color: rgb(234, 245, 253);
    box-sizing: border-box;
	padding: ${({ theme }) => theme.spacing({ top: 100, bottom: 0, left: 0, right: 0 })};
    .bean-bowl-container{
		width: 1550px;
		max-width: 90%;
		margin: auto;
		position: absolute;
		left: 0;
		right: 0;
		top: 0;
		display: flex;
		justify-content: flex-end;
		.bean-bowl{
			position: relative;
			top: -90px;
			height: auto;
		}
    }
  .fade-out {
    opacity: 0;
  }
  .fade-in {
    opacity: 1;
  }
  .container{
    width: 1550px;
    max-width: 90%;
    display: block;
    margin: auto;
    box-sizing: border-box;
    padding: ${({ theme }) => theme.spacing({ top: 130, bottom: 95, left: 150, right: 40 })};
    background: #1471D2;
    border-radius: 12px;
    .bean-burst{
      color: ${({ theme }) => theme.colours.yellow};
      width: 122px;
      margin: 0 auto 20px auto;
      display: block !important;
      height: auto;
    }
    .module-heading{
      color: ${({ theme }) => theme.colours.yellow};
      font-family: "apercu pro", apercu pro, sans-serif;
      font-weight: 700;
      font-size: 30px;
      text-transform: uppercase;
      margin-bottom: 15px;
    }
    .inner{
      padding: 60px 45px 100px 45px;
      border-radius: 12px;
      background-color: #EDF6FD;
      box-sizing: border-box;
      transition: opacity 0.4s ease-in-out;
      .inner-heading{
        color: #1471D2;
        font-family: 'hello headline', sans-serif;
      }
      h3{
        color: #1471D2;
        font-family: 'hello headline', sans-serif;
        font-size: 46px;
        line-height: 1.2;
      }
      p{
        color: #404164;
        text-align: center;
        font-size: 32px;
        line-height: 1.3;
      }
    }
  }
	.contents{
		display: flex;
		.text-column{
			width: 65%;
			max-width: 723px;
		}
		.image-column{
			display: flex;
			justify-content: center;
			align-items: center;
			.bluey-dress-up-image{
				max-width: 100%;
				object-fit: contain;
				height: auto;
				width: 520px;
			}
			.supporting-img {
				max-width: 100%;
				object-fit: contain;
				height: auto;
				width: 100%;
				transition: all 0.8s ease-in-out;

				&.fade-out {
					animation: ${fadeOutAnimation} 0.8s ease-in-out;
				}

				&.fade-in {
					animation: ${fadeInAnimation} 0.8s ease-in-out;
				}

				&.fading {
					opacity: 0;
				}
			}    
		}
	}
  	.start-button {
		font-family: "apercu pro", "apercu condensed pro", serif;
		font-weight: 700;
		text-transform: capitalize;
		color: ${({ theme }) => theme.colours.blue};
		background-color: ${({ theme }) => theme.colours.yellow};
		width: 218px;
		margin: 30px auto 0 auto;
		padding: 13px 0 11px;
		display: block;
		text-align: center;
		font-size: 24px;
		border-radius: 40px;
		line-height: 1;
		border: 2px solid ${({ theme }) => theme.colours.yellow};
		transition: all 0.4s ease-in-out;
		cursor: pointer;
		position: relative;
		z-index: 5;
        box-shadow: 0 8px 0 0 #404164;
		&:hover {
			background-color: ${({ theme }) => theme.colours.blue};
			color: ${({ theme }) => theme.colours.yellow};
			border: 2px solid ${({ theme }) => theme.colours.yellow};
            transform: translate(0px, 10px);
            box-shadow: 0 0 0 0 #404164;
		}
	}
  @media (max-width: 1200px) {  
      padding: ${({ theme }) => theme.spacing({ top: 50, bottom: 50, left: 0, right: 0 })};
  }  
  @media (max-width: 1300px) {
    .bean-bowl{
        display: none;
    }
    .container{
      padding: 60px 40px 20px 40px;
    }
	.container .contents{
       flex-flow: column;
    }
    .contents .image-column{
      padding-top: 40px;
    }
	.contents .image-column img{
        max-height: 350px;
    }
    .contents .text-column{
      width: 100%;
      max-width: 100%;
    }
  }
  @media (max-width: 1000px){
    .container .inner{
      padding: 40px;
    }
	  .container .inner h3{
		  font-size: 36px;
	  }
	  .container .inner p {
		  font-size: 28px;
	  }
	  .container .module-heading{
		  font-size: 26px;
	  }
  }
  @media (max-width: 767px){
      padding: 0;
      .container{
          max-width: 100%;
          border-radius: 0;
      }
	  .contents .image-column img{
          max-height: 250px;
      }
  }  
  @media (max-width: 600px) {
    .container {
      padding: 60px 20px;
    }
    .container .inner{
      padding: 30px 20px;
    }
	.start-button{
        font-size: 20px;
    }
  }
`;