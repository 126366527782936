import styled from 'styled-components';

const getVariantColours = ({ variant, theme } = {}) => {
  const variants = {
    red: {
      background: theme.colours.red,
      text: '#fff',
    },
    yellow: {
      background: theme.colours.yellow,
      text: theme.colours.brown,
    },
    blue: {
      background: theme.colours.blue,
      text: theme.colours.yellow,
    },
    tan: {
      background: theme.colours.tan,
      text: theme.colours.brown,
    },
    brown: {
      background: theme.colours.brown,
      text: theme.colours.yellow,
    },
  };

  if (variant in variants) return variants[variant];

  return variants.tan;
};

const checkAlignment = ({ alignment = 'left' }) => {
  const alignments = {
    left: 'left',
    center: 'center',
    right: 'right',
  };

  if (alignment in alignments) return alignments[alignment];

  return alignments.left;
};

export const YtTextComponent = styled.section`
  position: relative;
  width: 100%;
  background-color: ${({ theme, backgroundColor }) =>
      getVariantColours({ variant: backgroundColor, theme }).background};
  padding: ${({ theme }) => theme.spacing({ top: 32, bottom: 64, left: 0, right: 0 })};
  @media (max-width: 900px) {
    padding: ${({ theme }) => theme.spacing({ top: 0, bottom: 32, left: 0, right: 0 })};
  }
  .container{
    width: 1550px;
    max-width: 90%;
    display: block;
    margin: auto;
  }
  .two-col-grid{
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 100px;
    @media (max-width: 900px) {
      grid-template-columns: 1fr;
      gap: 32px;
    }
    .col{
        display: flex;
        flex-direction: column;
        justify-content: start;
        width: 100%;
        max-width: 100%;
       .content-container{
         max-width: 608px;
         margin-bottom: 0;
         width: auto;
         align-self: flex-start;
       }
      @media (max-width: 900px){
        .content-container{
          max-width: 100%;
          
        }
      }
       h2{
         color:  ${({ theme }) => theme.colours.red};
         text-align: left;
         font-size: 69px;
         line-height: 1;
         @media (max-width: 1400px){
           font-size: 55px;
         }
         @media (max-width: 1140px){
           font-size: 45px;
         }
         @media (max-width: 960px){
           font-size: 38px;
         }
         @media (max-width: 900px){
           font-size: 32px;
           text-align: center;
         }
       }
      p{
        text-align: left;
        margin-bottom: 0;
        @media (max-width: 900px){
          text-align: center;
        }
      }
    }
  }
`;

export const YtVideoComponent = styled.div`
    position: relative;
    padding-top: 56.25%;
    overflow: hidden;
    width: 100%;
    cursor: pointer;
    background-size: cover;
    iframe {
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      top: 0;
      width: 100%;
      height: 100%;
    }
`;


export const VideoRightOuterWrapper = styled.section`
	position: relative;
	width: 100%;
	background-color: ${({ theme, backgroundColor }) =>
    getVariantColours({ variant: backgroundColor, theme }).background};
	padding: ${({ theme }) => theme.spacing({ top: 32, bottom: 64, left: 0, right: 0 })};
    box-sizing: border-box;
	@media (max-width: 900px) {
		padding: ${({ theme }) => theme.spacing({ top: 0, bottom: 32, left: 0, right: 0 })};
	}
  .container{
    width: 1550px;
    max-width: 90%;
    display: block;
    margin: auto;
    box-sizing: border-box;
    &.contained {
      background-color: white;
      border-radius: 20px;
      padding: 97px 75px;
      @media (max-width: 900px) {
        padding: 30px;
      }
    }
    &.text-column{
      .module-heading, h2{
        color: #1263BA;
        margin-bottom: 0;
        line-height: 1.2;
      }
      p{
        color: #404164;
      }
    }
  }
  .two-col-grid{
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 100px;
    @media (max-width: 900px) {
      grid-template-columns: 1fr;
      gap: 32px;
    }
    .col{
      display: flex;
      flex-direction: column;
      width: 100%;
      max-width: 100%;
      .content-container{
        max-width: 608px;
        margin-bottom: 0;
        width: auto;
        align-self: flex-start;
      }
      @media (max-width: 900px){
        .content-container{
          max-width: 100%;

        }
      }
      h2{
        color:  ${({ theme }) => theme.colours.red};
        text-align: left;
        font-size: 69px;
        line-height: 1;
        @media (max-width: 1400px){
          font-size: 55px;
        }
        @media (max-width: 1140px){
          font-size: 45px;
        }
        @media (max-width: 960px){
          font-size: 38px;
        }
        @media (max-width: 900px){
          font-size: 32px;
          text-align: center;
        }
      }
      p{
        text-align: left;
        margin-bottom: 0;
        @media (max-width: 900px){
          text-align: center;
        }
      }
      &.text-column{
        justify-content: center;
        order: 0;
        gap: 30px;
        @media (max-width: 900px) {
          order: 1;
          justify-content: start;
          align-items: center;
        }
      }
      &.video-column{
        order: 1;
        @media (max-width: 900px) {
          order: 0;
        }
      }
    }
  }
`;
export const VideoComponent = styled.div`
  position: relative;
  padding-top: 56.25%;
  overflow: hidden;
  width: 100%;
  cursor: pointer;
  background-size: cover;
  iframe {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }
`;

export const VideoRightOuterWrapperBluey = styled.section`
  position: relative;
  width: 100%;
  background-color: #eaf5fd;
  padding: 0;
  box-sizing: border-box;
  @media (max-width: 600px) {
    padding-bottom: 20px;
  }
  .container{
    width: 1550px;
    max-width: 90%;
    display: block;
    margin: auto;
    background-color: white;
    border-radius: 20px;
    padding: 97px 75px;
    position: relative;
    top: 50px;
    box-sizing: border-box;
    @media (max-width: 1200px) {
      padding: 60px 40px;
    }
    @media (max-width: 900px) {
      padding: 80px 20px 60px 20px;
      border-radius: 20px;
      max-width: 95%;
      width: 100%;
    }
    @media (max-width: 600px) {
      top: 0;
    }
    .text-column {
      justify-content: center;
      align-items: flex-start;
      gap: 30px;
      .module-heading, h2 {
        color: #1263BA;
        margin-bottom: 0;
        line-height: 1.2;
        font-family: 'hello headline', sans-serif;
      }
      p {
        color: #404164;
        line-height: 1.4;
      }
    }
  }
  .two-col-grid{
    display: grid;
    grid-template-columns: 5fr 7fr;
    gap: 100px;
    @media (max-width: 1200px) {
      gap: 50px;
    }
    @media (max-width: 900px) {
      grid-template-columns: 1fr;
      gap: 32px;
    }
    .col{
      display: flex;
      flex-direction: column;
      width: 100%;
      max-width: 100%;
      .content-container{
        max-width: 608px;
        margin-bottom: 0;
        width: auto;
        align-self: flex-start;
      }
      @media (max-width: 900px){
        width: 90%;
        margin: auto;
        .content-container{
          max-width: 100%;
        }
      }
      h2{
        color:  ${({ theme }) => theme.colours.red};
        text-align: left;
        font-size: 69px;
        line-height: 1;
        @media (max-width: 1400px){
          font-size: 55px;
        }
        @media (max-width: 1140px){
          font-size: 45px;
        }
        @media (max-width: 960px){
          font-size: 38px;
        }
        @media (max-width: 900px){
          font-size: 40px;
          text-align: left;
        }
      }
      p{
        text-align: left;
        margin-bottom: 0;
        font-size: 20px;
        @media (max-width: 900px){
          text-align: left;
        }
      }
      &.text-column{
        justify-content: center;
        order: 0;
        @media (max-width: 900px) {
          order: 0;
          justify-content: start;
          align-items: start;
        }
      }
      &.video-column{
        order: 1;
        @media (max-width: 900px) {
          order: 1;
          width: 90%;
          margin: auto;
        }
        @media (max-width: 600px) {
          width: 100%;
        }
      }
    }
  }
  .animated-cans{
    position: absolute;
    width: 280px;
    bottom: -80px;
    left: 7%;
    z-index: 1;
    @media (max-width: 900px){
      width: 200px;
      bottom: -60px;
      left: 7%;
      z-index: 1;
    }
  }
`;