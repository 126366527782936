import styled from 'styled-components';

const getVariantColours = ({ type, theme, invertColor = false } = {}) => {
  const variants = {
      red: {
          background: invertColor ? theme.colours.red : theme.colours.yellow,
          text: invertColor ? theme.colours.yellow : theme.colours.red,
          hover: {
              background: invertColor ? theme.colours.yellow : theme.colours.red,
              text: invertColor ? theme.colours.red : theme.colours.yellow,
          },
      },
      yellow: {
          background: invertColor ? theme.colours.yellow : theme.colours.red,
          text: invertColor ? theme.colours.red : theme.colours.yellow,
          hover: {
              background: invertColor ? theme.colours.red : theme.colours.yellow,
              text: invertColor ? theme.colours.yellow : theme.colours.red,
          },
      },
      blue: {
          background: invertColor ? theme.colours.blue : theme.colours.yellow,
          text: invertColor ? theme.colours.yellow : theme.colours.blue,
          hover: {
              background: invertColor ? theme.colours.yellow : theme.colours.blue,
              text: invertColor ? theme.colours.blue : theme.colours.yellow,
          },
      },
      brown: {
          background: invertColor ? theme.colours.brown : theme.colours.yellow,
          text: invertColor ? theme.colours.yellow : theme.colours.brown,
          hover: {
              background: invertColor ? theme.colours.yellow : theme.colours.brown,
              text: invertColor ? theme.colours.brown : theme.colours.yellow,
          },
      },
  };

  const types = {
    product: variants.yellow,
    recipe: variants.red,
  };

  if (type in types) return types[type];

  return types.product;
};

export const HoverWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  transition: opacity 0.35s ease-in-out;

  &:hover {
    opacity: 1;
  }

  .ribbon {
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
    transform: translate(0, -50%);
    pointer-events: none;
  }
  .product & .ribbon {
    right: 0;
    margin: auto;
    width: 250px;
    padding: 15px 0;
    border-radius: 50px;
    background-color: ${({ theme }) => theme.colours.yellow};
    top: 80%;
    span{
      font-size: 20px;
      letter-spacing: 2px;
      text-transform: uppercase;
      color: ${({ theme }) => theme.colours.red};
      font-family: ${({ theme }) => theme.typography.fontFamily.heading};  
    }
  }
`;


export const CardComponent = styled.article`
  position: relative;
  font-family: ${({ theme }) => theme.typography.fontFamily.heading};
  overflow: hidden;
  &.product {
    .product-inner-image-container {
      position: absolute;
      top: 50%;
      left: 0;
      right: 0;
      width: 100%;
      height: auto;
      object-fit: cover;
      transform: translate(0%, -50%);
      transition: all 0.4s ease 0s;
      max-width: 100%;
      .img{
        position: relative;
        top: 0px;
        left: 0px;
        transform: none;
		  @media (min-width: 2400px) {
			  transform: scale(0.8);
		  }
	  }
      @media (max-width: 1199px){
        .product-inner-image-container{
          img {
            position: relative;
            top: 0;
            left: 0;
            transform: none;
            right: 0;
            margin: auto;
          }
        }
      }
    }
    footer{
      &.productCardFooter{
        h2{
			font-size: 31px;
			margin-bottom: 5px;
			line-height: 1.2;
        }
		h3{
			font-size: 31px;
			margin-bottom: 5px;
			line-height: 1.2;
		}  
        p{
			font-size: 18px;
			line-height: 1.5;
        }
        @media (max-width: 800px) {
          h2{
            font-size: 18px;
          }  
          h3{
			font-size: 18px;
		  }  
          p{
            font-size: 16px;
          }
        }
      }
    }
    .image-container{
      height: 100%;
    }
  }
  .image-container {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    overflow: hidden;
    background-color: ${({ theme }) => theme.colours.yellow};
    max-height: 180px;
    transition: all 0.4s ease-in-out;
    &:hover{
      background-color: ${({ theme }) => theme.colours.blue};
    }
    ${({ theme }) => theme.breakPoints.minBp('tablet')} {
      max-height: 360px;
    }

	  @media (min-width: 1200px) {
		  max-height: 500px;
	  }
	  @media (min-width: 1400px) {
		  max-height: 560px;
	  }

    &:before {
      display: block;
      content: '';
      width: 100%;
      padding-top: calc((4 / 3) * 100%);
    }

    &.article {
      max-height: 240px;
      ${({ theme }) => theme.breakPoints.minBp('tablet')} {
        max-height: 320px;
      }
      ${({ theme }) => theme.breakPoints.minBp('desktop')} {
        max-height: 480px;
      }
    }
    > a {
      position: relative;
      display: flex;
      flex-direction: column;
      &:hover {
        p {
          color: ${({ theme }) => theme.colours.adaBrown};
        }
      }
    }

    &:hover {
      > img {
        transform: translate(-50%, -50%) scale(1.02);
      }
      &${HoverWrapper} {
        visibility: visible;
        opacity: 1;
      }
      .image-container{
        background-color: ${({ theme }) => theme.colours.red};
      }
    }
    .product-inner-image-container {
      position: absolute;
      top: 50%;
      left: 50%;
      width: 100%;
      height: auto;
      object-fit: cover;
      transform: translate(-50%, -50%);
      transition: all 0.4s ease 0s;
      max-width: 70%;
      img {
        position: relative;
        top: 0;
        left: 0;
        transform: none;
		  @media (min-width: 2400px) {
				  transform: scale(0.8);
		  }  
      }
      .product-card-badge {
        width: 80px;
        position: absolute;
        top: -10px;
        left: 0;
        ${({ theme }) => theme.breakPoints.minMaxBp('481px', '768px')} {
          top: -20px;
          width: 80px;
        }
        ${({ theme }) => theme.breakPoints.minMaxBp('769px', '1200px')} {
          top: -10px;
          width: 80px;
        }
        ${({ theme }) => theme.breakPoints.maxBp('mobile')} {
          top: -5px;
          width: 35px;
        }
      }
    }
  }

  img {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    height: ${({ type }) => (type === 'product' ? 'auto' : '100%')};
    object-fit: cover;
    transform: translate(-50%, -50%);
    transition: 0.4s;
    .card-link {
      box-sizing: border-box;
      width: 100%;
      padding: ${({ theme }) => theme.spacing(8)};
      color: ${({ theme, type }) => getVariantColours({ theme, type }).text};
      font-size: ${({ theme }) => theme.spacing(14)};
      text-align: center;
      text-transform: uppercase;
      text-decoration: none;
      background-color: ${({ theme, type }) => getVariantColours({ theme, type }).background};
    }
  }

  .button-love {
    position: absolute;
    top: 0;
    right: 0;
    margin: ${({ theme }) => theme.spacing(8)};
    z-index: 10;
    ${({ theme }) => theme.breakPoints.minBp('desktop')} {
      margin: ${({ theme }) => theme.spacing(16)};
    }
  }

  footer {
    transition: all 0.4s ease-in-out; 
    height: 100%;
    padding: ${({ theme }) => theme.spacing({ top: 16, left: 0, right: 0, bottom: 0 })};
    ${({ theme }) => theme.breakPoints.minBp('desktop')} {
      padding: ${({ theme }) => theme.spacing({ top: 40, left: 40, right: 40, bottom: 0 })};
    }
  }

  h2 {
    margin-bottom: ${({ theme }) => theme.spacing(10)};
    color: ${({ theme }) => theme.colours.red};
    font-size: ${({ theme }) => `clamp(${theme.spacing(24)}, 4vw, ${theme.spacing(40)})`};
    line-height: ${({ theme }) => `clamp(${theme.spacing(28)}, 4vw, ${theme.spacing(44)})`};
    text-transform: uppercase;
    ${({ theme }) => theme.breakPoints.minBp('desktop')} {
      margin-bottom: ${({ theme }) => theme.spacing(24)};
    }
  }
  h3 {
    margin-bottom: ${({ theme }) => theme.spacing(10)};
    color: ${({ theme }) => theme.colours.red};
    font-size: ${({ theme }) => `clamp(${theme.spacing(24)}, 4vw, ${theme.spacing(40)})`};
    line-height: ${({ theme }) => `clamp(${theme.spacing(28)}, 4vw, ${theme.spacing(44)})`};
    text-transform: uppercase;
    ${({ theme }) => theme.breakPoints.minBp('desktop')} {
      margin-bottom: ${({ theme }) => theme.spacing(24)};
    }
  }  
  .c-card-h{
    color:  ${({ theme }) => theme.colours.red};
    font-weight: 400;
    text-align: left;
    text-transform: uppercase;
    font-size: ${({ theme }) => `clamp(${theme.spacing(24)}, 4vw, ${theme.spacing(40)})`};
    line-height: ${({ theme }) => `clamp(${theme.spacing(26)}, 4vw, ${theme.spacing(44)})`};
    ${({ theme }) => theme.breakPoints.minBp('desktop')} {
      margin-bottom: ${({ theme }) => theme.spacing(24)};
    }
  }

  p {
    margin: 0;
    color: ${({ theme }) => theme.colours.adaBrown};
    text-align: center;
    ${({ theme }) => theme.breakPoints.maxBp('tablet')} {
      font-size: ${({ theme }) => theme.spacing(12)};
      line-height: ${({ theme }) => theme.spacing(16)};
    }
  }
`;

export const ProductCardComponent = styled.article`
  position: relative;
  font-family: ${({ theme }) => theme.typography.fontFamily.heading};
  overflow: hidden;
  transition: all 0.4s ease-in-out;  
  &.product {
	 @media (max-width: 1199px){
         background-color: ${({ theme }) => theme.colours.yellow};
     }  
    .product-inner-image-container {
      position: absolute;
      top: 50%;
      left: 0;
      right: 0;
      width: 100%;
      height: auto;
      object-fit: cover;
      transform: translate(0%, -50%);
      transition: all 0.4s ease 0s;
      max-width: 100%;
      .img{
        position: relative;
        top: 0px;
        left: 0px;
        transform: none;
      }
      @media (max-width: 1199px){
        .product-inner-image-container{
          img {
            position: relative;
            top: 0;
            left: 0;
            transform: none;
            right: 0;
            margin: auto;
          }
        }
      }
    }
    .image-container{
      height: 100%;
    }
  }
  .image-container {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    overflow: hidden;
    background-color: ${({ theme }) => theme.colours.yellow};
    max-height: 280px;
    transition: all 0.4s ease-in-out;
	  @media (max-width: 450px){
		  max-height: 180px;
	  }
    ${({ theme }) => theme.breakPoints.minBp('tablet')} {
      max-height: 360px;
    }

	  @media (min-width: 1200px) {
		  max-height: 500px;
	  }
	  @media (min-width: 1400px) {
		  max-height: 560px;
	  }

    &:before {
      display: block;
      content: '';
      width: 100%;
      padding-top: calc((4 / 3) * 100%);
    }

    &.article {
      max-height: 240px;
      ${({ theme }) => theme.breakPoints.minBp('tablet')} {
        max-height: 320px;
      }
      ${({ theme }) => theme.breakPoints.minBp('desktop')} {
        max-height: 480px;
      }
    }
    > a {
      position: relative;
      display: flex;
      flex-direction: column;
      &:hover {
        p {
          color: ${({ theme }) => theme.colours.adaBrown};
        }
      }
    }

    &:hover {
      > img {
        transform: translate(-50%, -50%) scale(1.02);
      }
      &${HoverWrapper} {
        visibility: visible;
        opacity: 1;
      }
      .image-container{
        background-color: ${({ theme }) => theme.colours.red};
      }
    }
    .product-inner-image-container {
      position: absolute;
      top: 50%;
      left: 50%;
      width: 100%;
      height: auto;
      object-fit: cover;
      transform: translate(-50%, -50%);
      transition: all 0.4s ease 0s;
      max-width: 70%;
      img {
        position: relative;
        top: 0;
        left: 0;
        transform: none;
		  @media (min-width: 2400px) {
			  transform: scale(0.8);
		  }
	  }
      .product-card-badge {
        width: 80px;
        position: absolute;
        top: -10px;
        left: 0;
        ${({ theme }) => theme.breakPoints.minMaxBp('481px', '768px')} {
          top: -20px;
          width: 80px;
        }
        ${({ theme }) => theme.breakPoints.minMaxBp('769px', '1200px')} {
          top: -10px;
          width: 80px;
        }
        ${({ theme }) => theme.breakPoints.maxBp('mobile')} {
          top: -5px;
          width: 35px;
        }
      }
    }
  }

  img {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    height: ${({ type }) => (type === 'product' ? 'auto' : '100%')};
    object-fit: cover;
    transform: translate(-50%, -50%);
    transition: 0.4s;
    .card-link {
      box-sizing: border-box;
      width: 100%;
      padding: ${({ theme }) => theme.spacing(8)};
      color: ${({ theme, type }) => getVariantColours({ theme, type }).text};
      font-size: ${({ theme }) => theme.spacing(14)};
      text-align: center;
      text-transform: uppercase;
      text-decoration: none;
      background-color: ${({ theme, type }) => getVariantColours({ theme, type }).background};
    }
  }

  .button-love {
    position: absolute;
    top: 0;
    right: 0;
    margin: ${({ theme }) => theme.spacing(8)};
    z-index: 10;
    ${({ theme }) => theme.breakPoints.minBp('desktop')} {
      margin: ${({ theme }) => theme.spacing(16)};
    }
  }
    
	&.active{
		background-color: ${({ theme }) => theme.colours.yellow};
		.image-container .product-inner-image-container img{
            transform: scale(1.08) translate(0px, 5px);
            @media (min-width: 2040px){
				transform: scale(1.04) translate(0px, -5%);
            }
			@media (min-width: 2400px) {
				transform: scale(0.9);
			}
		}
        footer{
            h3{
				transform: translate(0px, -90px);
            }
            p{
				transform: translate(0px, -90px);
            }
            //padding-top: 0;
			//transform: translate(0px, -90px);
			background: transparent;
			@media (max-width: 1430px) {
				h3{
					transform: translate(0px, -100px);
				}
				p{
					transform: translate(0px, -100px);
				}
				.hoverButton{
					transform: translate(0px, -20px);
				}
			}
			@media (max-width: 1290px) {
				transform: translate(0px, -30px);
				overflow: visible;
			}
			@media (max-width: 1199px) {
                overflow: hidden;
				transform: translate(0px, 0px);
				h3{
					transform: translate(0px, 0px);
				}
				p{
					transform: translate(0px, 0px);
				}
				.hoverButton{
					transform: translate(0px, 0px);
				}
			}
        }
        .hoverButton{
			//transform: translate(0px, 80px);
			opacity: 1;
			height: 50px;
        }
		@media (max-width: 1320px){
            overflow: visible;
        }
		@media (max-width: 1199px){
			overflow: hidden;
		}
	}
	${({ theme }) => theme.breakPoints.maxBp('tablet')}{
        background-color: ${({theme}) => theme.colours.yellow};
    }
	.hoverButton{
		opacity: 0;
		height: 0;
		overflow: hidden;
		@media (max-width: 1199px){
			opacity: 1;
			height: 50px;
		}
	}
`;

export const ProductFooter = styled.footer`
    background: transparent;
    overflow: visible;
	transform: translate(0px, 0px);
    transition: all 0.4s ease-in-out;
	padding: ${({ theme }) => theme.spacing({ top: 16, left: 16, right: 16, bottom: 0 })};
	@media (min-width: 1200px){
		padding: ${({ theme }) => theme.spacing({ top: 20, left: 10, right: 10, bottom: 40 })};
    }
	@media (min-width: 1450px){
		padding: ${({ theme }) => theme.spacing({ top: 40, left: 40, right: 40, bottom: 0 })};
	}
    .c-card-h{
        color:  ${({ theme }) => theme.colours.red};
        font-weight: 400;
        text-align: left;
        text-transform: uppercase;
        font-size: ${({ theme }) => `clamp(${theme.spacing(24)}, 4vw, ${theme.spacing(40)})`};
        line-height: ${({ theme }) => `clamp(${theme.spacing(26)}, 4vw, ${theme.spacing(44)})`};
        ${({ theme }) => theme.breakPoints.minBp('desktop')} {
          margin-bottom: ${({ theme }) => theme.spacing(24)};
        }
    }
	h3{
		color: ${({ theme }) => theme.colours.red};
		font-size: 31px;
		margin-bottom: 29px;
		line-height: 1.2;
		color: ${({ theme }) => theme.colours.red};
		text-transform: uppercase;
        text-align: center;
        display: block;
		transform: translate(0px, 0px);
        transition: all 0.4s ease-in-out;
		${({ theme }) => theme.breakPoints.minBp('desktop')} {
			font-size: ${({ theme }) => `clamp(${theme.spacing(24)}, 3vw, ${theme.spacing(40)})`};
			line-height: ${({ theme }) => `clamp(${theme.spacing(28)}, 3vw, ${theme.spacing(44)})`};
            margin-bottom: 5px;
		}
        sup{
			position: relative;
			top: 0;
			left: 1px;
			font-size: 60%;
			line-height: 0.5em;
			vertical-align: super;
			font-family: 'apercu pro';
			font-weight: 400;
        }
	}
	p{
		font-size: 18px;
		line-height: 1.5;
		margin: 0;
		color: ${({ theme }) => theme.colours.adaBrown};
		text-align: center;
		display: block;
		transform: translate(0px, 0px);
		transition: all 0.4s ease-in-out;
		${({ theme }) => theme.breakPoints.maxBp('tablet')} {
			font-size: ${({ theme }) => theme.spacing(12)};
			line-height: ${({ theme }) => theme.spacing(16)};
		}
		@media (max-width: 1199px){
			display: none;
		}
	}
	@media (max-width: 800px) {
		h2{
			font-size: 18px;
		}
		h3{
			font-size: 18px;
		}
		p{
			font-size: 16px;
		}
	}
	${({ theme }) => theme.breakPoints.maxBp('desktop')}{
		background-color: ${({theme}) => theme.colours.yellow};
	}
`;

export const ButtonHoverWrapper = styled.div`
  position: absolute;
  top: auto;
  left: 0;
  right: 0;
  bottom: 20px;  
  width: 100%;
  height: 100%;
  opacity: 0;
  transition: all 0.4s ease-in-out;
  text-align: center;  
    
  &:hover {
    opacity: 1;
  }
   .view-button{
	   height: 50px;
	   border-radius: 50px;
	   display: inline-block;
	   background: ${({ theme }) => theme.colours.red};
	   padding: 0 40px;
	   font-weight: 700;
	   color: ${({ theme }) => theme.colours.yellow};
       text-transform: uppercase;
	   font-family: "apercu condensed pro", impact;
       cursor: pointer;
       span{
		   position: relative;
		   left: 10px;
           top: 1px;
		   font-size: 18px;
           transition: all 0.4s ease-in-out;
       }
       svg{
           position: relative;
           top: 2px;
		   color: ${({ theme }) => theme.colours.yellow};
           width: 0;
           opacity: 0;
           transition: all 0.4s ease-in-out;
       }
       &:hover{
           svg{
               opacity: 1;
               width: 20px;
           }
           span{
                left: 0;
           }
       }
	   ${({ theme }) => theme.breakPoints.maxBp('desktop')}{
		   height: 40px;
           span{
			   font-size: 16px;
           }
	   }
	   ${({ theme }) => theme.breakPoints.maxBp('tablet')}{
           padding: 0 10px;
           min-width: 125px;
	   }
    }
	${({ theme }) => theme.breakPoints.maxBp('tablet')}{
		min-width: 125px;
		.view-button{
			padding: 0 10px;
			min-width: 125px; 
        }
	}
	@media (max-width: 1199px){
        display: block;
        position: relative;
    }
`;

export const ProductPromoComponent = styled.article`
  position: relative;
  width: 100%;
  font-family: ${({ theme }) => theme.typography.fontFamily.heading};
  &.product {
    .image-container {
      height: 100%;
      &:hover {
        background-color: ${({theme}) => theme.colours.blue};
      }
    }
    .product-inner-image-container {
      position: absolute;
      top: 50%;
      left: 0;
      right: 0;
      width: 100%;
      height: auto;
      object-fit: cover;
      transform: translate(0%, -50%);
      transition: all 0.4s ease 0s;
      max-width: 100%;
      .img {
        position: relative;
        top: 0;
        left: 0;
        transform: none;
        right: 0;
        display: block;
        margin: auto;
		  @media (min-width: 2400px) {
			  transform: scale(0.8);
		  }
	  }
      @media (max-width: 1199px) {
          img {
            position: relative;
            top: 0;
            left: 0;
            transform: none;
            right: 0;
            margin: auto;
            display: block;
          }
      }
    }
    footer {
      .image-container {
        height: 100%;
      }
		p {
			text-align: center;
			display: block;
			width: 100%;
			margin-bottom: 0;
			transform: translate(0px, 0px);
			transition: all 0.4s ease-in-out;
		}
    }
  }  
  .image-container {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    overflow: hidden;
    background-color: ${({ theme }) => theme.colours.blue};
    max-height: 280px;
    transition: all 0.4s ease-in-out;
    &:before {
      display: block;
      content: '';
      width: 100%;
      padding-top: calc((4 / 6) * 100%);
    }
	  @media (max-width: 450px){
          max-height: 180px;
      }
    ${({ theme }) => theme.breakPoints.minBp('tablet')} {
      max-height: 340px;
    } 
    @media (min-width: 1200px) {
		  max-height: 500px;
	}
	@media (min-width: 1400px) {
      max-height: 560px;
    }

    &.article {
      max-height: 240px;

      ${({ theme }) => theme.breakPoints.minBp('tablet')} {
        max-height: 360px;
      }

      ${({ theme }) => theme.breakPoints.minBp('desktop')} {
        max-height: 480px;
      }
    }

    > a {
      position: relative;
      display: flex;
      flex-direction: column;
    }

    &:hover {
      > img {
        transform: translate(-50%, -50%) scale(1.02);
      }
      &.hover-image {
        visibility: visible;
        opacity: 1;
      }
    }
  }

  .product-inner-image-container {
    position: absolute;
    top: 50%;
    left: 74%;
    width: 100%;
    height: auto;
    object-fit: cover;
    transform: translate(-50%, -50%);
    transition: all 0.4s ease 0s;
    max-width: 70%;
    img {
      position: relative;
      top: 0;
      left: 0;
      transform: none;
      margin: auto;
      display: block;
		@media (min-width: 2400px) {
			transform: scale(0.8);
		}
	}
    .product-card-badge {
      position: absolute;
      left: 0px;
      width: 80px;

      ${({ theme }) => theme.breakPoints.minMaxBp('481px', '768px')} {
        top: -20px;
        width: 80px;
        left: -15px;
      }
      ${({ theme }) => theme.breakPoints.minMaxBp('769px', '1200px')} {
        top: -10px;
        width: 80px;
        left: -15px;
      }
      ${({ theme }) => theme.breakPoints.maxBp('mobile')} {
        top: -5px;
        width: 35px;
        left: -10px;
      }
    }
  }

  img {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    height: ${({ type }) => (type === 'product' ? 'auto' : '100%')};
    object-fit: cover;
    transform: translate(-50%, -50%);
    transition: 0.4s;

    ${({ type }) => (type === 'product' ? `max-width: 35%;` : '')}

    &:hover {
      // transform: translate(-50%, -50%) scale(1.02);
    }
  }

  .button-love {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 10;
    margin: ${({ theme }) => theme.spacing(8)};

    ${({ theme }) => theme.breakPoints.minBp('desktop')} {
      margin: ${({ theme }) => theme.spacing(16)};
    }
  }


	&.active{
		background-color: ${({ theme }) => theme.colours.blue};
		.image-container .product-inner-image-container img{
			transform: scale(1.08) translate(0px, 5px);
			@media (min-width: 2040px){
				transform: scale(1.04) translate(0px, -5%);
			}
			@media (min-width: 2400px) {
				transform: scale(0.9);
			}
		}
		footer{
			h3{
                color: ${({ theme }) => theme.colours.yellow};
				transform: translate(0px, -90px);
			}
			p{
                color: #fff;
				transform: translate(0px, -90px);
			}
			//padding-top: 0;
			//transform: translate(0px, -90px);
			background: transparent;
			@media (max-width: 1430px) {
				h3{
					transform: translate(0px, -100px);
				}
				p{
					transform: translate(0px, -100px);
				}
			}
			@media (max-width: 1330px) {
				h3{
					transform: translate(0px, -90px);
				}
				p{
					transform: translate(0px, -90px);
				}
			}
			@media (max-width: 1199px) {
				h3{
					transform: translate(0px, 0px);
				}
				p{
					transform: translate(0px, 0px);
				}
			}
		}
		.hoverButton{
			//transform: translate(0px, 80px);
			opacity: 1;
			height: 50px;
		}
	}

	.hoverButton{
		opacity: 0;
		height: 0;
		overflow: hidden;
		@media (max-width: 1199px){
            opacity: 1;
            height: 50px;
        }
	}

`;

export const ProductCardPromoFooter = styled.footer`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: ${({ theme }) => theme.spacing({ top: 16, left: 16, right: 16, bottom: 0 })};
  background-color: ${({theme}) => theme.colours.blue};
  gap: 20px;
	@media (min-width: 1200px){
		padding: ${({ theme }) => theme.spacing({ top: 20, left: 10, right: 10, bottom: 40 })};
		background-color: transparent;
		gap: 0;
	}
	@media (min-width: 1450px){
		padding: ${({ theme }) => theme.spacing({ top: 40, left: 40, right: 40, bottom: 0 })};
	}  

  .hoverButton{
	  ${({theme}) => theme.breakPoints.maxBp('desktop')}{
		  inset: 0;
	  }
  }

  h3 {
	font-size: 22px;
    margin-bottom: 0;
    line-height: 1.2;
	color: ${({theme}) => theme.colours.yellow};
    text-align: center;
    display: block;
    width: 100%;
	text-transform: uppercase;;
	transform: translate(0px, 0px);
	transition: 0.4s ease-in-out;
	  ${({theme}) => theme.breakPoints.minBp('tablet')} {
		  font-size: clamp(1.5rem, 3vw, 2.5rem);
		  line-height: clamp(1.75rem, 3vw, 2.75rem);
		  margin-bottom: 5px;
	  }
	  ${({ theme }) => theme.breakPoints.minBp('desktop')} {);
		  margin-bottom: 5px;
		  color: ${({theme}) => theme.colours.red};
	  }
  }
    
  p {
	  font-size: 20px;
	  color: #fff;
	  line-height: 1.5;
	  text-align: center;
	  display: block;
	  width: 100%;
	  margin-bottom: 0;
	  transform: translate(0px, 0px);
	  transition: all 0.4s ease-in-out;
	  ${({theme}) => theme.breakPoints.minBp('tablet')} {
		  font-size: 22px;
	  }
	  ${({theme}) => theme.breakPoints.minBp('desktop')} {
		  color: ${({theme}) => theme.colours.brown};
	  }
  }
`;


export const CardPromoComponent = styled.article`
	position: relative;
	width: 100%;
	font-family: ${({theme}) => theme.typography.fontFamily.heading};

	&.product {
		.image-container {
			height: 100%;

			&:hover {
				background-color: ${({theme}) => theme.colours.blue};
			}
		}

		.product-inner-image-container {
			position: absolute;
			top: 50%;
			left: 0;
			right: 0;
			width: 100%;
			height: auto;
			object-fit: cover;
			transform: translate(0%, -50%);
			transition: all 0.4s ease 0s;
			max-width: 100%;

			.img {
				position: relative;
				top: 0;
				left: 0;
				transform: none;
				right: 0;
				display: block;
				margin: auto;
				@media (min-width: 2400px) {
					transform: scale(0.8);
				}
			}

			@media (max-width: 1199px) {
				img {
					position: relative;
					top: 0px;
					left: 0px;
					transform: none;
					right: 0;
					margin: auto;
					display: block;
				}
			}
		}
      .image-container {
        height: 100%;
      }
  }  
  .image-container {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    overflow: hidden;
    background-color: ${({ theme }) => theme.colours.yellow};
    max-height: 180px;
    transition: all 0.4s ease-in-out;
    &:before {
      display: block;
      content: '';
      width: 100%;
      padding-top: calc((4 / 6) * 100%);
    }

    ${({ theme }) => theme.breakPoints.minBp('tablet')} {
      max-height: 340px;
    }
      
	@media (min-width: 1200px) {
		  max-height: 500px;
	}  
	@media (min-width: 1400px) {
		  max-height: 560px;
    }

    &.article {
      max-height: 240px;

      ${({ theme }) => theme.breakPoints.minBp('tablet')} {
        max-height: 360px;
      }

      ${({ theme }) => theme.breakPoints.minBp('desktop')} {
        max-height: 480px;
      }
    }

    > a {
      position: relative;
      display: flex;
      flex-direction: column;
    }

    &:hover {
      > img {
        transform: translate(-50%, -50%) scale(1.02);
      }
      &.hover-image {
        visibility: visible;
        opacity: 1;
      }
    }
  }

  .product-inner-image-container {
    position: absolute;
    top: 50%;
    left: 74%;
    width: 100%;
    height: auto;
    object-fit: cover;
    transform: translate(-50%, -50%);
    transition: all 0.4s ease 0s;
    max-width: 70%;
    img {
      position: relative;
      top: 0;
      left: 0;
      transform: none;
      margin: auto;
      display: block;
		@media (min-width: 2400px) {
			transform: scale(0.8);
		}
	}
    .product-card-badge {
      position: absolute;
      left: 0px;
      width: 80px;

      ${({ theme }) => theme.breakPoints.minMaxBp('481px', '768px')} {
        top: -20px;
        width: 80px;
        left: -15px;
      }
      ${({ theme }) => theme.breakPoints.minMaxBp('769px', '1200px')} {
        top: -10px;
        width: 80px;
        left: -15px;
      }
      ${({ theme }) => theme.breakPoints.maxBp('mobile')} {
        top: -5px;
        width: 35px;
        left: -10px;
      }
    }
  }

  img {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    height: ${({ type }) => (type === 'product' ? 'auto' : '100%')};
    object-fit: cover;
    transform: translate(-50%, -50%);
    transition: 0.4s;

    ${({ type }) => (type === 'product' ? `max-width: 35%;` : '')}

    &:hover {
      // transform: translate(-50%, -50%) scale(1.02);
    }
  }

  .button-love {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 10;
    margin: ${({ theme }) => theme.spacing(8)};

    ${({ theme }) => theme.breakPoints.minBp('desktop')} {
      margin: ${({ theme }) => theme.spacing(16)};
    }
  }

  footer {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: left;
    padding: ${({ theme }) => theme.spacing(20)};
    background-color: ${({ theme }) => theme.colours.red};

    ${({ theme }) => theme.breakPoints.minBp('desktop')} {
      padding: ${({ theme }) => theme.spacing(40)} ${({ theme }) => theme.spacing(50)};
    }
  }

  h2 {
    margin: 0;
    //color: ${({ theme }) => theme.colours.yellow};
    color: #fff;
    font-weight: 400;
    text-align: left;
    text-transform: uppercase;
    background-color: ${({ theme }) => theme.colours.red};
    font-size: ${({ theme }) => `clamp(${theme.spacing(24)}, 4vw, ${theme.spacing(40)})`};
    line-height: ${({ theme }) => `clamp(${theme.spacing(26)}, 4vw, ${theme.spacing(44)})`};
        ${({ theme }) => theme.breakPoints.minBp('desktop')} {
          max-width: 100%;
        }
    }
    h3 {
		margin: 0;
		//color: ${({ theme }) => theme.colours.yellow};
		color: #fff;
		font-weight: 400;
		text-align: left;
		text-transform: uppercase;
		background-color: ${({ theme }) => theme.colours.red};
		font-size: ${({ theme }) => `clamp(${theme.spacing(24)}, 4vw, ${theme.spacing(40)})`};
		line-height: ${({ theme }) => `clamp(${theme.spacing(26)}, 4vw, ${theme.spacing(44)})`};
        ${({ theme }) => theme.breakPoints.minBp('desktop')} {
          max-width: 100%;
        }
    }  
    .c-card-h{
		color:  ${({ theme }) => theme.colours.red};
		font-weight: 400;
		text-align: left;
		text-transform: uppercase;
		font-size: ${({ theme }) => `clamp(${theme.spacing(24)}, 4vw, ${theme.spacing(40)})`};
		line-height: ${({ theme }) => `clamp(${theme.spacing(26)}, 4vw, ${theme.spacing(44)})`};
    }

  p {
    margin: 0;
    color: ${({ theme }) => theme.colours.white};
    text-align: left;
    &.time {
      margin-left: auto;
      ${({ theme }) => theme.breakPoints.minBp('desktop')} {
        margin-top: ${({ theme }) => theme.spacing(18)};
        margin-left: 0;
      }
    }
    &.date {
      margin-top: 16px;
    }
  }
`;

export const MobileButtonWrapper = styled.div`
  position: relative;
  bottom: 0;
  width: 100%;
  padding: ${({ theme }) => theme.spacing({ top: 10, bottom: 8, left: 0, right: 0 })};
  color: ${({ theme, type }) => getVariantColours({ theme, type }).text};
  text-align: center;
  text-transform: uppercase;
  background-color: ${({ theme, type }) => getVariantColours({ theme, type }).background};
  font-size: 24px;
  ${({ theme }) => theme.breakPoints.minBp('tablet')} {
    padding: ${({ theme }) => theme.spacing({ top: 16, bottom: 14, left: 0, right: 0 })};
  }
`;