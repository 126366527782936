import React from 'react';
import PropTypes from 'prop-types';
import { Container } from './BlogAccordion.styles';
import { Placeholder, isExperienceEditorActive, withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import { Text } from 'components/shared/JssOverrides';

const BlogAccordion = ({ fields = {}, params = {}, rendering, sitecoreContext }) => {
  const experienceEditor = isExperienceEditorActive();
  const { title } = fields;
  const { isFullWidth } = params;

  return (
    <Container isFullWidth={isFullWidth} id={'accordion-alt'} experienceEditor={experienceEditor || sitecoreContext?.pageEditing}>
      <div className="title-container">
        {title && <Text field={title} tag="h1" />}
      </div>
      <ul>
        <Placeholder name="blog-accordion-content" rendering={rendering} style={{ minHeight: '100px' }} />
      </ul>
    </Container>
  );
};

BlogAccordion.propTypes = {
  fields: PropTypes.object,
  params: PropTypes.object,
  rendering: PropTypes.object,
  isFullWidth: PropTypes.string,
  sitecoreContext: PropTypes.object,
};

export default withSitecoreContext()(BlogAccordion);