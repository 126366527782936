import styled from 'styled-components';

export const VideoWrapper = styled.div`
  width: 100%;
  max-width: 860px;
  height: auto;
`;

export const VideoWrapperInner = styled.div`
  position: relative;
  height: 0;
  padding-bottom: ${({ ratio }) => `calc((${ratio[1]} / ${ratio[0]}) * 100%)`};
  box-shadow: 0 0 10px rgba(00, 00, 00, 0.5);
  cursor: pointer;  
  > iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
    .video-poster-fallback{
        position: absolute;
		inset: 0px;
		object-fit: cover;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
		width: 100%;
		height: 100%;
    }
    .video-placeholder {
		position: absolute;
		top: 0;
		left: 0;
		bottom: 0;
		right: 0;
		background-size: cover;
		background-position: center center;
    }
	.play-video-button {
		width: 100px;
		height: 100px;
		margin: auto;
		position: absolute;
		border: 0;
		background: none;
		left: 0;
		right: 0;
		top: 0;
		bottom: 0;
		cursor: pointer;
		.self {
			background-color:  ${({theme}) => theme.colours.red};
			text-align: center;
			border-radius: 50%;
			display: flex;
			justify-content: center;
			align-items: center;
			content: "";
			width: 100px;
			height: 100px;
			min-width: 100px;
			min-height: 100px;
			align-self: center;
			color: #fff;
			position: absolute;
			top: 0;
			bottom: 0;
			left: 0;
			right: 0;
			margin: auto;
			border: 0;
            transition: all 0.4s ease-in-out;
			svg {
				width: 31px;
				height: 38px;
				fill: #fff;
			}
			&:hover {
				background-color: ${({theme}) => theme.colours.yellow};
                svg{
                    fill: ${({theme}) => theme.colours.red};
                }
			}
		}
		@media (max-width: 650px){
			width: 50px;
			height: 50px;
			min-width: 50px;
			min-height: 50px;
			.self{
				width: 50px;
				height: 50px;
				min-width: 50px;
				min-height: 50px;
				svg{
					width: 20px;
					height: 20px;
				}
			}
		}
	}  
`;