import styled from 'styled-components';

export const GridComponent = styled.div`
  display: grid;
  grid-auto-rows: auto;
  grid-gap: ${({ theme, gapSize }) => (gapSize ? theme.spacing(gapSize) : theme.spacing(36))};
  grid-template-columns: repeat(1, 1fr);
  max-width: 90%;
  margin: 0 auto;

  ${({ theme }) => theme.breakPoints.minBp('tablet')} {
    grid-template-columns: repeat(2, 1fr);
  }

  ${({ theme }) => theme.breakPoints.minBp('desktop')} {
    grid-template-columns: repeat(3, 1fr);
    max-width: 90%;
  }

  &.example {
    margin-bottom: 20px;
    > * {
      height: 400px;
      background-color: ${({ theme }) => theme.colours.tan};
      border-radius: 4px;
    }
  }
`;

export const ComplexGrid = styled.div`
  display: grid;
  grid-auto-flow: dense;
  // grid-template-rows: repeat(3, 1fr);
  grid-template-columns: repeat(2, 1fr);
  gap: ${({ theme }) =>
    `clamp(${theme.spacing(36)}, 6vh, ${theme.spacing(60)})
    clamp(${theme.spacing(24)}, 6vw, ${theme.spacing(90)})`};
  max-width: 90%;
  margin: 0 auto;

  ${({ theme }) => theme.breakPoints.minBp('desktop')} {
    grid-auto-rows: auto;
    grid-template-columns: repeat(3, 1fr);
    gap: ${({ theme }) =>
      `clamp(${theme.spacing(60)}, 6vh, ${theme.spacing(100)})
    clamp(${theme.spacing(30)}, 1.75vw, ${theme.spacing(50)})`};
  }
  &.product{
    gap: ${({ theme }) =>
        `clamp(${theme.spacing(36)}, 1vh, ${theme.spacing(60)})
    clamp(${theme.spacing(24)}, 1vw, ${theme.spacing(90)})`};
    max-width: 90%;
    margin: 0 auto;

    ${({ theme }) => theme.breakPoints.minBp('desktop')} {
      grid-auto-rows: auto;
      grid-template-columns: repeat(3, 1fr);
      gap: ${({ theme }) =>
          `clamp(${theme.spacing(60)}, 1vh, ${theme.spacing(100)})
    clamp(${theme.spacing(30)}, 1vw, ${theme.spacing(50)})`};
    }
    ${({ theme }) => theme.breakPoints.maxBp('tablet')} {
      gap: 20px;
    }
  }

  ${({ theme }) => theme.breakPoints.minBp('1920px')} {
    max-width: ${({ theme }) => theme.grid.desktop};
  }

  > * {
    ${({ theme }) => theme.breakPoints.maxBp('desktop')} {
      &:nth-child(5n + 5) {
        grid-column: span 2;
      }
    }
  }

  &.product {
    > * {
      ${({ theme }) => theme.breakPoints.minBp('desktop')} {
        &:nth-child(10n + 4),
        &:nth-child(10n + 10) {
          grid-column: span 2;
        }
      }
    }
  }

  &.recipe {
    > * {
      ${({ theme }) => theme.breakPoints.minBp('desktop')} {
        &:nth-child(10n + 5),
        &:nth-child(10n + 9) {
          grid-column: span 2;
        }
      }
    }
  }

  &.example {
    > * {
      height: 200px;
      background-color: ${({ theme }) => theme.colours.tan};
      border-radius: 4px;

      ${({ theme }) => theme.breakPoints.minBp('desktop')} {
        height: 400px;
      }
    }
  }
`;

const setColumns = ({ columns = 'two' }) => {
  const options = {
    1: '1',
    2: '2',
    3: '3',
  };

  if (columns in options) return options[columns];

  return options['2'];
};

export const AdaptableGrid = styled.div`
  display: grid;
  grid-auto-flow: dense;
  grid-template-rows: repeat(1, 1fr);
  grid-template-columns: repeat(1, 1fr);
  gap: 50px;
  max-width: 90%;
  margin: 0 auto;

  ${({ theme }) => theme.breakPoints.minBp('desktop')} {
    grid-template-rows: repeat(1, 1fr);
    grid-template-columns: repeat(${({ columns }) => setColumns({ columns })}, 1fr);
    gap: ${({ theme }) =>
      `clamp(${theme.spacing(60)}, 6vh, ${theme.spacing(100)})
      clamp(${theme.spacing(50)}, 6vw, ${theme.spacing(100)})`};
  }

  ${({ theme }) => theme.breakPoints.minBp('1920px')} {
    max-width: 90%;
  }

  &.ingredients {
    ${({ theme }) => theme.breakPoints.maxBp('desktop')} {
      gap: 0;
    }
  }

  &.example {
    > * {
      height: 200px;
      background-color: ${({ theme }) => theme.colours.tan};
      border-radius: 4px;

      ${({ theme }) => theme.breakPoints.minBp('desktop')} {
        height: 400px;
      }
    }
  }
`;

export const OffsetGrid = styled.div`
  display: grid;
  grid-auto-flow: dense;
  grid-template-rows: repeat(2, 1fr);
  grid-template-columns: repeat(1, 1fr);
  gap: 36px 24px;
  margin: 0 auto;
  max-width: 90%;

  ${({ theme }) => theme.breakPoints.minBp('desktop')} {
    grid-template-rows: repeat(2, 1fr);
    grid-template-columns: repeat(8, 1fr);
    margin: 100px auto;
    gap: ${({ theme }) =>
      `${theme.spacing(160)}
    clamp(${theme.spacing(80)}, 8vw, ${theme.spacing(120)})`};
  }

  ${({ theme }) => theme.breakPoints.minBp('1920px')} {
    max-width: ${({ theme }) => theme.grid.desktop};
  }

  > * {
    ${({ theme }) => theme.breakPoints.maxBp('desktop')} {
      &:nth-child(2n + 1) {
        margin-left: 0;
        transform: translateX(-8px);
      }

      &:nth-child(2n + 2) {
        transform: translateX(8px);
      }
    }

    ${({ theme }) => theme.breakPoints.minBp('desktop')} {
      &:nth-child(4n + 1),
      &:nth-child(4n + 3) {
        grid-column: span 4;
        margin-top: ${({ theme }) => theme.spacing(-80)};
      }

      &:nth-child(4n + 2),
      &:nth-child(4n + 4) {
        grid-column: span 4;
        margin-top: ${({ theme }) => theme.spacing(40)};
      }
    }

    ${({ theme }) => theme.breakPoints.minBp('1440px')} {
      &:nth-child(4n + 1) {
        grid-column: span 3;
        margin-top: ${({ theme }) => theme.spacing(-80)};
      }

      &:nth-child(4n + 2) {
        grid-column: span 5;
        margin-top: ${({ theme }) => theme.spacing(40)};
      }

      &:nth-child(4n + 3) {
        grid-column: span 5;
        margin-top: ${({ theme }) => theme.spacing(-40)};
      }

      &:nth-child(4n + 4) {
        grid-column: span 3;
        margin-top: ${({ theme }) => theme.spacing(80)};
      }
    }
  }

  &.example {
    > * {
      height: 200px;
      background-color: ${({ theme }) => theme.colours.tan};
      border-radius: 4px;

      ${({ theme }) => theme.breakPoints.minBp('desktop')} {
        height: 400px;
      }
    }
  }
`;