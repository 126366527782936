import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { Image } from '@sitecore-jss/sitecore-jss-react';
import { isServer } from '@sitecore-jss/sitecore-jss';
import {
  MediaCarouselVideoSlideComponent,
  ContentWrapper,
  Media,
  BackgroundImage,
} from './MediaCarouselVideoSlide.styles.js';

import Video from 'components/shared/Video';

const MediaCarouselVideoSlide = ({ fields = {}, params = {} }) => {
  const { videoId = {}, backgroundImage = {} } = fields;
  const videoRef = useRef(null);
  const {videoPoster = ''} = params;
  return (
    <MediaCarouselVideoSlideComponent className="media-carousel-video-slide">
      <BackgroundImage>
        <Image field={backgroundImage}  className="background-image" draggable={false} />
      </BackgroundImage>
      {videoId?.value && !isServer() && (
        <ContentWrapper>
          <Media ref={videoRef}>
            <Video
              key={`youtube-video-${videoId?.value}`}
              youtubeId={videoId?.value}
              width={1600}
              height={900}
              poster={videoPoster}
            />
          </Media>
        </ContentWrapper>
      )}
    </MediaCarouselVideoSlideComponent>
  );
};

MediaCarouselVideoSlide.propTypes = {};

export default MediaCarouselVideoSlide;