import React from 'react';
import { useDeviceResize } from 'hooks';
import { isServer } from '@sitecore-jss/sitecore-jss';
import InterruptorRibbon from 'components/shared/InterruptorRibbon/InterruptorRibbon.jsx';
import Button from 'components/shared/Button';
import PromoBox from 'components/shared/PromoBox';
import { withSitecoreContext, isExperienceEditorActive } from '@sitecore-jss/sitecore-jss-react';

import { HeroCarouselSlideComponent } from './HeroCarouselSlide.styles';

const HeroCarouselSlide = ({ sitecoreContext, setCurrentSlide, totalSlides, rendering, ...props }) => {
  const fields = rendering?.fields;
  const params = rendering?.params;
  const device = useDeviceResize();
  const experienceEditor = isExperienceEditorActive();

  if (typeof fields?.copy?.value === 'string') {
    fields.copy.value.replace(new RegExp('\r?\n', 'g'), '<br />'); // eslint-disable-line no-control-regex
  }

  if (experienceEditor || sitecoreContext?.pageEditing) {
    return (
      <HeroCarouselSlideComponent>
        <div
          className="image-container"
          style={{
            backgroundImage: `url(${fields?.backgroundImage?.value?.src})`,
          }}
        ></div>
        <PromoBox
          className="promo"
          titleClass="hero"
          title={fields?.title}
          titleColor={params?.titleColor}
          copy={fields?.copy}
          copyColor={params?.copyColor}
          cta={fields?.cta}
          ctaColor={params?.ctaStyle}
          variant="transparent"
          underlineTitle
          pageEditing
        >
          {!!(params?.showRibbon?.length && params?.showRibbon === '1') && (
            <>
              <InterruptorRibbon className="ribbon-left" variant={params?.ribbonStyle} />
              <InterruptorRibbon className="ribbon-right" variant={params?.ribbonStyle} />
            </>
          )}

          <div className="controls">
            <Button
              type="circle"
              icon="chevron-left"
              variant={params?.arrowStyle}
              size={device === 'desktop' ? 'large' : 'small'}
              ariaLabel="Next slide"
              onClick={() => {
                if (typeof setCurrentSlide === 'function') {
                  setCurrentSlide((currentSlide) => {
                    return currentSlide === totalSlides ? 1 : currentSlide + 1;
                  });
                }
              }}
            />
            <Button
              type="circle"
              icon="chevron-right"
              variant={params?.arrowStyle}
              size={device === 'desktop' ? 'large' : 'small'}
              ariaLabel="Previous slide"
              onClick={() => {
                if (typeof setCurrentSlide === 'function') {
                  setCurrentSlide((currentSlide) => {
                    return currentSlide !== 1 ? currentSlide - 1 : totalSlides;
                  });
                }
              }}
            />
          </div>
        </PromoBox>
      </HeroCarouselSlideComponent>
    );
  }

  return (
    <div className="slide">
        <div
            className="image-container"
            style={{
                backgroundImage: `url(${fields?.backgroundImage?.value?.src}?mw=2000)`,
                '@media (max-width: 768px)': {
                    backgroundImage: `url(${fields?.backgroundImage?.value?.src}?mw=800)`,
                },
            }}
        ></div>
      <PromoBox
        className="promo"
        titleClass="hero"
        title={fields?.title}
        titleColor={params?.titleColor}
        copy={fields?.copy}
        copyColor={params?.copyColor}
        cta={fields?.cta}
        ctaColor={params?.ctaStyle}
        variant="transparent"
        underlineTitle
      >
        {!!(params?.showRibbon?.length && params?.showRibbon === '1') && (
          <>
            <InterruptorRibbon className="ribbon-left" variant={params?.ribbonStyle} />
            <InterruptorRibbon className="ribbon-right" variant={params?.ribbonStyle} />
          </>
        )}
        {!isServer() && totalSlides > 1 && (
          <div className="controls">
            <Button
              type="circle"
              icon="chevron-left"
              variant={params?.arrowStyle}
              size={device === 'desktop' ? 'large' : 'small'}
              ariaLabel="Next slide"
              onClick={() => {
                if (typeof setCurrentSlide === 'function') {
                  setCurrentSlide((currentSlide) => {
                    return currentSlide === totalSlides ? 1 : currentSlide + 1;
                  });
                }
              }}
            />
            <Button
              type="circle"
              icon="chevron-right"
              variant={params?.arrowStyle}
              ariaLabel="Previous slide"
              size={device === 'desktop' ? 'large' : 'small'}
              onClick={() => {
                if (typeof setCurrentSlide === 'function') {
                  setCurrentSlide((currentSlide) => {
                    return currentSlide !== 1 ? currentSlide - 1 : totalSlides;
                  });
                }
              }}
            />
          </div>
        )}
      </PromoBox>
    </div>
  );
};

HeroCarouselSlide.propTypes = {};

export default withSitecoreContext()(HeroCarouselSlide);