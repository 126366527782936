import React from 'react';
import PropTypes from 'prop-types';
import { Text, Image, withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';

import {
    VerticalEmbedsWrapper,
    VerticalEmbedsTitle,
    VerticalEmbedsHeading,
    VerticalEmbedsCopy,
    VerticalEmbedsBackground,
    VerticalEmbedsVideoContainer,
    VerticalEmbedsVideo,
    VerticalEmbedsButton,
} from './VerticalEmbeds.styles.js';

const VerticalEmbeds = ({ fields, sitecoreContext }) => {
    const {
        title,
        heading,
        copy,
        backgroundImg,
        videoOneUrl,
        videoOnePoster,
        videoTwoUrl,
        videoTwoPoster,
        videoThreeUrl,
        videoThreePoster,
        button,
    } = fields;

    return (
        <VerticalEmbedsWrapper>
            {/*{backgroundImg && backgroundImg?.value && (*/}
            {/*    <VerticalEmbedsBackground src={backgroundImg.value.src} alt={backgroundImg.value.alt} />*/}
            {/*)}*/}
            <div className="vertical-embeds-content">
                {title && <VerticalEmbedsTitle>{title.value}</VerticalEmbedsTitle>}
                {heading && <VerticalEmbedsHeading>{heading.value}</VerticalEmbedsHeading>}
                {copy && <VerticalEmbedsCopy>{copy.value}</VerticalEmbedsCopy>}
                <VerticalEmbedsVideoContainer>
                    {/*{videoOneUrl && (*/}
                    {/*    <VerticalEmbedsVideo*/}
                    {/*        src={videoOneUrl.value}*/}
                    {/*        poster={videoOnePoster.value.src}*/}
                    {/*        controls*/}
                    {/*    />*/}
                    {/*)}*/}
                    {/*{videoTwoUrl && (*/}
                    {/*    <VerticalEmbedsVideo*/}
                    {/*        src={videoTwoUrl.value}*/}
                    {/*        poster={videoTwoPoster.value.src}*/}
                    {/*        controls*/}
                    {/*    />*/}
                    {/*)}*/}
                    {videoThreeUrl && videoThreeUrl?.value && (
                        <VerticalEmbedsVideo
                            src={videoThreeUrl?.value}
                            poster={videoThreePoster?.value}
                            controls
                        />
                    )}
                </VerticalEmbedsVideoContainer>
                {/*{button && button?.value && (*/}
                {/*    <VerticalEmbedsButton href={button.value.href}>{button.value.text}</VerticalEmbedsButton>*/}
                {/*)}*/}
            </div>
        </VerticalEmbedsWrapper>
    );
};

VerticalEmbeds.propTypes = {
    fields: PropTypes.shape({
        title: PropTypes.object,
        heading: PropTypes.object,
        copy: PropTypes.object,
        backgroundImg: PropTypes.object,
        videoOneUrl: PropTypes.object,
        videoOnePoster: PropTypes.object,
        videoTwoUrl: PropTypes.object,
        videoTwoPoster: PropTypes.object,
        videoThreeUrl: PropTypes.object,
        videoThreePoster: PropTypes.object,
        button: PropTypes.object,
    }),
    sitecoreContext: PropTypes.object.isRequired,
};

export default withSitecoreContext()(VerticalEmbeds);