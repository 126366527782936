import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import { isServer } from '@sitecore-jss/sitecore-jss';

import Link from 'components/shared/Link';


import {ProductCardComponent, ProductCardFooter} from '../CardTypes.styles';

import { useDeviceResize } from 'hooks';
import { ConditionalComponentWrapper } from 'helpers/componentHelpers';

import { trademarkFilterHelper } from 'helpers/trademarkFilterHelper';
import { checkBadges } from 'helpers/badgeHelpers.js';

const ProductCard = ({
  sitecoreContext = {},
  image = {},
  promoImage = {},
  title = '',
  description = '',
  link = '',
  type = 'product',
  itemId = '',
  productIsNew = false,
  webBadge = '',
  language = '',
  globalization = '',
  classValue = '',
                     }) => {
  const { viewBag = {}, trademarkReplacements = [], salsify = {} } = sitecoreContext;
  const device = useDeviceResize();
  const { country: badgeCountry, language: badgeLanguage } = globalization;
  const activeImgSrc = checkBadges(productIsNew, webBadge, badgeLanguage, badgeCountry);
  const [isHovered, setIsHovered] = useState(false);
  const handleMouseEnter = () => {
    setIsHovered(true);
  };
  const handleMouseLeave = () => {
    setIsHovered(false);
  };


  return (
    <ProductCardComponent type={type} className={`product ${classValue} ${isHovered ? 'active' : ''}`}
                          onMouseEnter={handleMouseEnter}
                          onMouseLeave={handleMouseLeave}>
      {!isServer() && (
        <ConditionalComponentWrapper
          condition={link}
          wrapper={(children) => (
            <Link href={link} className="button-link">
              {children}
            </Link>
          )}
        >
          <div className="image-container">
            <div className="product-inner-image-container">
              {image?.url && <img src={image?.url} alt={image?.alt} />}
              {activeImgSrc && <img className="product-card-badge" src={activeImgSrc} alt={'New Look'} />}
            </div>
          </div>
          <ProductCardFooter className="productCardFooter">
            {title && (
              <h3 dangerouslySetInnerHTML={{ __html: trademarkFilterHelper(title, trademarkReplacements) }} />
            )}
            {/*{description && (*/}
            {/*  <p*/}
            {/*    dangerouslySetInnerHTML={{*/}
            {/*      __html: trademarkFilterHelper(description, trademarkReplacements),*/}
            {/*    }}*/}
            {/*  />*/}
            {/*)}*/}
          </ProductCardFooter>
        </ConditionalComponentWrapper>
      )}
    </ProductCardComponent>
  );
};

export default withSitecoreContext()(ProductCard);

ProductCard.propTypes = {
  image: PropTypes.object,
  itemId: PropTypes.string,
};