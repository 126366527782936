import React, { useState, useEffect } from 'react';
import { RecommendRecipeWrapper } from './RecommendRecipe.styles.js';
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';

const RecommendRecipe = ({ fields = {}, sitecoreContext }) => {
    const { title = {}, copy = {}, endheading = {}, endcopy = {}, loadingsubtext = {}, loadingtext = {} } = fields;
    const [quizStep, setQuizStep] = useState(-1);
    const [selectedOptions, setSelectedOptions] = useState([]);
    const [showIntro, setShowIntro] = useState(true);

    // Animation/loading states from earlier examples
    const [isTransitioning, setIsTransitioning] = useState(false);
    const [isFetching, setIsFetching] = useState(false);
    const [fadeClass, setFadeClass] = useState('fade-in');


    const [isImagesPreloaded, setIsImagesPreloaded] = useState(false);
    const [loadedCount, setLoadedCount] = useState(0);

    const quizData = [
        {
            question: "What's the best part of cooking with your family?",
            options: [
                { text: "Passing down recipes.", image: "/-/media/FamiliesBest/q1.jpg", key: "1" },
                { text: "Getting creative with flavors.", image: "/-/media/FamiliesBest/q2.jpg", key: "2" },
                { text: "Keeping it simple - stress free.", image: "/-/media/FamiliesBest/q3.jpg", key: "3" },
                { text: "Working together as a team.", image: "/-/media/FamiliesBest/q4.jpg", key: "4" }
            ]
        },
        {
            question: "What’s your mealtime tradition?",
            options: [
                { text: "A cozy family dinner around the table", image: "/-/jssmedia/FamiliesBest/q5.jpg", key: "5" },
                { text: "A big backyard barbecue.", image: "/-/jssmedia/FamiliesBest/q6.jpg", key: "6" },
                { text: "Game day with hearty snacks.", image: "/-/jssmedia/FamiliesBest/q7.jpg", key: "7" },
                { text: "A potluck with friends and family.", image: "/-/jssmedia/FamiliesBest/q8.jpg", key: "8" }
            ]
        },
        {
            question: "What’s your family’s favorite style of side dish?",
            options: [
                { text: "Cheesy, hearty, comfort food", image: "/-/jssmedia/FamiliesBest/q9.jpg", key: "9" },
                { text: "Fresh and light", image: "/-/jssmedia/FamiliesBest/q10.jpg", key: "10" },
                { text: "Simple and traditional", image: "/-/jssmedia/FamiliesBest/q11.jpg", key: "11" },
                { text: "Who says a main can’t be a side?", image: "/-/jssmedia/FamiliesBest/q12.jpg", key: "12" }
            ]
        },
        {
            question: "Where do beans fit into your family meals?",
            options: [
                { text: "The go-to snack to hold us over.", image: "/-/jssmedia/FamiliesBest/q13.jpg", key: "13" },
                { text: "The reliable sidekick.", image: "/-/jssmedia/FamiliesBest/q14.jpg", key: "14" },
                { text: "The perfect start to our day.", image: "/-/jssmedia/FamiliesBest/q15.jpg", key: "15" },
                { text: "They’re the main event.", image: "/-/jssmedia/FamiliesBest/q16.jpg", key: "16" }
            ]
        }
    ];
    const recipeResults = {
        "1": {
            name: "Traditional Hummus",
            image: "/-/media/BushsBeans/Recipe_Detail-02-99_1920x772/12_recipe-hero-traditional_hummus_1920x772.jpg",
            url: "https://www.bushbeans.com/en_US/bean-recipes/traditional-hummus"
        },
        "2": {
            name: "Chick Pea Pancakes",
            image: "/-/media/BushsBeans/Recipe_Detail-176-250_1920x772/206_ChickpeaPancakes_cut_1920x772_v3.jpg",
            url: "https://www.bushbeans.com/en_US/bean-recipes/chickpea-pancakes-with-strawberries-and-cream"
        },
        "3": {
            name: "Italian Sausage and Chick Peas",
            image: "/-/media/BushsBeans/Recipe_Detail-176-250_1920x772/199_ItalianStyleSausageGarbanzo_v1_1920x772.jpg",
            url: "https://www.bushbeans.com/en_US/bean-recipes/italian-style-sausage-and-chickpeas"
        },
        "4": {
            name: "Traditional Falafel with Dilly Cucumber Salad",
            image: "/-/media/BushsBeans/Recipe_Images/BUSH16531Q4-Recipes-Web-SelectsTraditional-Falafel-with-Dilly-Cucumber-SaladHero217V1AR3FINAL1920x77.jpg",
            url: "https://www.bushbeans.com/en_US/bean-recipes/traditional-falafel-with-dilly-cucumber-salad"
        },
        "5": {
            name: "Kidney Chili Beans Chili",
            image: "/-/media/BushsBeans/Recipe_Images/Recipe_1920x772/08_Kidney_Chili_Beans_Chili_1920x772.jpg",
            url: "https://www.bushbeans.com/en_US/bean-recipes/kidney-chili-beans-chili"
        },
        "6": {
            name: "Pulled Pork Chili",
            image: "/-/media/BushsBeans/Recipe_Detail-02-99_1920x772/54_20CL_BUB_3C_AF42517_UL_1920x772.jpg",
            url: "https://www.bushbeans.com/en_US/bean-recipes/pulled-pork-and-bean-chili"
        },
        "7": {
            name: "Buffalo Chicken Chili Bean Dip",
            image: "/-/media/BushsBeans/Recipe_Detail-176-250_1920x772/220_1920x772_BuffaloChickChiliCheeseDip.jpg",
            url: "https://www.bushbeans.com/en_US/bean-recipes/buffalo-chicken-chili-bean-dip"
        },
        "8": {
            name: "Buffalo Chicken Chili",
            image: "/-/media/BushsBeans/Recipe_Images/199-Buffalo-Chicken-Chili-1920x772.jpg",
            url: "https://www.bushbeans.com/en_US/bean-recipes/buffalo-chicken-chili"
        },
        "9": {
            name: "Taco Fiesta Chicken & Rice Bake",
            image: "/-/media/BushsBeans/Recipe_Detail-176-250_1920x772/180_Tasty_Taco_1920x772.jpg",
            url: "https://www.bushbeans.com/en_US/bean-recipes/taco-fiesta-chicken-and-rice-bake"
        },
        "10": {
            name: "Taco Fiesta Salad Bowl",
            image: "/-/media/BushsBeans/Recipe_Images/Taco-Fiesta-Salad-Bowl-1920x772.jpg",
            url: "https://www.bushbeans.com/en_US/bean-recipes/taco-fiesta-salad-bowl"
        },
        "11": {
            name: "Citrus Baked Chicken with Taco Fiesta",
            image: "/-/media/BushsBeans/Recipe_Detail-176-250_1920x772/203_CraveableCitrusBakedChick_withrice_1920x772.jpg",
            url: "https://www.bushbeans.com/en_US/bean-recipes/citrus-baked-chicken-with-bushs-taco-fiesta"
        },
        "12": {
            name: "Chicken Tacos with Black Beans",
            image: "/-/media/BushsBeans/Recipe_Detail-176-250_1920x772/179_Chicken_Tacos_1920x772.jpg",
            url: "https://www.bushbeans.com/en_US/bean-recipes/cha-cha-chicken-tacos"
        },
        "13": {
            name: "Grilled Corn Salsa",
            image: "/-/media/BushsBeans/Recipe_Detail-02-99_1920x772/18_GrilledCornSalsa_CHIPS_1920x772.jpg",
            url: "https://www.bushbeans.com/en_US/bean-recipes/grilled-corn-bean-salsa"
        },
        "14": {
            name: "Baked Beans with Burnt Ends & Bacon",
            image: "/-/media/BushsBeans/Recipe_Images/Bushs-burnt-ends-bacon-recipe-header-1920x772.jpg",
            url: "https://www.bushbeans.com/en_US/bean-recipes/baked-beans-burnt-ends-bacon"
        },
        "15": {
            name: "Single Skillet Smoky Bean Breakfast",
            image: "/-/media/BushsBeans/Recipe_Images/Skillet-Bean-Breakfast-Hero-1920x772.jpg",
            url: "https://www.bushbeans.com/en_US/bean-recipes/skillet-bean-breakfast"
        },
        "16": {
            name: "Baked Beans with Brisket",
            image: "/-/media/BushsBeans/Recipe_Images/Baked-Beans-Brisket-Hero-1920x772.jpg",
            url: "https://www.bushbeans.com/en_US/bean-recipes/baked-beans-with-brisket"
        }
    };
    const quizImages = quizData.flatMap(q => q.options.map(o => o.image));
    const recipeImages = Object.values(recipeResults).map(recipe => recipe.image);
    const allImages = [...quizImages, ...recipeImages];
    const totalImages = allImages.length;

    // Preload images on mount
    useEffect(() => {
        if (!allImages || !allImages.length) {
            setIsImagesPreloaded(true);
            return;
        }

        let loaded = 0;
        allImages.forEach(src => {
            const img = new Image();
            img.src = src;
            // On load or error, increment loaded count
            const onLoadOrError = () => {
                loaded++;
                if (loaded === totalImages) {
                    setIsImagesPreloaded(true);
                }
            };
            img.onload = onLoadOrError;
            img.onerror = onLoadOrError;
        });
    }, [allImages, totalImages]);

    const handleStartQuiz = () => {
        setQuizStep(0);
        setShowIntro(false);
    };

    const handleOptionClick = (option) => {
        setSelectedOptions(prev => [...prev, option.key]);
        // If not the last question, animate out, then move on
        if (quizStep < quizData.length - 1) {
            // 1) Fade out the current question
            setFadeClass('fade-out');
            setIsTransitioning(true);

            setTimeout(() => {
                // 2) Advance the quiz step
                setQuizStep((prev) => prev + 1);

                // 3) Fade in the next question
                setFadeClass('fade-in');
                setIsTransitioning(false);
            }, 500); // match your fade-out duration
        } else {
            // This was the last question, so show "fetching" screen, then show results
            setFadeClass('fade-out');
            setIsTransitioning(true);

            setTimeout(() => {
                // Show loading/fetching screen
                setIsFetching(true);
                setIsTransitioning(false);
                setFadeClass('fade-in');

                // Simulate a short delay for "fetching"
                setTimeout(() => {
                    setIsFetching(false);
                    setQuizStep(quizData.length);
                }, 2000); // 2s "fetching" time, pick what you prefer
            }, 500);
        }
    };

    const handleResetQuiz = (event) => {
        event.preventDefault();
        setQuizStep(-1);
        setSelectedOptions([]);
        setShowIntro(true);
    };

    const renderQuizContent = () => {
        if (quizStep >= 0 && quizStep < quizData.length) {
            const currentQuestion = quizData[quizStep];
            return (
                <div key={`question-${quizStep}`} className={`quiz-question ${fadeClass}`}>
                    <h3>{currentQuestion.question}</h3>
                    <div className="quiz-options">
                        {currentQuestion.options.map((option, index) => (
                            <div
                                key={index}
                                className="quiz-option option-fade-in"
                                style={{ animationDelay: `${index * 0.1}s` }}
                                onClick={() => !isTransitioning && handleOptionClick(option)}
                            >
                                <img src={option.image} alt={option.text} className="quiz-option-image" />
                                <button className="quiz-option-button">{option.text}</button>
                            </div>
                        ))}
                    </div>
                </div>
            );
        }

        // If quizStep == quizData.length, that means show results
        if (quizStep === quizData.length) {
            return (
                <div className={`quiz-results ${fadeClass}`}>
                    <div className="inner">
                        {!endheading?.value && <h2>Did Duke Guess Your Recipe?</h2>}
                        {endheading?.value && <h2>{endheading.value}</h2>}
                        {!endcopy?.value && (
                            <p>Check out the recipes below—do any look like a family favorite? Try them out and make them your own!{" "}
                                 <a className="resetQuiz" onClick={handleResetQuiz} href="#">
                                    Take the quiz again.
                                </a>
                            </p>
                        )}
                        {endcopy?.value && (
                            <p>
                                <span dangerouslySetInnerHTML={{ __html: endcopy.value }} />
                                <a className="resetQuiz" onClick={handleResetQuiz} href="#"> Take the quiz again.</a>
                            </p>
                        )}
                    </div>
                    <div className="results-container">
                        {selectedOptions.map((key, index) => {
                            const recipe = recipeResults[key] || {
                                name: "No recipe found for this selection.",
                                image: "/-/media/FamiliesBest/default.jpg",
                                url: "#"
                            };
                            return (
                                <a
                                    href={recipe.url}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    key={index}
                                    className="result-item"
                                >
                                    <img
                                        src={recipe.image}
                                        alt={recipe.name}
                                        className="result-image"
                                    />
                                    <h3>{recipe.name}</h3>
                                </a>
                            );
                        })}
                    </div>
                </div>
            );
        }
        return null;
    };
    if (!isImagesPreloaded) {
        return (
            <RecommendRecipeWrapper className="recommend-recipe preload-screen">
                <div className="inner intro-text">
                    <span className="imgloadtext">Loading...</span>
                    <div className="loading-spinner" />
                </div>
            </RecommendRecipeWrapper>
        );
    }
    return (
        <RecommendRecipeWrapper className="recommend-recipe">
            {showIntro && (
                <div className="inner intro-text">
                    <div className="text-content">
                        {!title?.value && <h2>Looking for Family Dinner Inspiration? Take the Quiz!</h2>}
                        {title?.value && <h2>{title.value}</h2>}
                        {!copy?.value && <p>Answer a few fun questions about your family's traditions, and see if Duke can fetch the perfect recipe just for you!</p>}
                        {copy?.value && <p>{copy.value}</p>}
                    </div>
                </div>
            )}

            {/* Start button */}
            {quizStep === -1 && (
                <button className="start-button" onClick={handleStartQuiz}>
                    Take the Quiz
                </button>
            )}

            {/* If isFetching is true, show the "Loading" screen instead of question/results */}
            {isFetching ? (
                <div className={`loading-screen ${fadeClass}`}>
                    {!loadingsubtext?.value && <p className="qsubheading">Can Duke Guess Your Family’s Recipe?</p>}
                    {loadingsubtext?.value && <p className="qsubheading">{loadingsubtext.value}</p>}
                    {!loadingtext?.value && <h2>Duke is fetching your recipe...</h2>}
                    {loadingtext?.value && <h2>{loadingtext.value}</h2>}
                    <div className="loading-spinner" />
                </div>
            ) : (
                renderQuizContent()
            )}
            {showIntro && (
            <div className="bg-graphics">
                <img className="duke-img intro-img" src="/-/jssmedia/FamiliesBest/DukeTheDog.png" alt="Duke the Dog"  />
                <img className="ingredient-img intro-img" src="/-/jssmedia/FamiliesBest/ingredientsGraphic.png" alt="recipe ingredient background decoration"/>
                <img className="mobile-ingredient-img intro-img" src="/-/jssmedia/FamiliesBest/mobile-ingredient-graphic.png" alt="recipe ingredient background graphic"/>
            </div>
            )}
            {isFetching && (
                 <img className="duke-loading-img " src="/-/jssmedia/FamiliesBest/DukeTheDog.png" alt="Duke the Dog"  />
            )}
        </RecommendRecipeWrapper>
    );
};

export default withSitecoreContext()(RecommendRecipe);