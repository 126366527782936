import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {isExperienceEditorActive, withSitecoreContext} from '@sitecore-jss/sitecore-jss-react';
import {VideoRightOuterWrapper} from './VideoRightComponent.styles.js';
import {YtVideoComponent} from './VideoRightComponent.styles.js';
import {SocialComponentWrapper, SocialOuterWrapper} from "./SocialMediaComponent.styles";
import {RichText} from "../../shared/JssOverrides";
import SocialLinks from "../../shared/SocialLinks";

const VideoRightComponent = (
    {
        video,
        poster,
        rtClass,
        content,
        tag,
        editable,
        sitecoreContext,
        ...props
    }
    ) => {
    const {params = {}} = props;
    const {backgroundColor = 'yellow', alignment = 'center'} = params;
    const [isIframeVisible, setIsIframeVisible] = useState(false);
    const [videoUrl, setVideoUrl] = useState('');
    const experienceEditor = isExperienceEditorActive();

    const handleVideoClick = () => {
        setIsIframeVisible(true);
        setVideoUrl(video + '&autoplay=1');
    };

    if (experienceEditor || sitecoreContext?.pageEditing) {
        return (
            <VideoRightOuterWrapper backgroundColor={backgroundColor} alignment={alignment}>
                <div className="container">
                    <div className="two-col-grid">
                        <div className="col">
                            <div
                                className={rtClass}
                                dangerouslySetInnerHTML={{__html: content.value}}
                            />
                        </div>
                        <div className="col">
                            <YtVideoComponent
                                data-video={video}
                                style={poster ? { backgroundImage: `url(${poster})` } : {}}
                                onClick={handleVideoClick}
                            >
                                {isIframeVisible && (
                                    <iframe
                                        className={`fade-in ${isIframeVisible ? 'visible' : ''}`}
                                        width="560"
                                        height="315"
                                        src={isIframeVisible ? videoUrl : ''}
                                        frameBorder="0"
                                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                        allowFullScreen
                                    ></iframe>
                                )}
                            </YtVideoComponent>
                        </div>
                    </div>
                </div>
            </VideoRightOuterWrapper>
        );
    }

    return (
        <VideoRightOuterWrapper backgroundColor={backgroundColor} alignment={alignment}>
            <div className="container">
                <div className="two-col-grid">
                    <div className="col">
                        <div
                            className={rtClass}
                            dangerouslySetInnerHTML={{__html: content.value}}
                        />
                    </div>
                    <div className="col">
                        <YtVideoComponent
                            data-video={video}
                            style={poster ? { backgroundImage: `url(${poster})` } : {}}
                            onClick={handleVideoClick}
                        >
                            {isIframeVisible && (
                                <iframe
                                    className={`fade-in ${isIframeVisible ? 'visible' : ''}`}
                                    width="560"
                                    height="315"
                                    src={isIframeVisible ? videoUrl : ''}
                                    frameBorder="0"
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                    allowFullScreen
                                ></iframe>
                            )}
                        </YtVideoComponent>
                    </div>
                </div>
            </div>
        </VideoRightOuterWrapper>
    );
};

VideoRightComponent.propTypes = {
    sitecoreContext: PropTypes.object,
};

export default withSitecoreContext()(VideoRightComponent);