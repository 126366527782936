import React from 'react';
import PropTypes from 'prop-types';
// import { Link as ReactRouterLink } from 'react-router-dom';

const Link = (props) => {
    // Check if the link is external
    const isExternal = (url) => {
        return /^(http|https):/.test(url);
    };

    // Render a standard anchor tag for external links with security attributes
    if (props?.path && isExternal(props.path)) {
        return (
            <a {...props} href={props.path} target="_blank" rel="noopener noreferrer" aria-label={props.ariaLabel}>
                {props.children}
            </a>
        );
    }

    // Render the link normally if it's not external
    if (props?.path) {
        return (
            <a {...props} href={props.path} aria-label={props.ariaLabel}>
                {props.children}
            </a>
        );
    }

    return <a {...props}>{props.children}</a>;
};

Link.propTypes = {
    path: PropTypes.string,
    ariaLabel: PropTypes.string,
    children: PropTypes.node,
};

export default Link;