import styled, { keyframes } from 'styled-components';

const getbackgroundColor = ({ backgroundColor, theme } = {}) => {
    const backgroundColors = {
        tan: {
            background: theme.colours.tan,
            text: theme.colours.brown,
            heading: theme.colours.red,
        },
        yellow: {
            background: theme.colours.yellow,
            text: theme.colours.brown,
            heading: theme.colours.red,
        },
        blue: {
            background: theme.colours.blue,
            text: theme.colours.tan,
            heading: theme.colours.yellow,
        },
        red: {
            background: theme.colours.red,
            text: theme.colours.yellow,
            heading: theme.colours.yellow,
        },
    };

    if (backgroundColor in backgroundColors) return backgroundColors[backgroundColor];
    return backgroundColors.yellow;
};

const fadeInUpAnimation = keyframes`
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
`;

const fadeInLeftAnimation = keyframes`
  0% {
    opacity: 0;
    transform: translateX(-50px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
`;


export const HeroWithTextLeftComponent = styled.section`
	/* Move keyframes into the component scope */
	@keyframes fadeInUp {
		0% {
			opacity: 0;
			transform: translateY(20px);
		}
		100% {
			opacity: 1;
			transform: translateY(0);
		}
	}

	position: relative;
	background-color: ${({ theme, backgroundColor }) =>
		backgroundColor
			? getbackgroundColor({ theme, backgroundColor }).background
			: theme.colours.yellow};
	padding: 0;

	&[data-loaded="true"] {
		.inner {
			animation: fadeInUp 0.8s ease forwards;
		}
		.mediaDiv img {
			animation: fadeInUp 0.8s ease forwards 0.3s; /* 0.3s delay for staggering */
		}
	}
	.c-wrap{
		position: relative;
		z-index: 5;
	}
	.container {
		display: grid;
		grid-template-columns: repeat(12, minmax(0, 1fr));
		gap: 20px;
		//width: 1550px;
		max-width: 90%;
		margin: auto;
		@media (max-width: 900px) {
			gap: 0;
            max-width: 100%;
		}
	}

	@media (max-width: 900px) {
		padding: 0;
	}
    .grid-div{
		display: grid;
		grid-template-columns: repeat(12, minmax(0, 1fr));
		gap: 20px;
		width: 100%;
		max-width: 100%;
		margin: auto;
        .copyDiv{
            margin: 50px;
        }
		@media (max-width: 900px) {
			gap: 0;
            .copyDiv{
                margin: 0;
            }
		} 
    }
`;


export const CopyDiv = styled.div`
    position: relative;
	display: flex;
	flex-flow: column;
	grid-column: span 6 / span 6;
	align-self: center;
	order: 0;
    .inner{
        width: 650px;
        max-width: 100%;
        margin: 50px auto;
		display: flex;
		flex-flow: column;
		gap: 30px;
    }
    img{
        width: 100%;
        height: auto;
        margin: auto;
    }
    p{
        text-align: left;
        display: block;
		color: ${({ theme, backgroundColor }) =>
			backgroundColor
				? getbackgroundColor({ theme, backgroundColor }).text
				: theme.colours.tan};
        font-size: 24px;
        line-height: 1.3;
        font-weight: 400;
        margin-bottom: 0;
        span{
			color: ${({ theme, backgroundColor }) =>
				backgroundColor
					? getbackgroundColor({ theme, backgroundColor }).text
					: theme.colours.tan};
        }
    }
    .copy-img{
        margin-bottom: 40px;
        @media (max-width: 900px){
            max-width: 320px;
            margin: 0 auto 30px auto;
            display: block;
        }
    }
	.top-heading{
		font-size: 70px;
		color: ${({ theme, backgroundColor }) =>
			backgroundColor
				? getbackgroundColor({ theme, backgroundColor }).heading
				: theme.colours.red};
        font-weight: 700;
        line-height: 1;
		display: block;
		text-align: left;
		margin-bottom: 0;
		font-family: "apercu pro", helvetica, verdana, sans-serif;
	}    
    .large-heading{
		font-family: bordonaro !important;
        font-size: 350px;
		color: ${({ theme, backgroundColor }) =>
			backgroundColor
				? getbackgroundColor({ theme, backgroundColor }).heading
				: theme.colours.red};
		line-height: 0.7;
		position: relative;
		top: -70px;
	}
    .small-heading{
		font-size: 24px;
		line-height: 1;
		font-weight: 700;
		margin-bottom: 10px;
        display: block;
		color: ${({ theme, backgroundColor }) =>
			backgroundColor
				? getbackgroundColor({ theme, backgroundColor }).text
				: theme.colours.tan};
    }
    .description{
		text-align: left;
		display: block;
		color: ${({ theme, backgroundColor }) =>
			backgroundColor
				? getbackgroundColor({ theme, backgroundColor }).text
				: theme.colours.tan};
		font-size: 24px;
		line-height: 1.3;
		font-weight: 400;
		margin-bottom: 20px;
    }
	@media (max-width: 1400px){
        .inner{
            padding: 50px 0;
            margin: auto;
        }
		.top-heading{
           font-size: 50px;
        }
		.large-heading{
			top: -50px;
			font-size: 250px;
			line-height: 0.7;
		}
	}
	@media (max-width: 1130px){
		.top-heading{
			font-size: 40px;
		}
		.large-heading{
			top: -40px;
			font-size: 200px;
		}
        .small-heading{
            font-size: 18px;
        }
		.description{
            font-size: 18px;
        }
	}
     @media (max-width: 900px){
		grid-column: span 12 / span 12;
        .inner{
            margin: auto;
            padding: 30px;
			width: calc(100% - 60px);
            max-width: calc(100% - 60px);
        }
        order: 1;
        padding: 0;
        background-color: ${({ theme, backgroundColor }) =>
			backgroundColor
				? getbackgroundColor({ theme, backgroundColor }).background
				: theme.colours.blue};
		.top-heading{
			font-size: 40px;
			color: ${({ theme, backgroundColor }) =>
				backgroundColor
					? getbackgroundColor({ theme, backgroundColor }).heading
					: theme.colours.tan};
            text-align: center;
		}
		.large-heading{
			font-size: 150px;
			color: ${({ theme, backgroundColor }) =>
				backgroundColor
					? getbackgroundColor({ theme, backgroundColor }).heading
					: theme.colours.tan};
			top: -20px;
			text-align: center;
		}
		.small-heading{
			font-size: 20px;
			line-height: 1.5;
			color: ${({ theme, backgroundColor }) =>
				backgroundColor
					? getbackgroundColor({ theme, backgroundColor }).text
					: theme.colours.tan};
			text-align: center;
            display: block;
		}
		.description{
            font-size: 20px;
            line-height: 1.3;
			text-align: center;
			color: ${({ theme, backgroundColor }) =>
				backgroundColor
					? getbackgroundColor({ theme, backgroundColor }).text
					: theme.colours.tan};
		}
        .buttonWrapper{
			display: flex;
			justify-content: center;
        }
    }
    @media (max-width: 600px){
		.top-heading{
			font-size: 25px;
		}
		.large-heading{
			font-size: 110px;
			top: -20px;
		}
		.small-heading{
			font-size: 16px;
		}
		.description{
			font-size: 16px;
		}
    }
	@media (max-width: 600px){
        width: 100%;
		p{
            font-size: 18px;
        }
	}
`;

export const FadeInUpElement = styled.div`
    opacity: 0;
    animation: ${fadeInUpAnimation} 0.5s cubic-bezier(0.34, 1.56, 0.64, 1) forwards;
`;
export const FadeInUpElementQuart = styled.div`
    opacity: 0;
    animation: ${fadeInUpAnimation} 0.5s cubic-bezier(0.25, 1, 0.5, 1) forwards;
`;

export const MediaDiv = styled.div`
	position: relative;
	display: flex;
	flex-flow: column;
	grid-column: span 6 / span 6;
	align-self: baseline;
	height: 100%;
	justify-content: end;
	order: 1;
	@media (max-width: 900px){
		grid-column: span 12 / span 12;
        padding: 0;
		order: 0;
	}
	@media (max-width: 600px){
		padding: 0;
	}
    img{
        opacity: 0;
		animation: ${fadeInLeftAnimation} 0.5s cubic-bezier(0.25, 1, 0.5, 1) forwards;
        max-width: 100%;
        max-height: 100%;
        width: 100%;
        height: 100%;
        object-fit: cover;
		object-position: bottom;
    }
`;


export const moveClouds = keyframes`
	0% {
		transform: translateX(-90%);
	}
	100% {
		transform: translateX(100%);
	}
`;

export const CloudsWrapper = styled.div`
	position: absolute;
	top: 5%;
	left: 0;
	width: 100%;
	overflow: hidden;
	z-index: 1;
	img {
		width: 100%;
		height: auto;
		animation: ${moveClouds} 60s linear infinite;
	}
	svg {
		width: 200%;
		height: auto;
		animation: ${moveClouds} 60s linear infinite;
	}
	@media (max-width: 899px){
		top: 35%;
		width: 100%;
		z-index: 5;
		img {
			width: 150%;
		}
	}
	@media (max-width: 750px){
		top: 53%;
	}
	@media (max-width: 500px){
		top: 60%;
	}
`;


export const HeroWithTextLeftBluey = styled.section`
	background: #CCE7FD;
	overflow: hidden;
	@keyframes fadeInUp {
		0% {
			opacity: 0;
			transform: translateY(20px);
		}
		100% {
			opacity: 1;
			transform: translateY(0);
		}
	}
	position: relative;
	padding: 0;
	.top-heading{
		font-family: 'hello headline', sans-serif;
		color: #1263BA;
	}
	.description{
		color: #404164;
		font-size: 26px;
	}
	&[data-loaded="true"] {
		.inner {
			animation: fadeInUp 0.8s ease forwards;
		}
		.mediaDiv img {
			animation: fadeInUp 0.8s ease forwards 0.3s; /* 0.3s delay for staggering */
		}
	}
	.c-wrap{
		position: relative;
		z-index: 5;
	}
	.container {
		display: grid;
		grid-template-columns: repeat(12, minmax(0, 1fr));
		gap: 20px;
		max-width: 90%;
		margin: auto;
		@media (max-width: 900px) {
			gap: 0;
            max-width: 100%;
		}
	}

	@media (max-width: 900px) {
		padding: 0;
	}
    .grid-div{
		display: grid;
		grid-template-columns: repeat(12, minmax(0, 1fr));
		gap: 20px;
		width: 100%;
		max-width: 100%;
		margin: auto;
        .copyDiv{
            margin: 50px;
        }
		@media (max-width: 900px) {
			gap: 0;
            .copyDiv{
                margin: 0;
            }
		} 
    }
	@media (max-width: 1100px) {
		.top-heading{
			line-height: 1.2;
		}
		.description{
			font-size: 20px
		}
	}
	@media (max-width: 600px) {
		.description{
			font-size: 18px
		}
	}
`;


export const CopyDivBluey = styled.div`
    position: relative;
	display: flex;
	flex-flow: column;
	grid-column: span 6 / span 6;
	align-self: center;
	order: 0;
    .inner{
        width: 650px;
        max-width: 100%;
        margin: 50px auto;
		display: flex;
		flex-flow: column;
		gap: 30px;
    }
	.top-heading{
		font-size: 70px;
		font-family: 'hello headline', sans-serif;
		color: #1263BA;
        font-weight: 700;
        line-height: 1;
		display: block;
		text-align: left;
		margin-bottom: 0;
	}
    .description{
		text-align: left;
		display: block;
		color: #404164;
		font-size: 26px;
		line-height: 1.3;
		font-weight: 400;
		margin-bottom: 20px;
    }
	@media (max-width: 1400px){
        .inner{
            padding: 50px 0;
            margin: auto;
        }
		.top-heading{
           font-size: 50px;
        }
	}
	@media (max-width: 1130px){
		.top-heading{
			font-size: 40px;
		}
		.description{
            font-size: 20px;
        }
	}
     @media (max-width: 900px){
		grid-column: span 12 / span 12;
        .inner{
            margin: auto;
            padding: 100px 30px 0;
			width: calc(80% - 60px);
            max-width: calc(80% - 60px);
        }
        order: 1;
        padding: 0;
        background-color: rgb(204, 231, 253);
		.top-heading{
			font-size: 50px;
			text-align: center;
			line-height: 1.15;
		}
		.description{
            font-size: 20px;
            line-height: 1.3;
			text-align: center;
			color: #404164;
		}
        .buttonWrapper{
			display: flex;
			justify-content: center;
        }
    }
	@media (max-width: 600px){
        width: 100%;
		.top-heading{
			font-size: 40px;
		}
		.inner{
			width: calc(100% - 60px);
			max-width: calc(100% - 60px);
		}
	}
`;

export const MediaDivBluey = styled.div`
	position: relative;
	display: flex;
	flex-flow: column;
	grid-column: span 6 / span 6;
	align-self: baseline;
	height: 100%;
	justify-content: end;
	order: 1;
	@media (max-width: 900px){
		grid-column: span 12 / span 12;
        padding: 0;
		order: 1;
	}
	@media (max-width: 600px){
		padding: 0;
	}
    img{
        opacity: 0;
		animation: ${fadeInLeftAnimation} 0.5s cubic-bezier(0.25, 1, 0.5, 1) forwards;
        max-width: 100%;
        max-height: 100%;
        width: 100%;
        height: 100%;
        object-fit: contain;
		object-position: bottom;
    }
`;