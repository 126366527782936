import React from 'react';
import PropTypes from 'prop-types';
import { MainNavAboutUsLinkComponent } from './MainNavAboutUsLink.styles.js';

import Link from 'components/shared/Link';
import {Image} from "@sitecore-jss/sitecore-jss-react";

const MainNavAboutUsLink = ({ text, image, src, ...props }) => {
  return (
    <MainNavAboutUsLinkComponent {...props}>
      <Link path={props.path}>
        <h2>{text}</h2>
        <div className="image">
          <Image field={image} imageParams={{ mw: 650, mh: 300 }} className="card-image" alt={`view ${text} page`} />
          <div>View</div>
        </div>
      </Link>
    </MainNavAboutUsLinkComponent>
  );
};

MainNavAboutUsLink.propTypes = {
  text: PropTypes.string,
  src: PropTypes.string,
};

export default MainNavAboutUsLink;