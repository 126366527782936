import React from 'react';
import PropTypes from 'prop-types';
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import { isServer } from '@sitecore-jss/sitecore-jss';

import Link from 'components/shared/Link';
import Button from 'components/shared/Button';
import Ribbon from 'components/shared/Ribbon';
import { CardPromoComponent, HoverWrapper } from '../CardTypes.styles';

import { useDeviceResize } from 'hooks';
import { ConditionalComponentWrapper } from 'helpers/componentHelpers';
import { checkBadges, languageFilter } from 'helpers/badgeHelpers';

import { trademarkFilterHelper } from 'helpers/trademarkFilterHelper';

const ProductPromoCard = ({
  sitecoreContext = {},
  image = {},
  promoImage = {},
  title = '',
  description = '',
  link = '',
  itemId = '',
  type = 'product',
  webBadge = '',
  productIsNew = false,
  globalization = '',
}) => {
  const { viewBag = {}, trademarkReplacements = [] } = sitecoreContext;
  const device = useDeviceResize();
  const { country: badgeCountry, language: badgeLanguage } = globalization;
  const filteredLanguage = languageFilter(badgeLanguage);
  const activeImgSrc = checkBadges(productIsNew, webBadge, filteredLanguage, badgeCountry);

  return (
    <CardPromoComponent type={type} className="product">
      {!isServer() && <Button type="love" size={device === 'mobile' ? 'small' : 'large'} itemId={itemId} />}

      {!isServer() && (
        <ConditionalComponentWrapper
          condition={Link}
          wrapper={(children) => (
            <Link href={link} className="button-link">
              {children}
            </Link>
          )}
        >
          <div className="image-container">
            <div className="product-inner-image-container">
              {image?.url && <img src={image?.url} alt={image?.alt} />}
              {activeImgSrc && <img className="product-card-badge" src={activeImgSrc} alt={'New Look'} />}
            </div>
            {device === 'desktop' && (
              <HoverWrapper>
                {promoImage?.url && (
                  <img src={promoImage?.url} alt={promoImage?.alt} className="hover-image" />
                )}
                <Ribbon variant="red" />
              </HoverWrapper>
            )}
          </div>

          <footer className="productCardPromoFooter">
            {title && (
                <h3 dangerouslySetInnerHTML={{ __html: trademarkFilterHelper(title, trademarkReplacements) }} />
            )}
            {description && (
                <p
                    dangerouslySetInnerHTML={{
                      __html: trademarkFilterHelper(description, trademarkReplacements),
                    }}
                />
            )}
          </footer>
        </ConditionalComponentWrapper>
      )}
    </CardPromoComponent>
  );
};

export default withSitecoreContext()(ProductPromoCard);

ProductPromoCard.propTypes = {
  image: PropTypes.object,
  itemId: PropTypes.string,
};